import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { PencilFill, ThreeDots } from 'react-bootstrap-icons';
import {
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,Button,Card,CardBody,CardFooter
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../services/restClient';
import Loader from '../_shared/Loader';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';

const StorageRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);



const ReadOnlyCardHeader = ({storage,isEditing}) =>{
 
    if(!storage) throw new Error('storage required');
    let navigate= useNavigate();
    const [collapseOne, setCollapseOne] = useState(false);
    
 
    return ( 
      <Fragment>
          <ConfirmDeleteModal 
            isOpen={collapseOne}
            url={ 'storages'} 
            onRequestingClose={()=>{ setCollapseOne(false)}} 
            onConfirm={ ()=>{ navigate('/asukohad')}}
            deleteBody={()=>{
              return[storage.id]
            }}></ConfirmDeleteModal> 
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
                <span>{storage.name}</span>
         </h5>
          </Col>
          <Col xs="auto" >
          <h6 className="text-uppercase text-600">
                 
                </h6>
          </Col>
        </Row>
        <Row>
          <Col>
           <UncontrolledDropdown className="d-inline-block ms-2">
              <DropdownToggle color="falcon-default" size="sm">
                <ThreeDots />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem  className="text-danger" onClick={ ()=>  setCollapseOne(true) } >Kustuta asukoht</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                      navigate('/asukoht-muuda/'+ storage.id);
                    }
                    else{
                      navigate('/asukoht/'+ storage.id);
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Uuenda detaile') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row>
        </CardHeader>
      </Fragment>
      
      );
}

const ReadOnlyFooter= ({ storage })=>{
    if(!storage) throw new Error('storage required');
    return (
         <Row>
        <Col>
        {<Media> 
           <Media body>
              <p className="mb-0">Asukoht loodi</p>
              <p className="fs--1 mb-0 text-600">{storage.introducedAt}</p>
            </Media>
          </Media>}
        </Col>
        <Col className="text-end">
        </Col>
      </Row>);
}

const ReadOnlyCardBody = ({ storage }) => {
    if(!storage) throw new Error('storage required');
  return (
    <Row>
      <Col lg className="col-xxl-5">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Asukoha Informatsioon</h6>
        <StorageRow title="Id">{storage.id}</StorageRow>
        <StorageRow title="Nimetus">{storage.name}</StorageRow>
        <StorageRow title="Aadress">{storage.address  }</StorageRow>
      </Col>
      <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Lisa Informatsioon</h6>
        <StorageRow title="Kirjeldus">
          {storage.description ? (
            storage.description.split('\n').map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Kirjeldus puudub</p>
            )}
        </StorageRow>
      </Col>
    </Row>
  );

}
 

//display
const StorageCard = () => {
    const { id }  = useParams();
    const url = `storages/${id}`;
    const [storage] = useGet({ url}); 
     
    return (
      <Fragment> 
      {!storage ? (
        <Loader />
         ) : ( <Card className="mb-3">
        <ReadOnlyCardHeader { ...{ isEditing:false, storage}}/>
         <CardBody className="bg-light border-top">
           <ReadOnlyCardBody storage={storage}/>
         </CardBody>
         <CardFooter className="border-top">
           {<ReadOnlyFooter  storage={storage}/>}
         </CardFooter>
       </Card>)
            }
    </Fragment> 
    ); 
}

export default StorageCard;
export { 
    ReadOnlyFooter,
    ReadOnlyCardHeader
}
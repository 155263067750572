import React, { useState } from 'react';
import PropTypes from 'prop-types'; 
import { Button, Col, Form,  FormGroup, Input, Row } from 'reactstrap'; 
import LoginImg from '../../../assets/img/m2.webp'
import { query } from '../../../services/restClient';
import { useAlert } from '@blaumaus/react-alert';
import { Link, Navigate  } from 'react-router-dom'; 
import { useParams}  from 'react-router';
import {sessionContext} from '../../../services/sessionContext';
import { ChevronLeft, EnvelopeAtFill, Facebook, Google } from 'react-bootstrap-icons';
const ConfirmForm = ({email}) => {
  
  return (   <div className="p-4 card-body">
  <div className="text-center"><EnvelopeAtFill size={36}></EnvelopeAtFill>
      <h4>Palun kontrolli oma emaili!</h4>
      <p>Sulle on email aadressil <strong>{email}</strong> . Konto aktiveerimiseks kinnita oma email, klikkides emailile saadetud lingile.</p>
      <Link to="/autentimine/logisisse" color="primary"
          className="mt-3 btn btn-primary btn-sm"><ChevronLeft></ChevronLeft> Sisselogimine </Link>
  </div>
</div>
  );
};

ConfirmForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ConfirmForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

 
const ConfirmPage =()=>{
    const { email} = useParams();
    debugger;
  return (<div className="container-fluid">
  <div className="min-vh-100 bg-100 row">
      <div className="d-none d-lg-block position-relative col-6">
          <div className="bg-holder"
              style={{
                backgroundImage:`url(${LoginImg})`,
                backgroundPosition: "50% 20%"
              }}>
          </div>
      </div>
      <div className="px-sm-0 align-self-center mx-auto py-5 col-sm-10 col-md-6">
          <div className="g-0 justify-content-center row">
              <div className="col-xxl-6 col-lg-9 col-xl-8">
                  <div className="card">
                      <div className="bg-shape bg-circle-shape text-center p-2 card-header"><Link
                              className="text-white light font-sans-serif fs-5 z-index-1 position-relative"
                              style={{
                                fontFamily: '"din-1451", Arial, Tahoma',
                                fontWeight: "normal !important"
                              }} to="/">Carmasters OÜ</Link></div> 
                           <ConfirmForm email={email} />
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>)
}

export default ConfirmPage; 

import React from 'react';
import {Resources} from '../_shared/Ressursid';
import { Media} from 'reactstrap';
import { Link } from 'react-router-dom'; 
import Moment from 'moment';
import 'car-makes-icons/dist/style.css'; 



const Soidukid = () => {

  const actionColumn = {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle', 
    align: 'right'
  };
  const columns = [
    {
      dataField: 'producer',
      headerClasses: 'border-0',
      text: 'Mark',
      classes: 'border-0 py-2 align-middle', 
      formatter: (dataField, {  producer,id }) => {
        return (
          <div className="d-flex align-items-center media">
          <div className="avatar avatar-l ">
            <div className="avatar-emoji rounded-circle ">
                <span role="img" aria-label="Emoji"><i className={'car-'+producer.replace(" ","-").toLowerCase()}></i></span>
            </div>
          </div>
          <Media body  className="ms-2 ">
             <Link to={'/soiduk/'+id} > <h5 className="mb-0 fs--1"> {producer}</h5>  </Link>
          </Media>
        </div> 
        );
      } 
  },
  {
    dataField: 'model',
    headerClasses: 'border-0',
    text: 'Mudel',
    classes: 'border-0 py-2 align-middle' 
  },
    {
      dataField: 'regNr',
      text: 'RegNr',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle' ,
      formatter: (dataField, {  regNr,producer,id }) => {
        return (
          <Link to={'/soiduk/'+id} >
            <h5 className="mb-0 fs--1"> {regNr}</h5>
        </Link>
        );
      }
    },
     {
      dataField: 'ownerName',
      text: 'Omanik',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, {  ownerName,ownerId }) => {
        if(!ownerName) return  <p className="font-italic text-400 mb-1">Omanik puudub</p>;
        return (
          <Link to={'/klient/'+ownerId} >
           <h5 className="mb-0 fs--1">{ownerName}</h5>
          </Link>
        );
      }
    },
    {
      dataField: 'vin',
      headerClasses: 'border-0',
      text: 'VIN',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, {  vin,id }) => {
        return (
          <Link to={'/soiduk/'+id} >
             <h5 className="mb-0 fs--1">{vin}</h5>
          </Link>
        );
      }
    },
    {
      dataField: 'body',
      headerClasses: 'border-0',
      text: 'Kere',
      classes: 'border-0 py-2 align-middle' 
    }, 
    {
      dataField: 'engine',
      headerClasses: 'border-0',
      text: 'Mootor',
      classes: 'border-0 py-2 align-middle' 
    },
    {
      dataField: 'productionDate',
      headerClasses: 'border-0',
      text: 'Toodetud',
      classes: 'border-0 py-2 align-middle' ,
      formatter: (dataField, { productionDate })=> {  
        return Moment(productionDate).format('MM-yyyy'); 
       },
    },
    {
      dataField: 'region',
      headerClasses: 'border-0',
      text: 'Regioon',
      classes: 'border-0 py-2 align-middle' 
    },
    {
      dataField: 'series',
      headerClasses: 'border-0',
      text: 'Seeria',
      classes: 'border-0 py-2 align-middle' 
    },
    {
      dataField: 'transmission',
      headerClasses: 'border-0',
      text: 'Käigukast',
      classes: 'border-0 py-2 align-middle' 
    },
    
    actionColumn
  ];
  return (
    <Resources
         columns={columns}
         keyField="id"
         resourceName={'vehicles'}
         resourceLink={'soiduk'}
         resourcePath="soidukid"
         resourceDisplayName={'Sõidukid'} 
         actionColumn={actionColumn}
         documentation={
           <div>
             <strong>Otsida saab regnr, vin, kliendi nime, ja teiste sõiduki omaduste järgi </strong>
          <ul className="ms-2 list-unstyled">
            <li>Näiteks kõik e61 mudelid
              <ul>
                <li><em>e61</em></li>
              </ul>
            </li> 
            <li>Omaniku nime järgi
              <ul>
                <li><em>rene prost</em></li>
              </ul>
            </li>
            <li>Reg numbri järgi mis algab 425B
              <ul>
                <li><em>425b*</em></li>
              </ul>
            </li>
          </ul> 
           </div>
         }
      />
  );
};
export default Soidukid;

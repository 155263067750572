import {  Fragment } from 'react';
import { useParams } from 'react-router'; 
import { Col,Media,Row,  Card, CardBody, CardHeader,Nav,NavItem,NavLink} from 'reactstrap'; 
import 'moment/locale/et';  
import {ProductsComponent} from './ProductsComponent';
import { PricingPdfView } from './PricingPdfComponent';
import { WorkSubjectComponent } from './WorkSubjectComponent';
import { WorkStateComponent } from './WorkStateComponent';
import { useGet,basePath } from '../../services/restClient';
import { Check, ArrowClockwise } from 'react-bootstrap-icons';
import {OfferOptionsComponent} from './OfferOptionsComponent';
const ReadOnlyFooter = () => {
     
    return (
        <Row>
            <Col>
                {<Media>
                    <Media body>
                        <p className="mb-0"></p>
                        <p className="fs--1 mb-0 text-600"></p>
                    </Media>
                </Media>}
            </Col>
            <Col className="text-end">
            </Col>
        </Row>);
}


 
//display
const JobCard = (props) => {
    const { id,offerid: offerId } = useParams();

    if (!id) throw new Error('id required');
    
    const pdfPath =!offerId?`pricings/invoice/${id}`:`pricings/estimate/${offerId}`;
   
    const [workStatus,setWorkStatus]= useGet({ url: `work/${id}/status` }); 

    const isOfferIssued=()=>{
        return workStatus.offers.find(x=>x.number === +offerId).isIssued
    }
    const mustShowPdfView=()=>{
        return (workStatus.invoiceIssued && !offerId)|| (offerId&&isOfferIssued());
    }

    if(!workStatus) return (<Fragment></Fragment>)
    
    return (
        <Row>
            <Col lg="9" ><Card className="mb-3">
            <CardHeader className="p-0 bg-light">
            <Nav className="border-bottom-0" tabs> 
              <NavItem className="text-nowrap">
                <NavLink href={`/too/${id}`} 
                  className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                  active={!offerId}>
                  {workStatus.invoiceIssued?<Check size={20}></Check>:<ArrowClockwise size={20}></ArrowClockwise>} 
                  <h6 className={'mb-0 text-600 fw-'+(!offerId?'bold':'medium')}>
                    Tooted ja teenused
                  </h6>
                </NavLink>
              </NavItem>
              {workStatus?.offers.map(item => (
                  <NavItem key={`navitem${item.number}`} className="text-nowrap">
                      <NavLink
                          href={`/too/${id}/pakkumine/${item.number}`}
                          className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                          active={+offerId===item.number}
                          >
                           {item.isIssued?<Check size={20}></Check>:<ArrowClockwise size={20}></ArrowClockwise>}
                          <h6 className={'mb-0 text-600 fw-'+(+offerId===item.number?'bold':'medium')}>Pakkumine {item.number}</h6>
                      </NavLink>
                  </NavItem> 
                ))} 
            </Nav>
          </CardHeader>
                <CardBody className='bg-light'>
                            {offerId&& 
                                  <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                                  <OfferOptionsComponent isReadOnly={workStatus.invoiceIssued} workId={id} offerId={offerId} setWorkStatus={setWorkStatus} pdfPath={pdfPath}></OfferOptionsComponent>
                                </div>
                                }
                            {mustShowPdfView() && 
                              <Row>
                                <PricingPdfView url={basePath + pdfPath}></PricingPdfView>
                              </Row>}
                            {!mustShowPdfView() && 
                              <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2"> 
                                <ProductsComponent isReadOnly={workStatus.invoiceIssued} workId={id} offerId={offerId} > </ProductsComponent>
                              </div> 
                                 } 
                </CardBody> 
            </Card></Col>
            <Col lg="3">
                 <Card className='mb-3'> 
                        <CardBody>
                           <WorkStateComponent workid={id} workStatus={workStatus} setWorkStatus={setWorkStatus} pdfPath={pdfPath}></WorkStateComponent> 
                        </CardBody>
                    </Card>
                    <Card> 
                        <CardBody>
                        <WorkSubjectComponent isReadOnly={workStatus.invoiceIssued} id={id} />
                        </CardBody>
                    </Card> 
            </Col>
        </Row>
    );
}

export default JobCard;
export {
    ReadOnlyFooter
}
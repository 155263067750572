import { useErrorStatus } from '../UnhandledErrorHandler';
import { useState, useEffect } from 'react';  
import {getJwtToken,setJwtToken} from './session';
import { options } from './options';
import { unstable_batchedUpdates } from 'react-dom';
const basePath = `${options.url.API_SCHEME}://${options.url.API_HOST}:${options.url.API_PORT}/api/`;
 
const downloadPdf = ({pdfPath,fileName,setError})=>{
   
  query({
      url:pdfPath+'/pdf',
      method: 'GET',
      onSuccess:(blob)=>{
          
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;;
          link.click(); 
          /*const fileUrl = URL.createObjectURL(blob);
          const w = window.open(fileUrl, '_blank');
          w && w.focus();*/
      },
      onFailure:setError
  })
}

const page =({resourceName,searchText,whenReady,setError})=> {
 
  var url = resourceName+'/page?'+new URLSearchParams({
    orderby: 'id',
    searchText: searchText,
    limit: 20,
    offset: 0
  })
  query({
    url: url,
    method : 'GET',
    onSuccess: (result) => { 
      if (result.items) {
        whenReady(result.items); 
      } 
      else  {
        console.log(result);
        setError('Expected rows, but got nothing')
        return [];
      }
    },
    onFailure: setError
  });
}
  
const query = ({ url, method, body, onSuccess, onFailure }) => {
 
  const jwt = getJwtToken();
  const headers = {
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['Authorization'] = 'Bearer ' + jwt;
  }
  url = basePath + url;
   
  body = !body ? undefined : JSON.stringify(body);
  fetch(url, {
    method: method,
    body: body,
    headers: headers
  }).then(response => {
 
    const contentType = response.headers.get('content-type');
    const isjson = contentType && contentType.indexOf('application/json') !== -1;
    const ispdf = contentType && contentType.indexOf('application/pdf') !== -1;
    var success = response.status < 400; 
    
    if (success) {
      if (isjson) {
        return response.json().then(json => {
          onSuccess(json);
        });
      } else if (ispdf) {
        return response.blob().then(blob => {
          onSuccess(blob);
        });
      }  else {
        return response.text().then(text => {
          onSuccess(text);
        });
      }
    } else {
      return response.text().then(text => {
        onFailure({
          url: url,
          status: response.status,
          text: text
        });
      });
    }
  }).catch((reason) => {

    onFailure({
      url: url,
      text: reason.toString()
    });
  });
};


const usePost = ({resource}) => { 
  const { setError } = useErrorStatus(); 
  const httpChange =(body,onSuccess)=>{ 
    query({
      url:resource ,method: 'POST',body, onSuccess, onFailure:setError
    });
   };
 return [httpChange]; 
};

const usePut = ({url}) => { 
  const { setError } = useErrorStatus(); 
  const httpChange =(body,onSuccess)=>{ 
    query({ url ,method: 'PUT',body, onSuccess,onFailure:setError});
   };
 return [httpChange]; 
};

const useDelete =({ url })=>{
  const { setError } = useErrorStatus();   
  const httpDelete =(body,onSuccess)=>{
    query({url , method: 'DELETE',body,onSuccess,onFailure:setError});
   };
 return [httpDelete];
}

const useGet = ({ url }) => {
  
  const [urlUsed,setUrlUsed] = useState(url);
  const [data, setData] = useState(null); 
  const { setError } = useErrorStatus(); 
 
  useEffect(() => {  
    if((url && data === null) || (url && url!==urlUsed)  )
    {  
      query({ url ,method: 'GET', onSuccess: (response)=>{
        unstable_batchedUpdates(()=>{
          setData(response); 
          setUrlUsed(url);
        });
       
      },onFailure: setError })
    }
  },[url,urlUsed,setError,data]);
   
  return [data,setData]
}
  
export {
   useGet,query,page,usePut,useDelete,usePost,basePath,downloadPdf
}
import React  from 'react'; 
import {
  CardHeader,
  Col,
  Row,Card,CardBody,CardFooter 
} from 'reactstrap'; 
import { EditableCardBody} from './SoidukMuuda' 
import {   usePost } from '../../services/restClient';
const NewCardHeader = () =>{
    return (
        <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">Uus soiduk</h5>
          </Col> 
        </Row>
        </CardHeader>
      );
}
 
//create
const VehicleNewCard = () => {
 
  const [changeVehicle] = usePost({ resource:'vehicles'});
     
    return ( 
      <Card className="mb-3">
      <NewCardHeader />
      <CardBody className="bg-light border-top">
      <EditableCardBody { ...{  changeVehicle}} />
      </CardBody>
      <CardFooter className="border-top">
      
      </CardFooter>
    </Card>  
    ); 
}



export default VehicleNewCard;
import React from "react"; 
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem } from 'reactstrap';
import corner4 from '../../assets/img/corner-4.webp';
import Background from '../_shared/Background';
 
const NavMenuItem =({icon,name,to,onClick})=>{
  
    return (
        <NavItem key={name}> 
            <NavLink className={(navData) => navData.isActive ? 'active nav-link'  : 'nav-link' }   onClick={onClick}  to={to}>
              {icon} {name}
            </NavLink> 
        </NavItem> 
    );

}

const SideBarMenu = ({ collapsed, children }) => {
      
    return (

        <Collapse id="sidebarMenu" isOpen={!collapsed}   className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
            <div className="position-sticky pt-3 sidebar-sticky">
            <Background
          image={corner4}
          className="bg-card"
          style={{ 
              backgroundSize: 'contain',
              backgroundPosition: 'bottom',
              zIndex:'-1',
              borderTopRightRadius: '0.375rem',
               borderBottomRightRadius: '0.375rem' }}
        />
                <ul style={{height:"100%"}}  className="nav flex-column">
                {children}
                </ul>
            </div>
        </Collapse>

    );
}


export {
    SideBarMenu,    NavMenuItem
}
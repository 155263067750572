import React from 'react'; 
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import {sessionContext} from './services/sessionContext'; 
import { transitions, positions, Provider as AlertProvider } from '@blaumaus/react-alert'
import {  X  } from 'react-bootstrap-icons';
import { session } from './services/session';

  
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '0px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}
 

const AlertTemplate = ({ style, options, message, close }) => {

  var color = options.type === 'info' ? 'success': options.type;
  return (
 
    <div style={style} className={'alert alert-'+color} role="alert">
      {message}
      <X size={24} onClick={close}></X>
    </div> 
  )
}
 
const root = createRoot(document.getElementById('root'));
root.render(
 
   <AlertProvider template={AlertTemplate} {...options}>
    <sessionContext.Provider value={session}>
       <App /> 
    </sessionContext.Provider>
  </AlertProvider> 
);
  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

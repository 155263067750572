import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { query } from '../../services/restClient'; 
import { useErrorStatus } from '../../UnhandledErrorHandler';


const QueryRemoteSelect =()=>{
  
  const navigate = useNavigate();
  const [value,setValue] = useState(null);
  const { setError } = useErrorStatus();
    return (
        <AsyncSelect
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        noOptionsMessage={()=>"Tulemusi ei leitud."}
        id="navSearch"
        styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
            control: (provided,state) =>({
              ...provided,
              borderRadius: "0px",
              minHeight:"48px",
              backgroundColor: 'hsla(0,0%,100%,.1)', 
              borderColor: 'hsla(0,0%,100%,.1)',
              border: 0,
              // This line disable the blue border
              color: "#fff",
              boxShadow: 'hsl(0,0%,20%)',
              '&:hover': {
                backgroundColor: state.isFocused ? "#fff" : "#fff"
               }
            })
          }}
        placeholder = "Otsi ..." 
        className="w-100"
        value={value} 
        isClearable={false}
        onChange={(opts) => { 
          navigate('/'+opts.value);
          setValue(null);
        }}
        loadingMessage={() => {
          return "Laen andmeid ...";
        }}
        loadOptions={(inputValue)=>{
          return queryRemoteData(inputValue,setError)
        }}
      />
    )
}

 
const queryRemoteData = (inputValue,setError) =>
  new Promise((resolve) => {

    if (!inputValue) {
      resolve([]);
      return;
    }
    query({
      url: 'query/'+inputValue,
      method : 'GET',
      onSuccess: (result) => { 
        resolve(result.map((item)=>{
          return {
            label:item.resourcename+', '+item.name,
            value: item.controller+'/'+item.id
          };
        }));
      },
      onFailure: setError
    }); 
  });
 export default QueryRemoteSelect; 
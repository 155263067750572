import React, { useEffect, useState } from 'react';
import { query } from '../../services/restClient'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown} from 'reactstrap';
import { ThreeDots } from 'react-bootstrap-icons';
import { Link, useNavigate, useParams } from 'react-router-dom'; 
import Loader from './Loader';
import { useErrorStatus } from '../../UnhandledErrorHandler'; 
import {unstable_batchedUpdates} from 'react-dom' 
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { RemotePagination } from './RemotePagination';
 

const nameFormatter = (dataField, { name, id }) => {
  
  return (
    <Link to={'/klient/' + id} >
      <div  className="d-flex align-items-center">
        <div className="avatar avatar-xl">
          <div className="avatar-name rounded-circle ">
            <span> { name.match(/\b\w/g).join('')}</span>
        </div>
        </div>
        <Media body className="ms-2">
          <h5 className="mb-0 fs--1 ">{name}</h5>
        </Media>
      </div> 
    </Link>
  );
};

const emailFormatter = email => <a href={`mailto:${email}`}>{email}</a>;
const phoneFormatter = phone => <a href={`tel:${phone}`}>{phone}</a>;

  
const Resources = ({ columns = [], childrenOfRow=[], resourceName, resourceLink, resourceDisplayName, actionColumn, keyField, resourcePath,documentation,searchFilter }) => {
  const idKey = keyField;

  actionColumn.formatter = (dataField, item) => {///
    // Control your row with this id
    const id = item[idKey];
    return (
      <div className='text-end'>
        
        <UncontrolledDropdown size='sm'>
          <DropdownToggle color="link"  className="py-0 text-600 btn-reveal me-3">
            <ThreeDots className="fs--1" />
          </DropdownToggle>
          <DropdownMenu end className="border">
            <DropdownItem onClick={() => {
              navigate('/' + resourceLink + '-muuda/' + id);
            }}>Muuda</DropdownItem>
            <DropdownItem
              onClick={ ()=>{
                unstable_batchedUpdates(() => {
                  setWorkToDelete(id);
                  setIsDeleteOpen(true);
                })
              } }
              className="text-danger"
            >
              Kustuta
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };
 

  const [dataIsDirty,setDataIsDirty] = useState(false);
  const { setError } = useErrorStatus();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [workToDelete,setWorkToDelete] = useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false); 
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState(''); 
  var { offset } = useParams();
 
  let navigate = useNavigate();
  const limit = 18;
  offset = +(offset ?? 0); 
   
  useEffect(() => {
    let searchOptions = {
      orderby: idKey,
      desc: true,
      limit: limit,
      searchText: searchText ?? '',
      offset: offset
    };

    if(searchFilter)
    {
      searchOptions = {
        ...searchOptions,
        ...searchFilter
       };
    }  
    var url = resourceName + '/page?' + new URLSearchParams(searchOptions);
   
    query({
      url: url,
      method: 'GET',
      onSuccess: (result) => {
        if (result.items) {
          unstable_batchedUpdates(() => {
            setHasNextPage(result.hasMore);
            setData(result.items); 
            setDataIsDirty(false); 
          }); 
        }
        else {
          setError('Oodatud otsingu tulemus vigane.')
          console.log(result);
          return [];
        }
      },
      onFailure: setError
    });
    
  }, [idKey,offset,resourceName,searchText,setError,dataIsDirty,searchFilter]);
 
 
  const getPageNr = () => {
    return (offset === 0 ? 0 :  Math.floor(offset / limit)) + 1;
  }

  const onSelect = () => {
   
    //setImmediate(() => {
     // setIsSelected(!!table.current.selectionContext.selected.length);
   // });
  };
  
  const handleSearch = (searchText) => {
    setSearchText(searchText);
    if (offset!== 0) //if typing page back to 1
    {
      navigate('/' + resourcePath);
    }
  }; 
  return (
    <div>
        <ConfirmDeleteModal 
            isOpen={isDeleteOpen}
            url={ resourceName} 
            onRequestingClose={()=>{ setIsDeleteOpen(false)}} 
            onConfirm={()=> setDataIsDirty(true)}
            deleteBody={()=>{
              return[workToDelete]
            }}
            ></ConfirmDeleteModal>  
    {!data ? (<Loader></Loader>) : (
 
      <RemotePagination
      dataIsDirty={setDataIsDirty}
      resourceName={resourceName}
        childrenOfRow={childrenOfRow}
        columns={columns} 
        keyField={idKey}
        data={data}
        page={getPageNr()}
        sizePerPage={limit}
        handleSearch={handleSearch}
        hasNextPage={hasNextPage} 
        onSelect={onSelect}
        title={resourceDisplayName}
        resourceLink={resourceLink}
        documentation={documentation}
        offset={offset}
        limit={limit}
        resourcePath={resourcePath}

      />
    )} 
  </div>
  );
};

export { Resources, nameFormatter, phoneFormatter, emailFormatter };

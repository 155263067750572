import React, { Fragment } from 'react';
import { Resources } from '../_shared/Ressursid'; 
import { Link } from 'react-router-dom';


const Varuosad = () => {

  const actionColumn = {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle',
    align: 'right'
  };
  const columns = [
    {
      dataField: 'code',
      text: 'Kood',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField, { code, id }) => {
        return (
          <Link to={'/varuosa/' + id} >
            <h5 className="mb-0 fs--1">{code} </h5>
          </Link>
        );
      }
    },
    {
      dataField: 'name',
      headerClasses: 'border-0',
      text: 'Nimi',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'price',
      headerClasses: 'border-0',
      text: 'Hind',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField, { price }) => {
        return (
          <Fragment>
            {price.toFixed(2)} €
          </Fragment>
        )
      },
    },
    {
      dataField: 'quantity',
      headerClasses: 'border-0',
      text: 'Kogus',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'discount',
      headerClasses: 'border-0',
      text: 'Allahindlus',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField, { discount }) => {
        return (
          <Fragment>
            {discount.toFixed(0)} %
          </Fragment>
        )
      },
    },
    {
      dataField: 'storageName',
      headerClasses: 'border-0',
      text: 'Asukoht',
      classes: 'border-0 py-2 align-middle',
      sort: true
    }, actionColumn
  ];
  return (
    <Resources
      columns={columns}
      keyField="id"
      resourceName={'spareparts'}
      resourceLink={'varuosa'}
      resourceDisplayName={'Varuosad'}
      resourcePath="varuosad"
      actionColumn={actionColumn}
      documentation={
        <Fragment> <strong>Otsida saab Koodi, nime ja asukoha järgi</strong>
          <ul className="ms-2 list-unstyled">
            <li>Näiteks kõik summuti vedrud office asukohas
              <ul>
                <li><em>office summuti vedru</em></li>
              </ul>
            </li> 
            <li>Näiteks kõik varuosad mis algavad nimega Jahutus
              <ul>
                <li><em>jahutus*</em></li>
              </ul>
            </li>
            <li>Või näiteks kõik Mahle jahutusvaruosad
              <ul>
                <li><em>mahle jahutus*</em></li>
              </ul>
            </li>
            <li>Kui otsida ntx sõna järgi <em>CR565000S</em> siis leitakse üks toote vaste kui esineb sellise koodiga varuosa.</li>
            <li>Kui otsida aga sõna järgi <em>1364*</em> siis leitakse kõik tooted mis on seotud sissepritsesüsteemiga</li>
          </ul> </Fragment>
      }
    />
  );
};
export default Varuosad;

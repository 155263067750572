 import {
  Card,CardBody,CardFooter 
} from 'reactstrap';
import corner4 from "../../assets/img/f10.webp";
import Background from "../_shared/Background";
import { EditableCardBody } from './TooMuuda' 
import {usePost } from '../../services/restClient';

const NewCardHeader = () => {
  return (
    <Card className='d-none d-sm-none d-md-block'>
      <div className="position-relative py-6 py-lg-8">
        <Background image={corner4} overlay="1" className="rounded-soft-top" />
      </div>
    </Card>
  );
}

//create
const WorkNewCard = () => {

  const [changeJob] = usePost({ resource:'jobs'});

  return (
    <Card className="mb-3">
    <NewCardHeader />
    <CardBody className="bg-light border-top">
    <EditableCardBody { ...{ changeJob}} />
    </CardBody>
    <CardFooter className="border-top">
     
    </CardFooter>
  </Card>  
  );
}



export default WorkNewCard;
export {
  NewCardHeader
}

import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PencilFill, ThreeDots } from 'react-bootstrap-icons';
import { Building, Person } from 'react-bootstrap-icons';
import {
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,Button,Card,CardBody,CardFooter 
} from 'reactstrap'; 
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../services/restClient';
import {loadClientVehicles} from './KlientSoidukid';
import { Link } from 'react-router-dom';
import { useErrorStatus } from '../../UnhandledErrorHandler';
import Loader from '../_shared/Loader';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';

const createMarkup =(html) => ({ __html: html });

const ClientRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);

ClientRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLastItem: PropTypes.bool
};

ClientRow.defaultProps = { last: false };


 
const ReadOnlyCardHeader = ({client,isEditing}) =>{
 
    if(!client) throw new Error('client required');
    let navigate = useNavigate();
    const [collapseOne, setCollapseOne] = useState(false);
    
    return (
      <Fragment>
         <ConfirmDeleteModal 
            isOpen={collapseOne}
            url={ 'clients'} 
            onRequestingClose={()=>{ setCollapseOne(false)}} 
            onConfirm={ ()=>{ navigate('/kliendid')}}
            deleteBody={()=>{
              return[client.id]
            }}></ConfirmDeleteModal>  
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
             {getClientName(client)} ({
                 client.currentEmail?(<a href={`mailto:${client.currentEmail}`}>{client.currentEmail}</a>):( <span className="font-italic text-400 mb-1">Kehtiv email puudub</span>) })
         </h5>
          </Col>
          <Col xs="auto" >
             {client.regNr ? (
                <h6 className="text-uppercase text-600">
                  Äriklient <Building />
                </h6>
              ) : (
                  <h6 className="text-uppercase text-600">
                    Eraklient <Person />
                  </h6>
                )}
          </Col>
        </Row>
        <Row>
          <Col>
           <UncontrolledDropdown className="d-inline-block ms-2">
              <DropdownToggle color="falcon-default" size="sm">
                <ThreeDots />
              </DropdownToggle>
              <DropdownMenu> 
                <DropdownItem  className="text-danger" onClick={ ()=>  setCollapseOne(true) } >Kustuta klient</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                        navigate('/klient-muuda/'+ client.id);
                    }
                    else{
                        navigate('/klient/'+ client.id);
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Uuenda detaile') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row>
        </CardHeader>
      </Fragment>
      
      );
}

const ReadOnlyFooter= ({ client })=>{
    if(!client) throw new Error('client required');
    return (
         <Row>
        <Col>
        {<Media> 
           <Media body>
              <p className="mb-0">Klient loodi</p>
              <p className="fs--1 mb-0 text-600">{client.introducedAt}</p>
            </Media>
          </Media>}
        </Col>
        <Col className="text-end">
        </Col>
      </Row>);
}
const getClientName =(client)=>{
    return client.regNr? client.name : (client.firstName +' '+ client.lastName);
}
const ReadOnlyCardBody = ({clientVehicles, client }) => {
    if(!client) throw new Error('client required');
    if(!clientVehicles) throw new Error('clientVehicles required');
  return (
    <Row>
      <Col lg className="col-xxl-5">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">  {client.regNr ? ('Ärikliendi') : ('Erakliendi')} Informatsioon</h6>
        <ClientRow title="Id">{client.id}</ClientRow>
        <ClientRow title="Nimi">{getClientName(client)  }</ClientRow>
        <ClientRow title="Kehtiv email">
        {client.currentEmail?(<a className="py-1" href={`mailto:${client.currentEmail}`}>{client.currentEmail}</a>):( <p className="font-italic text-400 mb-1">Kehtiv email puudub</p>) }
        </ClientRow>
        <ClientRow title="Kirjeldus">
          {client.description ? (
            client.description.split('\n').map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Kirjeldus puudub</p>
            )}
        </ClientRow>
        {client.regNr && (
          <ClientRow title="Registrikood">
            {client.regNr }
          </ClientRow>
        )}
        {!client.regNr && (
          <ClientRow title="Isikukood">
            {client.personalCode ? client.personalCode : <p className="font-italic text-400 mb-0">Isikukood puudub</p>}
          </ClientRow>
        )}

       <ClientRow title="Sõidukid" isLastItem>
         {
           (clientVehicles.map((vehicle, index) => {
     
             const { id,regNr,producer,model } = vehicle;
             
             return (
               <Row  noGutters key={index}>
                  <span className="me-2">{producer}  {model} </span>   
                  (<Link to={'/soiduk/' + id} >
                  {regNr}
                  </Link> )
                
               </Row>
             );
           }))
         }
       </ClientRow>
      </Col>
      <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Kontakt Informatsioon</h6>
        <ClientRow title="Emailid">
        {client.emailAddresses ? (
            client.emailAddresses.map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Emaili aadresse pole</p>
            )} 
        </ClientRow>
        <ClientRow title="Postiaadress">
          <p className="mb-1" dangerouslySetInnerHTML={createMarkup(client.address)} />
        </ClientRow>
        <ClientRow title="Telefon">
          <a href={`tel:${client.phone}`}>{client.phone}</a>
        </ClientRow>
        <ClientRow title="Keeruline klient">
          <p> {client.isAsshole ? 'Jah' : 'Ei'}</p>
        </ClientRow>
      </Col>
    </Row>
  );

}



//display
const ClientDisplay = () => {
    
    const {id} = useParams();
    const setError = useErrorStatus();
    const [client] = useGet({ url:`clients/${id}`});
    const [clientVehicles, setClientVehicles] = useState(); 
    if(client && !clientVehicles)
    {
      loadClientVehicles({
        id,
        onSuccess: setClientVehicles ,
        onError: setError
      });
    }
   
    return (
      <Fragment> 
      {(!client||!clientVehicles) ? (
        <Loader />
         ) : ( <Card className="mb-3">
        <ReadOnlyCardHeader { ...{ isEditing:false, client}}/>
         <CardBody className="bg-light border-top">
           <ReadOnlyCardBody { ...{ client,clientVehicles}}/>
         </CardBody>
         <CardFooter className="border-top">
           {<ReadOnlyFooter  client={client}/>}
         </CardFooter>
       </Card>)
            }
    </Fragment> 
    ); 
} 

 
export default ClientDisplay;
export { 
    ReadOnlyFooter,
    ReadOnlyCardHeader
}
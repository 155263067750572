import React  from 'react'; 
import {
  CardHeader,
  Col,
  Row,Card,CardBody,CardFooter 
} from 'reactstrap';  
import { EditableCardBody} from './AsukohtMuuda'
import {   usePost } from '../../services/restClient';

const NewCardHeader = () =>{
    return (
        <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">Uus asukoht</h5>
          </Col> 
        </Row>
        </CardHeader>
      );
}
 
 

//create
const StorageNewCard = () => {
 
  const [changeStorage] = usePost({resource:'storages' });
 
    return ( 
      <Card className="mb-3">
      <NewCardHeader  />
       <CardBody className="bg-light border-top">
       <EditableCardBody { ...{ changeStorage}} />
       </CardBody>
       <CardFooter className="border-top"> 
       </CardFooter>
     </Card>
    ); 
}



export default StorageNewCard;
import React, { Fragment, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Row,
  Popover,
  PopoverBody,
  CardHeader
} from 'reactstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom'; 
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
 
 
const CustomTotal = ({ sizePerPage, page, lastIndex }) => (
  <span>
    Kirjed {(page - 1) * sizePerPage + 1} kuni {lastIndex}
  </span>
);

const PagerButtons =({page,hasNextPage,paginationProps,offset,limit,resourcePath})=>{
  
   let navigate = useNavigate();
   
  const handleNextPage = ({ page, onPageChange }) => () => {
     
    var redirect = '/'+resourcePath+'/'+(offset + limit);
    navigate(redirect);
  };
  const handlePreviousPage = ({ page, onPageChange }) => () => {
  
    var redirect = '/'+resourcePath+'/'+(offset - limit);
    navigate(redirect);
  };
  return (<Fragment>
    <Button
      key="btnPrevPage"
      color={page === 1 ? 'light' : 'primary'}
      size="sm"
      onClick={handlePreviousPage({ ...paginationProps })}
      disabled={page === 1}
      className="px-4"
    >
      Eelmine lk.
    </Button>
    <Button
      key="btnNextPage"
      color={!hasNextPage ? 'light' : 'primary'}
      size="sm"
      onClick={handleNextPage({ ...paginationProps })}
      disabled={!hasNextPage}
      className="px-4 ms-2"
    >
      Järgmine lk.
    </Button>
  </Fragment>)
}

const SearchInputComponent = ({ handleSearch, documentation  }) => {
  const [isShown, setIsShown] = useState(false);
  let input;
  const handleClick = () => {
    handleSearch(input.value); 
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  }
  return (     
  <Row className="align-items-center " >
    <div className="input-group d-flex justify-content-end">
      <input type="text" className="form-control form-control-sm" style={{maxWidth:"200px"}} onKeyDown={handleKeyDown} ref={n => input = n} placeholder="Otsi..."></input>
      <button className="btn btn-sm btn-primary" type="button" onClick={handleClick} key="btn-search0" name='btn-search0' id="btn-search0">Otsi</button>
        {documentation && <Fragment>
          <Link id='helpIcon'  key="helpIcon"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className='ms-1' to="#">
            <InfoCircle size={24}></InfoCircle>
          </Link>
          <Popover
            placement="left-start"
            isOpen={isShown}
            container='body'
            target="btn-search0"
            toggle={() => { }}
          >
            <PopoverBody >
              {documentation}
            </PopoverBody>
          </Popover>
        </Fragment>}
    </div>
  </Row>)
}
 
export const RemotePagination = ({
  dataIsDirty,
  resourceName,
  childrenOfRow,
  columns,
  keyField,
  data,
  page,
  sizePerPage,
  hasNextPage, 
  title, 
  documentation,
  offset,
  limit,
  resourcePath,  
  handleSearch
}) =>

{ 
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [isDeleteOpen,setIsDeleteOpen] = useState();
   
  const handleCheckAll = (target) => {
    if (target.checked) {

      var allChecked = data.reduce((map, obj) => {
        return {
          ...map,
          [obj[keyField]]: target.checked
        };
      }, {})
      setSelectedRows(allChecked);
    }
    else {
      setSelectedRows({});
    }
    setIsAllSelected(target.checked);
  }

  const handleCheckbox = (event, row) => {
    setSelectedRows({
      ...selectedRows,
      [row[keyField]]: event.target.checked
    }); 
  };
  const getSelected=()=>{
    var selected = Object.entries(selectedRows).filter(x=>{
      let [, value] = x;
      return value === true;
    }).map(x=>{
      let [key] = x;
      return key;
    }) 
    return selected;
  }

  const apply = ()=>{
    setIsDeleteOpen(true);
  }
    
  return (<div> 
        <ConfirmDeleteModal 
            isOpen={isDeleteOpen}
            url={ resourceName} 
            onRequestingClose={()=>{ setIsDeleteOpen(false)}} 
            onConfirm={()=>{dataIsDirty(true)}}
            deleteBody={getSelected}></ConfirmDeleteModal> 
        
    <Card key="crd" className="mb-3">
        <CardHeader>
        <Row  className="align-items-center">
            <Col lg={6}>
              <h5>{title}</h5>
            </Col>
            <Col   lg={6}>
               {( getSelected().length>0) ? ( 
                     <Row className="align-items-center">
                        <Col className='d-flex justify-content-end' >
                          <h6 className='mt-1'>Hulga tegevus</h6>
                        </Col>
                        <Col >
                          <InputGroup key="htgv"> 
                            <Input type="select" bsSize='sm' id="bulk-select">
                              <option value="delete">Kustuta</option> 
                            </Input>

                            <button type='button' className="btn btn-primary btn-sm" size="sm" onClick={ apply} >
                              Rakenda
                            </button>
                          </InputGroup>
                        </Col> 
                      </Row>   
                  ) : (
                    <SearchInputComponent key="srch" handleSearch={handleSearch} documentation={documentation}    >
                    </SearchInputComponent>
                  )}
            </Col>  
          </Row> 
      </CardHeader> 
                <CardBody key="crdbdy"  > 
                  <Row key="tblRow" >
                  <div className="table-responsive" >
                    <table
                        className="table table-dashboard   table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap">
                        <thead>
                            <tr className="bg-200 text-900 border-y border-200">
                                <th className="selection-cell-header" data-row-selection="true"
                                     style={{border:" 0px", verticalAlign: "middle"}}>
                                <Input
                                  type="checkbox"
                                  id="chkAll" 
                                  checked={isAllSelected}
                                  onChange={({ target }) => {
                                    handleCheckAll(target);
                                  }}
                                />
                                </th>
                                {
                                  columns.map((item,i)=>{
                                    return ( 
                                      <th key={"th"+i}  className={item.headerClasses}>{item.text}</th>
                                    )
                                  })
                                } 
                            </tr>
                        </thead>
                        <tbody> 
                            { 
                            data.map((item,rowIndex)=>{ 
                             
                               return (
                                <Fragment key={"pagabletablefragm"+rowIndex}>
                                 <tr key={"tr"+rowIndex} className="btn-reveal-trigger border-top border-200">
                                  <td key={"td"+rowIndex} className="selection-cell" style={{border:" 0px", verticalAlign: "middle"}}>
                                        <Input
                                        type="checkbox"
                                        key={"chkRow"+rowIndex} 
                                        id={"chkRow"+rowIndex} 
                                       checked={ selectedRows[item[keyField]] || false } 
                                       onChange={(event) => {
                                         handleCheckbox(event, item)
                                       }} 
                                      />
                                  </td>
                                  {
                                    columns.map((column,columnIndex)=>{ 
                                      const value =  column.dataField ? item[column.dataField]: '';  
                                      return ( 
                                        <td key={"td"+columnIndex} className={column.classes+" text-nowrap"}>{ (column.formatter ? column.formatter(value,  item ) : value)  }</td>
                                      )
                                    })
                                  }   
                               </tr> 
                                </Fragment> 
                               )
                            })} 
                        </tbody>
                    </table> 
                  </div>
                  </Row>
                  <Row key="tblFooterRow" className="px-1 py-3">
                    <Col key="tblFc1" className="pl-3 fs--1">
                      <CustomTotal
                        key="cstmTotal"
                        sizePerPage={sizePerPage}
                        //  totalSize={totalSize}
                        page={page}
                        lastIndex={sizePerPage * page}
                      />

                    </Col>
                    <Col key="tblFc2" xs="auto" className="pr-3">
                       <PagerButtons
                        offset={offset}
                        limit={limit}
                        page={page} 
                        hasNextPage={hasNextPage}
                        resourcePath={resourcePath}
                       ></PagerButtons>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

    
  </div>);
};
 

import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { House } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'; 

const ErrorPage =  ({ url,status,text }) => {
    
  return (
    
    <Card>
      <CardBody className="p-5">
        <div className="text-center display-1 text-300 fs-error">{status}</div>
        <p className="text-center lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Käitlemata viga!
        </p>
        <hr />
        <p>{url}</p> 
        <p>{text.split("\n").map((text, i) => i ? [<br/>, text] : text)} </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/toolaud">
          <House  className="me-2" />
          Töölauale
        </Link>
      </CardBody>
    </Card>
  );
};

export default ErrorPage;
import { forwardRef, useState,useImperativeHandle } from 'react';
import {
    Button,Modal,ModalBody,ModalFooter
} from 'reactstrap';

export const DeleteModal = forwardRef( (props,ref)=>{

    const {onDelete} = props;

    useImperativeHandle(ref, () => ({
        show() {
            setIsOpen(true);
        }
    }));
    const [isOpen,setIsOpen] = useState(false);

    return (<Modal  centered isOpen={isOpen}>
        <ModalBody>Kindel, et soovid andmed kustutada? Tagasi neid ei saa.</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => { 
              setIsOpen(false);
            }}>
            Sulge
          </Button>
          <Button
            color="primary"
            onClick={ ()=>{
                onDelete();
                setIsOpen(false);
            }}>
            Jah kustuta
          </Button>
        </ModalFooter>
        </Modal>)
})
import React, { Fragment, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useParams } from "react-router";
import { X } from "react-bootstrap-icons";
import {
  Col,
  Row,
  Input,
  Form,
  FormGroup,
  Table,
  Label,
  InputGroup,Card,CardBody,CardFooter 
} from "reactstrap";
import { Plus, Check } from "react-bootstrap-icons";
import FormGroupInput from "../_shared/FormGroupInput";
import { Link, useNavigate } from "react-router-dom";
import { useDelete, useGet, usePut } from "../../services/restClient"; 
import { ReadOnlyCardHeader, ReadOnlyFooter } from "./Klient";
import { Button } from "reactstrap";
import Loader from '../_shared/Loader';
import { useAlert } from "@blaumaus/react-alert";


const isIterableArray = array => Array.isArray(array) && !!array.length;

const aadressRemoteData = (inputValue) =>
  new Promise((resolve) => {
    if (!inputValue) {
      resolve([]);
      return;
    }
    const url =
      "https://inaadress.maaamet.ee/inaadress/gazetteer?" +
      new URLSearchParams({
        results: 20,
        appartment: 1,
        unik: 0,
        address: inputValue,
      });
   
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (!json.addresses) return [];
        const options = json.addresses.map((addr, index) => {
          return {
            value: index + 1,
            label: [
              addr.aadresstekst +
                (!addr.kort_nr ? "" : "-" + addr.kort_nr).trim(),
              addr.asustusyksus,
              addr.asum,
              addr.omavalitus,
              addr.maakond,
              addr.sihtnumber,
            ]
              .filter((item) => item)
              .join(", "),
          };
        });
        resolve(options);
      })
      .catch((error) => {
        console.error(error);
      });
  });

const EditableCardBody = ({
  client = {
    id: 0,
    name: "",
    regNr: "",
    firstName: "",
    lastName: "",
    personalCode: "",
    phone: "",
    currentEmail: "",
    emailAddresses: [],
    address: "",
    description: "",
    isAsshole: false,
  },
  changeClient,
  clientType
}) => {

   
  const [name, setName] = useState(client.name??'');
  const [firstName, setFirstName] = useState(client.firstName??'');
  const [lastName, setLastName] = useState(client.lastName)??'';
  const [personalCode, setPersonalCode] = useState(
    client.personalCode??''
  );
  const [regNr, setRegNr] = useState(client.regNr??'');
  const [phone, setPhone] = useState(client.phone??'');
  const [address, setAddress] = useState(client.address)??'';
  const [description, setDescription] = useState(client.description??'');
  const [isAsshole, setIsAsshole] = useState(client.isAsshole??false);
  const [emailAddresses, setEmailAddresses] = useState(client.emailAddresses??[]);
  const [currentEmail, setCurrentEmail] = useState(client.currentEmail??'');
  const [newEmail, setNewEmail] = useState();
  let navigate = useNavigate()
  const alert = useAlert();
  let emailAddressesData;

  const setEmailAddressData = () => {
    emailAddressesData = emailAddresses.map((x) => {
      return {
        checked: currentEmail === x,
        addr: x,
        state: currentEmail === x ? "(kehtiv)" : "",
      };
    });
  };

  setEmailAddressData();
  const setActiveEmail = (addr) => {
    setCurrentEmail(addr);
    setEmailAddressData();
  };

  const removeEmail = (addr) => {
    setEmailAddresses(emailAddresses.filter((item) => item !== addr));
    setEmailAddressData();
  };

  const addEmail = () => {
    setEmailAddresses([...emailAddresses, newEmail]);
    if (emailAddresses.length === 0) setCurrentEmail(newEmail);
    setEmailAddressData();
  };

  const changeResourceHandler = (e) => {
    e.preventDefault();
    var body ={
      name,
      firstName,
      lastName,
      emailAddresses,
      currentEmail,
      phone,
      address,
      description,
      isAsshole,
      regNr,
      personalCode, 
      introducedAt: new Date(),
    };
    const posting = client.id === 0;
    changeClient(body,(response)=>{
      if (posting) {
        navigate('/klient/' + Number(response).toString());
        alert.show('Uus klient lisatud.');
      }
      else {
          navigate('/klient/' + client.id);
          alert.show('kliendi andmed muudetud.');
      }
    });
    
  };

  const EmailRow = ({ addr, checked = false, handleChange, handleRemove }) => (
    <tr>
      <td>
        {checked ? (
          <Label>
            <strong>{addr}</strong>
          </Label>
        ) : (
          <Label>{addr}</Label>
        )}
      </td>
      <td>
      <FormGroup check>
        <Input
            id={`ticketPrice${addr}`}
            type="radio"
            name="ticketPriceRadio"
            checked={checked}
            onChange={({ target }) => handleChange(addr, "name", target.value)}
          ></Input>
        </FormGroup> 
      </td>
      <td>
        <Link color="link" to="#" size="sm" onClick={() => handleRemove(addr)}>
          <X size={24}></X>
        </Link>
      </td>
    </tr>
  );

   
  return (
    <Form onSubmit={changeResourceHandler}>
      <Row>
        <Col lg={12}>
          <FormGroup>
            <div className="form-check">
              <Input
                type="checkbox"
                id="is-asshole"
                checked={isAsshole}
                onChange={({ target }) => {
                  setIsAsshole(target.checked);
                }}
              />
              <Label for="exampleCheckLbl" check>
                Keeuline klient
              </Label>
            </div>
          </FormGroup>
        </Col>
        {clientType === "legalclients" ? (
          <Fragment>
            <Col lg={6}>
              <FormGroupInput
                id="name"
                label="Ettevõtte nimi"
                value={name}
                onChange={({ target }) => setName(target.value)}
                required
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="regNr"
                label="Registrikood"
                value={regNr}
                onChange={({ target }) => setRegNr(target.value)}
                required
              />
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col lg={6}>
              <FormGroupInput
                id="first-name"
                label="Eesnimi"
                value={firstName}
                required
                onChange={({ target }) => setFirstName(target.value)}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="last-name"
                label="Perenimi"
                value={lastName}
                onChange={({ target }) => setLastName(target.value)}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="personal-id-number"
                label="Isikukood"
                value={personalCode}
                onChange={({ target }) => setPersonalCode(target.value)}
              />
            </Col>
          </Fragment>
        )}
        <Col lg={6}>
          <FormGroupInput
            id="phone"
            label="Telefon"
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
            type="tel"
          />
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label htmlFor="aadressandmed">Aadress andmed</Label>
            <AsyncCreatableSelect
              cacheOptions
              defaultInputValue={address}
              defaultOptions
              formatCreateLabel={(val) => {
                return "kasuta: " + val;
              }}
              onChange={(value) => {
                setAddress(value.label);
              }}
              loadingMessage={() => {
                return "Laen avalikke aadressandmeid";
              }}
              loadOptions={aadressRemoteData}
            />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroupInput
            id="description"
            label="Kirjeldus"
            value={description}
            onChange={({ target }) => setDescription(target.value)}
            type="textarea"
            rows="5"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Fragment>
            <Table borderless size="sm" className="mt-2">
              <tbody>
                {isIterableArray(emailAddressesData) &&
                  emailAddressesData.map((address, index) => (
                    <EmailRow
                      {...address}
                      id={index}
                      handleChange={setActiveEmail}
                      handleRemove={removeEmail}
                      handleAdd={addEmail}
                      key={index}
                    />
                  ))}
              </tbody>
            </Table>
          </Fragment>
          <FormGroup>
            <InputGroup size="sm">
              <Input
                id="newEmail"
                label="Email"
                type="email"
                onChange={({ target }) => setNewEmail(target.value)}
              />
              <Button
                  color="falcon-default"
                  onClick={() => addEmail(newEmail)}
                >
                  <Plus></Plus>
                  Lisa email
                </Button>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-end">
          <Button color=" btn-primary" size="sm" type="submit">
            {" "}
            <Check size={20} className="me-1"></Check>
            Salvesta muudatused
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

//edit
const ClientEditCard = () => {
  const { id } = useParams();
  if (!id) throw new Error("id required");

  const [client] = useGet({ url:`clients/${id}` }); 
  const clientType = !client? null:  (client.isPrivate ? "privateclients" : "legalclients");
  const [changeClient] = usePut({ url:`${clientType}/${id}` });
  const [deleteClient] = useDelete({ url:`${clientType}/${id}` });
   
  return ( 
    <Fragment> 
    {!client ? (
      <Loader />
       ) : ( <Card className="mb-3">
     <ReadOnlyCardHeader { ...{isEditing:true, client,deleteClient}} />
       <CardBody className="bg-light border-top">
       <EditableCardBody { ...{ client,changeClient, clientType }} />
       </CardBody>
       <CardFooter className="border-top">
       <ReadOnlyFooter client={client} />
       </CardFooter>
     </Card>)
          }
  </Fragment>  
  );
};

export default ClientEditCard;
export { EditableCardBody };

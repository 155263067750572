import moment from 'moment';
import Cookies from 'universal-cookie'; 
import {options} from './options';
const cookies = new Cookies();
var cookieOpts = {
  path:'/',
  domain:options.url.API_HOST
};
const getJwtToken = ()=>{
    return cookies.get('jwt_token'); 
}
const setJwtToken =(jwt)=>{  
  var timeout = +localStorage.getItem("session-timeout");
  var expires = moment(new Date()).add(timeout, 'seconds').toDate()
  var cookieOptions ={
    ...cookieOpts,
    secure:true, 
    expires
  };
  cookies.set("jwt_token",jwt,cookieOptions)
}
 
export const session = {
    user:{
      isAuthorized:()=>{  
        const jwt = getJwtToken(); 
        return jwt && jwt.length >0;
      }, 
      removeJwtToken :()=>{ 
        
        cookies.remove('jwt_token', cookieOpts);
      }
    },
    options:{
        dialogTimeout:30,
        appName:"myapp",
        setTimeout:(value)=>{ 
            localStorage.setItem("session-timeout",value);
        },
        getTimeout:()=>{
            var timeout = localStorage.getItem("session-timeout");
            return timeout;
        }
    }
  }

  export {
    getJwtToken,setJwtToken
  };
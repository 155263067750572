import React from 'react';
import AsyncSelect from 'react-select/async';
import { page } from '../../services/restClient';
import { useErrorStatus } from '../../UnhandledErrorHandler';

const SparePartRemoteSelect = ({ id, value, placeholder, onChange }) => {


  const customStyles = {
    control: base => ({
      ...base,
      minHeight: '29px',
      height: '29px',
      minWidth:'135px'
    }),

    valueContainer: base => ({
      ...base,
      height: '29px',
    }),
    singleValue: base => ({
      ...base,
      top: '40%',
    }),
    input: base => ({
      ...base,
      display:'inline-block',
      menu: provided => ({ ...provided, zIndex: 9999 }),
    })
  };


  const { setError } = useErrorStatus();
  return (
    <AsyncSelect
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      styles={customStyles}
      id={id}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      placeholder={placeholder}
      value={value}
      isClearable={false}
      onChange={onChange}
      loadingMessage={() => {
        return "Laen toote andmeid";
      }}
      loadOptions={(inputValue) => {
        return sparePartRemoteData(inputValue, setError)
      }}
    />
  )
}

const sparePartRemoteData = (inputValue, setError) =>
  new Promise((resolve) => {

    if (!inputValue) {
      resolve([]);
      return;
    }

    page({
      resourceName: 'spareparts',
      searchText: inputValue + '*',
      whenReady: (items) => {
        resolve(items.map((part) => {
          return {
            label: part.code,
            value: part.code,
             name: part.name,
             price: part.price,
            unit: part.unit
          };
        }));
      },
      setError
    })
  });
export default SparePartRemoteSelect;
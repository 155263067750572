import { Fragment, useState,useRef, forwardRef,useImperativeHandle, useEffect } from 'react'; 
import { unstable_batchedUpdates } from 'react-dom';
import { 
   Button,FormGroup,Form,Modal,ModalHeader,ModalBody,ModalFooter,Label
} from 'reactstrap';  
import { usePut, useGet, useDelete } from '../../services/restClient';
import { Send} from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import Select from 'react-select';
import { useAlert } from '@blaumaus/react-alert';
import { ConfirmModal } from '../_shared/ConfirmModal'; 
import { DeleteModal } from '../_shared/DeleteModal';
import { useNavigate} from 'react-router-dom';

const OfferDelete = forwardRef((props,ref)=>{

    const {workId,offerId} = props;
    var modelRef = useRef();
    const [deleteOffer] = useDelete( {url:`work/${workId}/offers/${offerId}`});
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({ 
        delete(){
            modelRef.current.show();
        }
    }));

    return (
        <DeleteModal ref={modelRef} onDelete={
            ()=>{ 
              unstable_batchedUpdates(()=>{
                  deleteOffer(()=>{ 
                    navigate('/too/'+workId);
                    navigate(0);
                  })
              })
            }
          }></DeleteModal>
    )
})

const InvoiceOptionsDialog = forwardRef((props,ref)=>{

    const {onIssue} = props; 
    const paymentOptions =["sularahas","pangaülekandega"];
    const [payment,setPayment] = useState(0);
    const [dueDays,setDueDays] = useState(10);
    const [isOpen,setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({ 
        show(){
            setIsOpen(true);
        },
        hide(){
            setIsOpen(false);
        }
    }));

    return  (
        <Modal  centered isOpen={isOpen}>
        <ModalHeader>Arve väljastamine</ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                   <Label>Makseviis</Label>
                        <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={{label:paymentOptions[payment],value:payment}}  
                        name="email"
                        isClearable={false}
                        isSearchable={false}
                        onChange={(target)=>{
                            setPayment(target.value);
                        }}
                        options={paymentOptions.map((item,index)=>{
                            return {label:item,value:(index)};
                        })}
                        />
                </FormGroup>
                <FormGroup>
                <FormGroupInput
                    id="dueDays"
                    label="Tähtaeg (päevades)"
                    value={dueDays }
                    type="number"
                    step="1"
                    onChange={({ target }) => setDueDays(target.value)}
                />
                </FormGroup>
            </Form> 
        </ModalBody>
        <ModalFooter>
        <Button size="sm"
            onClick={() => { 
            setIsOpen(false);
            }}>
            Katkesta
        </Button>
        <Button  size="sm" color="primary" onClick={()=>onIssue({payment:(+payment)+1,dueDays})}   role="button"    >
                    <Send></Send> Väljasta
            </Button> 
        </ModalFooter>
    </Modal> 
    )
})

const IssuePricing =forwardRef((props,ref)=>
{
    const {id,setWorkStatus,offerId} = props;
    const url = !offerId ? `work/${id}/invoice/issue`: `work/${id}/estimate/issue/${offerId}`  ;
    const invoiceOptionsRef = useRef();
    const [issue] = usePut( {url}); 
    useImperativeHandle(ref, () => ({ 
        issue(){ 
            if(offerId)
            {
                issue('', (response)=>{ 
                    setWorkStatus(null);
                })
            }
            else{
                invoiceOptionsRef.current.show();
            } 
        }
    }));

    return (<Fragment>
        {!offerId&&<InvoiceOptionsDialog ref={invoiceOptionsRef} onIssue={({payment,dueDays})=>{
             issue({
                 paymentType: payment,
                 dueDays:dueDays
             }, (response)=>{
               
                invoiceOptionsRef.current.hide();
                setWorkStatus(null); 
            })
        }} ></InvoiceOptionsDialog>}
    </Fragment>)
})

const SendPricing =forwardRef((props,ref)=>{
  
    const {id,offerId,setPricing,clientName} = props; 
    const [isOpen,setIsOpen] = useState(false);
    const url = (isOpen? `work/client/${id}/emailAddresses`:null); 
    const [clientEmails,setClientEmails] = useGet({url:url})
    const [emailAddress,setEmailAddress] = useState(''); 
    const sendUrl = offerId? `work/${id}/estimate/send/${offerId}` : `work/${id}/invoice/send`;
    const [sendPricing] = usePut( {url:sendUrl}); 
   
    
    useEffect(()=>{
        if(clientEmails) setEmailAddress(clientEmails.find(x=>x.isActive)?.address);
    },[clientEmails])
    
    const send =()=>{
        sendPricing( {
            emailAddress:emailAddress,
            displayName:clientName
        }, (response)=>{
            unstable_batchedUpdates(()=>{
                //if(setOffer) setOffer(response);
                //else   window.location.reload();
                setIsOpen(false); 
                setPricing(null); //todo do setpricing
            }); 
            alert.show('Saadetud');
        })
    }
    const alert = useAlert();
    useImperativeHandle(ref, () => ({ 
        send(){
            unstable_batchedUpdates(()=>{ 
                setIsOpen(true);  
                setClientEmails(null);
            }); 
        }
    }));

    return (  <Fragment>
                <Modal  centered isOpen={isOpen}>
                <ModalHeader>Saada pdf</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                        {!(clientEmails?.length > 0)?( 
                            <FormGroupInput
                            id="name"
                            label="Emaili aadress"
                            value={emailAddress }
                            onChange={({ target }) => setEmailAddress(target.value)}
                        />):( 
                            <Fragment>
                               <Label  >Vali emailiaadress</Label>
                                <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={{label:emailAddress,value:emailAddress}}  
                                name="email"
                                onChange={(target)=>{
                                    setEmailAddress(target.value);
                                }}
                                options={clientEmails.map((item)=>{
                                    return {label:item.address,value:item.address};
                                })}
                                />
                          </Fragment>
                           )}
                      
                        </FormGroup>
                    </Form> 
                </ModalBody>
                <ModalFooter>
                <Button size="sm"
                    onClick={() => { 
                    setIsOpen(false);
                    }}>
                    Katkesta
                </Button>
                <Button  size="sm" color="primary" onClick={send}   role="button"    >
                            <Send></Send> Saada 
                    </Button> 
                </ModalFooter>
            </Modal> 
</Fragment>)
})
const CancelPricing = forwardRef((props,ref)=>{
    const {id,offerId,setWorkStatus, sentOn} = props;  
    const url = offerId? `work/${id}/estimate/cancel/${offerId}`:`work/${id}/invoice/cancel`;
    const [cancelPricing] = usePut({url});
    const [isOpen,setIsOpen] = useState();
    const [message,setMessage] = useState();
    const cancel = ()=>{
        cancelPricing('',(response)=>{ 
            setWorkStatus(null);
        })
    }
    useImperativeHandle(ref, () => ({ 
        cancel(){ 
            var message = "Kas oled kindel et soovid koostatud "+(offerId?'pakkumise':'arve')+" dokumenti tühistada? Uuesti  väljastamisel ei pruugi andmed olla enam samad.";
            if(sentOn) {
                message  = message + " Samuti on see "+(offerId?'pakkumine':'arve')+" kliendile juba saadetud";
            } 
            setMessage(message);
            setIsOpen(true) ; 
        }
    }));
    return ( 
        <ConfirmModal 
        isOpen={isOpen} 
        message={message} 
        onRequestingClose={()=>{setIsOpen(false)}}
        onConfirm={cancel}>
        </ConfirmModal> 
     )

})

export {
    IssuePricing ,SendPricing ,CancelPricing, OfferDelete
}
import React  from "react";
import { Navigate  } from "react-router-dom";
import AuthLayout from "../pages/_layout"; 
import CacheBuster from '../CacheBuster';
import packageInfo  from '../../package.json';
import { Spinner } from "reactstrap";

export default function AppRoute({
  user,
  isPrivate,
  icon,
  resource,
  resourceDisplayName, 
   children
  }) {
    
   

    if (isPrivate && !user.isAuthorized()) {
      return <Navigate  to="/autentimine/logisisse" />;
    }
   
    const isProduction =  process.env.NODE_ENV === 'production';
       
    return (
      <CacheBuster
              resource={resource}
              currentVersion={packageInfo.version}
              isEnabled={isProduction} //If false, the library is disabled.
              isVerboseMode={true} //If true, the library writes verbose logs to console.
              loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
              metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
            >{ user.isAuthorized() ? (
                <AuthLayout
                  body={children}
                  head={ icon}
                  resource={resource}
                  resourceDisplayName={resourceDisplayName}
                ></AuthLayout>
              ):(
                {children}
              )
            }</CacheBuster> 
    );
}
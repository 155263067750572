import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router'; 
import {
    Col,
    Row, Form
} from 'reactstrap';
import { Check } from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import { useNavigate } from 'react-router-dom';
import {  useGet,usePut,useDelete } from '../../services/restClient';
import { ReadOnlyCardHeader, ReadOnlyFooter } from './Soiduk'
import { Button,Label,FormGroup,Card,CardBody,CardFooter  } from 'reactstrap';
import ClientRemoteSelect from '../Klient/KlientRemoteSelect';
import Loader from '../_shared/Loader'; 
import { useAlert } from '@blaumaus/react-alert';
import Moment from 'moment';
const EditableCardBody = ({
    vehicle = {
        id: 0,
        regNr: '',
        producer: '',
        model: '',
        vin: '',
        odo: 0,
        body: '',
        drivingSide: '',
        engine: '',
        productionDate: null,
        region: '',
        series: '',
        transmission: '',
        description: '',
        ownerId:0,
        ownerName:''
    }, 
    changeVehicle
}) => {
 
  
    const [owner,setOwner] = useState({label:vehicle.ownerName??'',value:vehicle.ownerId??0})

    const [regNr, setRegNr] = useState(vehicle.regNr??'');
    const [producer, setProducer] = useState(vehicle.producer??'');
    const [model, setModel] = useState(vehicle.model??'');
    const [vin, setVin] = useState(vehicle.vin??'');
    const [odo, setOdo] = useState(vehicle.odo);
    const [body, setBody] = useState(vehicle.body??'');
    const [drivingSide, setDrivingSide] = useState(vehicle.drivingSide??'');
    const [transmission, setTransmission] = useState(vehicle.transmission??'');
    const [engine, setEngine] = useState(vehicle.engine??'');
    var formattedProductionDate = vehicle.productionDate? Moment( vehicle.productionDate).format('YYYY-MM-DD'): null; 
    const [productionDate, setProductionDate] = useState(formattedProductionDate);
    const [region, setRegion] = useState(vehicle.region??'');
    const [series, setSeries] = useState(vehicle.series??'');

    const [description, setDescription] = useState(vehicle.description??'');
    let navigate = useNavigate()
    const alert = useAlert();

    const changeResourceHandler = e => {
        e.preventDefault();
       
        const requestBody = { regNr, producer,ownerId:+owner.value, vin, odo: +odo, body, model,drivingSide, series, transmission, engine, productionDate: productionDate? new Date(productionDate):null, region, description, introducedAt: new Date() };
        const posting = vehicle.id === 0;
        changeVehicle(requestBody,(response)=>{

            if (posting) {
                navigate('/soiduk/' + Number(response).toString());
                alert.show('Uus soiduk lisatud.');
            }
            else {
                navigate('/soiduk/' + vehicle.id);
                alert.show('Soiduk andmed muudetud.');
            }
        }) 
    };

 

    return (
        <Form onSubmit={changeResourceHandler}>
            <Row>
            <Col lg={6}>
                    <FormGroupInput
                        id="regNr"
                        label="Reg. nr"
                        value={regNr} required
                        onChange={({ target }) => setRegNr(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="vin"
                        label="VIN"
                        value={vin}
                        onChange={({ target }) => setVin(target.value)} 
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="producer"
                        label="Mark"
                        value={producer}
                        onChange={({ target }) => setProducer(target.value)}
                    />
                </Col>
                  <Col lg={6}>
                    <FormGroupInput
                        id="model"
                        label="Mudel"
                        value={model}
                        onChange={({ target }) => setModel(target.value)}
                    />
                </Col>
                <Col xs={12}>
          <FormGroup>
            <Label htmlFor="omanik">Omanik</Label>
            <ClientRemoteSelect
              placeholder = "Kirjuta omaniku leidmiseks ..."
              value={owner}
              onChange={(opts) => {
                setOwner(opts);
              }}
            />
          </FormGroup>
        </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="odo"
                        label="Läbisõit"
                        value={odo}
                        onChange={({ target }) => setOdo(target.value)}
                        type="number"
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="body"
                        label="Kere"
                        value={body}
                        onChange={({ target }) => setBody(target.value)} 
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="drivingSide"
                        label="Sõidupool"
                        value={drivingSide}
                        onChange={({ target }) => setDrivingSide(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="transmission"
                        label="Käigukast"
                        value={transmission}
                        onChange={({ target }) => setTransmission(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="engine"
                        label="Mootor"
                        value={engine}
                        onChange={({ target }) => setEngine(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="productionDate"
                        label="Tootmiskuu"
                        value={productionDate}
                        onChange={({ target }) => setProductionDate(target.value)}
                        type="date"
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="region"
                        label="Regioon"
                        value={region}
                        onChange={({ target }) => setRegion(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="series"
                        label="Seeria"
                        value={series}
                        onChange={({ target }) => setSeries(target.value)}
                    />
                </Col>
                <Col xs={12}>
                    <FormGroupInput
                        id="description"
                        label="Kirjeldus"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        type="textarea"
                        rows="5"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-end">
                    <Button
                        color=" btn-primary"
                        size="sm"
                        type="submit"
                    > <Check size={20} className="me-1"></Check>
                      Salvesta muudatused
                  </Button>
                </Col>
            </Row>
        </Form>
    );
}

//edit
const VehicleEditCard = () => {
    const { id } = useParams();
    if (!id) throw new Error('id required');

    const [vehicle] = useGet({ url:`vehicles/${id}`});  
    const [changeVehicle] = usePut({ url:`vehicles/${id}`});
    const [deleteVehicle] = useDelete({ url:`vehicles/${id}`});
    return (
        <Fragment> 
            {!vehicle ? (
            <Loader />
            ) : ( <Card className="mb-3">
                <ReadOnlyCardHeader { ...{isEditing:true, vehicle,deleteVehicle}} />
                <CardBody className="bg-light border-top">
                <EditableCardBody { ...{ vehicle,changeVehicle}} />
                </CardBody>
                <CardFooter className="border-top">
                <ReadOnlyFooter vehicle={vehicle} />
                </CardFooter>
                </Card>)
                }
        </Fragment> 
    );
}



export default VehicleEditCard;
export {
    EditableCardBody
}

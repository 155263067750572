import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types'; 
import { Button, Col, Form,  FormFeedback,  FormGroup, Input, Row } from 'reactstrap'; 
import LoginImg from '../../../assets/img/m2.webp'
import { query, usePost } from '../../../services/restClient'; 
import { Link, Navigate, useNavigate  } from 'react-router-dom'; 
import {sessionContext} from '../../../services/sessionContext';
import { Facebook, Google } from 'react-bootstrap-icons';
import { useForm } from "react-hook-form";
const ValidatedInput = React.forwardRef((props, ref) => ( 
    <Input innerRef={ref} {...props} /> 
  ));

const RegisterForm = () => {
  const hasLabel = true;
  const [message,setMessage] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm(); 
  const postRegistration =  (data)=>{
    query({
        url:'users/register' ,method: 'POST',body:data, onSuccess:(response)=>{
            navigate('/autentimine/kinnita/'+response);
        }, onFailure:(fail)=>{ 
            console.log(fail);
            if(fail?.text === "EmailAlreadyRegistered") 
            {
              setMessage("Sellise emailiga konto on juba registreeritud."); 
            } 
            else if(fail?.text === "UsernameTaken") 
            {
              setMessage("Kasutanimi on kasutuses, vali teine."); 
            }  
        }
      });
  }
 const navigate = useNavigate();
  const [showAlert,setShowAlert] = useState();
  
  return (   <div className="p-4 card-body">  
    <div className="d-flex justify-content-end"> 
    <p className="mb-0 fs--1"><span className="fw-semi-bold">Juba kasutaja? </span><a
            href="/autentimine/logisisse">Logi sisse</a></p>
   </div>
    <Form onSubmit={handleSubmit((data) => postRegistration(data))}>
          {message&&
            <div role="alert" className="mt-2 fade alert alert-danger alert-dismissible show">
             <button type="button" className="btn-close" onClick={()=> setMessage(null) } aria-label="Close alert"></button>
             <div className="alert-heading h6">{message}</div> 
             </div>}
             
      <FormGroup  className="mb-3">
        {hasLabel && <label>Kasutajanimi</label>}
         <ValidatedInput
          placeholder='Kasutajanimi' 
          {...register('username',  { required: 'Sisesta kasutajanimi' })}
          invalid={!!errors.username}  
        />  
        <FormFeedback invalid="true">{errors.username?.message}</FormFeedback>
      </FormGroup> 
      <FormGroup className="mb-3">
        {hasLabel && <label>Emaili aadress*</label>}
        <ValidatedInput
          placeholder='Emaili aadress'
          type="email"
          {...register("email", {
            required: 'Email nõutud',
            pattern: {
                value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                message: 'Emaili aadress pole korrektne'
            }
        })} 
          invalid={!!errors.email} 
        /> 
       <FormFeedback invalid="true">{errors.email?.message}</FormFeedback>
      </FormGroup>
      <Row>
        <Col sm={6}>
          <FormGroup className="mb-3">
          {hasLabel && <label>Parool*</label>}
          <input
              placeholder='Sisesta parool'  
              className={`form-control ${(errors.password?'is-invalid':'')}`}
              {...register('password', { 
                required: 'Sisesta parool',
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                  message: 'Parool peab olema 6-16 tähemärki ja vähemalt ühe numbri ja erimärgiga'
                }
              })}
              type="password"
            ></input>  
            <FormFeedback invalid="true">{errors.password?.message}</FormFeedback>
        </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup className="mb-3">
            {hasLabel && <label>Kinnita parool*</label>}
            <input
              placeholder='Kinnita parool'  
              className={`form-control ${(errors.confirmPassword?'is-invalid':'')}`}
              {...register('confirmPassword',{
                validate: (value) => value === watch('password') || "Passwords don't match."
              })}
              type="password"
            ></input> 
              <FormFeedback invalid="true">{errors.confirmPassword?.message}</FormFeedback>
          </FormGroup> 
        </Col>
      </Row> 
      <div className="mb-3">
              <div className="form-check form-check">
                <ValidatedInput   type="checkbox"  
                 invalid={!!errors.agreedToTerms} 
                    {
                        ...register('agreedToTerms', {
                          required: 'Palun nõustu tingimuste ja privaatsuspoliitikaga.'
                        })
                      }>
                 </ValidatedInput>  
                  <label htmlFor="acceptCheckbox" className="form-label form-check-label">
                      Olen lugenud ning nõustun <a href="/authentication/split/register#!">tingimuste</a> ja <a
                          href="/authentication/split/register#!">privaatsuspoliitikaga</a>
                          </label> 
                          <FormFeedback invalid="true">{errors.agreedToTerms?.message}</FormFeedback>
            </div>               
       </div>
      <FormGroup>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100" 
        >
          Registreeri
        </Button>
      </FormGroup>
      <Row >
        <Col className="d-flex justify-content-end"><Link className="fs--1 mb-0" to="/autentimine/parool-ununes">Unustasid parooli?</Link></Col>
      </Row> 
      <div className="w-100 position-relative text-center mt-4">
                <hr className="text-300"/>
                <div className="divider-content-center"> või registreeri kasutades: </div>
            </div>
            <div className="mb-0">
                <Row>
                    <div className="pe-sm-1 col-sm-6"><button type="button"
                            className="btn-outline-google-plus mt-2 w-100 btn btn-sm"><Google size={20}></Google> google</button></div>
                    <div className="ps-sm-1 col-sm-6"><button type="button"
                            className="btn-outline-facebook mt-2 w-100 btn btn-sm"><Facebook size={20}></Facebook>  facebook</button></div>
                </Row>
            </div>
    </Form>
    </div>
  );
};

RegisterForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegisterForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

 
const RegisterPage =()=>{

  return (<div className="container-fluid">
  <div className="min-vh-100 bg-100 row">
      <div className="d-none d-lg-block position-relative col-6">
          <div className="bg-holder"
              style={{
                backgroundImage:`url(${LoginImg})`,
                backgroundPosition: "50% 20%"
              }}>
          </div>
      </div>
      <div className="px-sm-0 align-self-center mx-auto py-5 col-sm-10 col-md-6">
          <div className="g-0 justify-content-center row">
              <div className="col-xxl-6 col-lg-9 col-xl-8">
                  <div className="card">
                      <div className="bg-shape bg-circle-shape text-center p-2 card-header"><Link
                              className="text-white light font-sans-serif fs-5 z-index-1 position-relative"
                              style={{
                                fontFamily: '"din-1451", Arial, Tahoma',
                                fontWeight: "normal !important"
                              }} to="/">Carmasters OÜ</Link></div> 
                         <RegisterForm  />  
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>)
}

export default RegisterPage; 

import React, { useState } from "react";
import { SideBarMenu, NavMenuItem } from "./Sidebar";
import { Button, ButtonGroup, NavbarToggler } from "reactstrap";
import { Link, useNavigate} from 'react-router-dom';
import { CardChecklist,  HouseDoor,Nut, Sliders, Plus,People,Signpost,Truck } from "react-bootstrap-icons";
import UnhandledErrorHandler from "../../UnhandledErrorHandler"; 
import QueryRemoteSelect from "./QueryRemoteSelect";
import { Timeout } from "./Timeout";
import {sessionContext} from '../../services/sessionContext';
import packageInfo  from '../../../package.json';


export default function AuthLayout({ body,head,resource,resourceDisplayName }) {
  const iconClassName = "nav-icon";
  const navigate = useNavigate();
  
  const navMenuClicked =()=>{
    if(window.matchMedia('(max-width: 768px)'))
    { 
       setCollapsed(true);
    }
  }
  const menuIitems = [
    NavMenuItem({
      icon: <HouseDoor className={iconClassName} />,
      name: "Töölaud",
      to: "/toolaud",
      onClick:navMenuClicked
    }),
   
    NavMenuItem({
      icon: <CardChecklist className={iconClassName} />,
      name: "Tööd",
      to: "/tood",
      onClick:navMenuClicked
    }),
    NavMenuItem({
      icon: <People className={iconClassName} />,
      name: "Kliendid",
      to: "/kliendid",
      onClick:navMenuClicked
    }),
    NavMenuItem({
      icon: <Truck className={iconClassName} />,
      name: "Sõidukid",
      to: "/soidukid",
      onClick:navMenuClicked
    }),
    NavMenuItem({
      icon: <Nut className={iconClassName} />,
      name: "Varuosad",
      to: "/varuosad",
      onClick:navMenuClicked
    })
  ];

  const admin =  [
      NavMenuItem({
    icon: <Signpost className={iconClassName} />,
    name: "Asukohad",
    to: "/asukohad",
    onClick:navMenuClicked
  }),
  NavMenuItem({
    icon: <People className={iconClassName} />,
    name: "Töötajad",
    to: "/tootajad",
    onClick:navMenuClicked
  }),
   
  NavMenuItem({
    icon: <Sliders className={iconClassName} />,
    name: "Seaded",
    to: "/seaded",
    onClick:navMenuClicked
  })];
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <UnhandledErrorHandler> 
      <sessionContext.Consumer>
      {({user, options}) => {
        return ( 
          <div className="App">
          <Timeout user={user} sessionOptions={options}></Timeout>
          <header  className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-3 link-light" href="/toolaud">
              CarMasters OÜ
            </a>
            <NavbarToggler onClick={toggleNavbar} className="position-absolute d-md-none" >
              <span className="navbar-toggler-icon"></span>
            </NavbarToggler>
             <QueryRemoteSelect></QueryRemoteSelect> 
             <div className="navbar-nav">
              <div className="nav-item text-nowrap">
                <a className="nav-link px-3" onClick={(e)=>{
                  e.preventDefault();
                  user.removeJwtToken();
                  navigate('/autentimine/logisisse'); 
                }} href="/"> Logi välja</a>
              </div>
            </div> 
          </header>
          <div className="container-fluid">
            <div className="row">
              <SideBarMenu collapsed={collapsed}>
             
                {menuIitems}
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                  <span>Administratiivne</span>
                </h6>
                <ul className="nav flex-column mb-2">{admin}</ul>{" "}
                <h6 style={{height:"100%"}}  className="d-flex align-items-end px-3 mb-4 mb-1 text-muted">
                  <span>Version: {packageInfo.version}</span>
                </h6>
                
              </SideBarMenu>
    
              <main role="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                {head&&<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <div className="ms-2">{head}</div> 
                  {resource&&<div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                      <Link to={'/'+resource+'-uus'}>
                        <Button size="sm" color="falcon-default" className="pr-4">
                          <Plus></Plus>Uus {resourceDisplayName}
                        </Button>
                      </Link> 
                    </ButtonGroup>
                  </div>}
                </div> 
                } 
                  {body}   
              </main>
            </div>
          </div>
        </div>
        );
      }}
    </sessionContext.Consumer>  
    </UnhandledErrorHandler>
  );
}
 
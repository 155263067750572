import React from 'react';
import AsyncSelect from 'react-select/async';
import { page } from '../../services/restClient'; 
import { useErrorStatus } from '../../UnhandledErrorHandler';

const ClientRemoteSelect =({onKeyDown,value,placeholder,onChange})=>{

   
    const { setError } = useErrorStatus();
    return (
        <AsyncSelect
        styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
        onKeyDown={onKeyDown}
        placeholder = {placeholder}
        value={value}
        isClearable={true}
        onChange={(opts) => {
           
            onChange(opts);
        }}
        loadingMessage={() => {
          return "Laen kliendi andmeid";
        }}
        loadOptions={(inputValue)=>{
          return clientRemoteData(inputValue,setError)
        }}
      />
    )
}

const clientRemoteData = (inputValue,setError) =>
  new Promise((resolve) => {
    
    if (!inputValue) {
      resolve([]);
      return;
    } 

    page({
      resourceName:'clients',
      searchText: inputValue + '*',
      whenReady:(items)=>{
        resolve(items.map((client)=>{
          return {
            label:client.name,
            value:client.id
          };
        }));
      },
      setError
    })  
  });
 export default ClientRemoteSelect;
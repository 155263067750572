import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { PencilFill, ThreeDots } from 'react-bootstrap-icons';
import {
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,Button,Card,CardBody,CardFooter 
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../services/restClient';
import Moment from 'moment';
import { Link } from 'react-router-dom'; 
import Loader from '../_shared/Loader';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';

const VehicleRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);

 
const getVehicleName =(vehicle)=>{
    return vehicle.producer +' '+vehicle.model;
}

const ReadOnlyCardHeader = ({vehicle,isEditing}) =>{
 
    if(!vehicle) throw new Error('vehicle required');
    let navigate = useNavigate()
    const [collapseOne, setCollapseOne] = useState(false);
       
    return (
      <Fragment>
            <ConfirmDeleteModal 
            isOpen={collapseOne}
            url={ 'vehicles'} 
            onRequestingClose={()=>{ setCollapseOne(false)}} 
            onConfirm={ ()=>{ navigate('/soidukid')}}
            deleteBody={()=>{
              return[vehicle.id]
            }}></ConfirmDeleteModal>  
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
             {getVehicleName(vehicle)} ({
                 vehicle.regNr?(<span>{vehicle.regNr}</span>):( <span className="font-italic text-400 mb-1">Reg. nr puudub</span>) })
         </h5>
          </Col>
          <Col xs="auto" >
          <h6 className="text-uppercase text-600">
                 
                </h6>
          </Col>
        </Row>
        <Row>
          <Col>
           <UncontrolledDropdown className="d-inline-block ms-2">
              <DropdownToggle color="falcon-default" size="sm">
                <ThreeDots />
              </DropdownToggle>
              <DropdownMenu> 
                <DropdownItem  className="text-danger" onClick={ ()=>  setCollapseOne(true) } >Kustuta sõiduk</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                      navigate('/soiduk-muuda/'+ vehicle.id);
                    }
                    else{
                      navigate('/soiduk/'+ vehicle.id);
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Uuenda detaile') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row>
        </CardHeader>
      </Fragment>
      
      );
}

const ReadOnlyFooter= ({ vehicle })=>{
    if(!vehicle) throw new Error('vehicle required');
    return (
         <Row>
        <Col>
        {<Media> 
           <Media body>
              <p className="mb-0">Sõiduk loodi</p>
              <p className="fs--1 mb-0 text-600">{vehicle.introducedAt}</p>
            </Media>
          </Media>}
        </Col>
        <Col className="text-end">
        </Col>
      </Row>);
}

const ReadOnlyCardBody = ({ vehicle }) => {
    if(!vehicle) throw new Error('vehicle required');
  return (
    <Row>
      <Col lg className="col-xxl-5">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Sõiduki Informatsioon</h6>
        <VehicleRow title="Id">{vehicle.id}</VehicleRow>
        <VehicleRow title="Reg. nr">{vehicle.regNr}</VehicleRow>
        <VehicleRow title="Mark">{vehicle.producer  }</VehicleRow>
        <VehicleRow title="Mudel">{vehicle.model  }</VehicleRow>
        <VehicleRow title="VIN">{vehicle.vin  }</VehicleRow>
        <VehicleRow title="Läbisõit">{vehicle.odo  }</VehicleRow>
        <VehicleRow title="Omanik" isLastItem>
            {vehicle.ownerName ?  
              (  <Link to={'/klient/'+vehicle.ownerId} >
              <strong>{vehicle.ownerName}</strong>
            </Link>) 
             : <p className="font-italic text-400 mb-0">Omanik puudub</p>}
          </VehicleRow>
      </Col>
      <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Lisa Informatsioon</h6>
        <VehicleRow title="Tootmiskuu">{ Moment(vehicle.productionDate).format('MM-yyyy')}</VehicleRow>
        <VehicleRow title="Käigukast">{vehicle.transmission  }</VehicleRow>
        <VehicleRow title="Seeria">{vehicle.series  }</VehicleRow>
        <VehicleRow title="Regioon">{vehicle.region  }</VehicleRow>
        <VehicleRow title="Juhipool">{vehicle.drivingSide  }</VehicleRow>
        
        <VehicleRow title="Kirjeldus">
          {vehicle.description ? (
            vehicle.description.split('\n').map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Kirjeldus puudub</p>
            )}
        </VehicleRow>
      </Col>
    </Row>
  );

}



//display
const VehicleCard = (props) => {
    const { id }  = useParams();

    if(!id) throw new Error('id required');
 
    const [vehicle] = useGet({ url:`vehicles/${id}`}); 
   
    return ( 
        <Fragment> 
        {!vehicle ? (
          <Loader />
          ) : ( <Card className="mb-3">
          <ReadOnlyCardHeader { ...{ isEditing:false, vehicle}}/>
          <CardBody className="bg-light border-top">
            <ReadOnlyCardBody { ...{ vehicle}}/>
          </CardBody>
          <CardFooter className="border-top">
            {<ReadOnlyFooter  vehicle={vehicle}/>}
          </CardFooter>
        </Card>) }
      </Fragment> 
    ); 
}

export default VehicleCard;
export { 
    ReadOnlyFooter,
    ReadOnlyCardHeader
}
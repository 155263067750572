import React, {  useState,Fragment } from 'react'; 
import Select from "react-select";
import { useParams } from 'react-router'; 
import {
    Col,
    Row,  Form 
} from 'reactstrap';
import {  Check } from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import {   useNavigate } from 'react-router-dom';
import { ReadOnlyCardHeader, ReadOnlyFooter } from './Varuosa'
import { Button,Card,CardBody,CardFooter,FormGroup,Label } from 'reactstrap';
import Loader from '../_shared/Loader';
import { useDelete, useGet,usePut,page } from '../../services/restClient';
import { useAlert } from '@blaumaus/react-alert';
import { useErrorStatus } from '../../UnhandledErrorHandler';

 
const EditableCardBody = ({
    sparePart = {
        id: 0,
        code: '',
        name: '',
        price: 0,
        quantity: 0,
        discount: 0,
        storageId: 0,
        description: ''
    }, 
    changeSparePart,
}) => {
 
    const [code, setCode] = useState(sparePart.code);
    const [name, setName] = useState(sparePart.name);
    const [price, setPrice] = useState(sparePart.price);
    const [quantity, setQuantity] = useState(sparePart.quantity);
    const [discount, setDiscount] = useState(sparePart.discount);
    const [storageId, setStorageId] = useState(sparePart.storageId);
    const [storageName,setStorageName] = useState(sparePart.storageName);
    const [description, setDescription] = useState(!sparePart.description ? '' : sparePart.description);
    let navigate = useNavigate();

    //storages
    const alert = useAlert();
    const changeResourceHandler = e => {
        e.preventDefault();
        
        var body ={ code, name, price:+price, quantity:+quantity, discount:+discount, storageId:+storageId, description, introducedAt: new Date() };
        var posting = sparePart.id === 0;
        changeSparePart(body,(response) => {
            if (posting) {
                navigate('/varuosa/' + Number(response).toString());
                alert.show('Uus varuosa lisatud.');
            }
            else {
                navigate('/varuosa/' + sparePart.id);
                alert.show('Varuosa andmed muudetud.')
            }
           
        }) ;
    };
 
    return (
        <Form onSubmit={changeResourceHandler}>
            <Row>
                <Col lg={6}>
                    <FormGroupInput
                        id="code"
                        label="Kood"
                        value={code} required
                        onChange={({ target }) => setCode(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="name"
                        label="Nimi"
                        value={name} required
                        onChange={({ target }) => setName(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="price"
                        label="Hind"
                        value={price}
                        onChange={({ target }) => setPrice(target.value)}
                        type="number"
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="quantity"
                        label="Kogus"
                        value={quantity}
                        onChange={({ target }) => setQuantity(target.value)}
                        type="number"
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="discount"
                        label="Allahindlus"
                        value={discount}
                        onChange={({ target }) => setDiscount(target.value)}
                        type="number"
                    />
                </Col> 
                <Col lg={6}> 
                      <FormGroup>
                      <Label htmlFor="asukoht">Asukoht</Label>
                        <StoragesSelect
                         storageId={storageId}
                         storageName={storageName}
                         handleChange={ ( target ) => { 
                            setStorageId(target.value);
                            setStorageName(target.label);
                         }  }
                        >
                        </StoragesSelect>
                      </FormGroup>
                    
                </Col> 
                <Col xs={12}>
                    <FormGroupInput
                        id="description"
                        label="Kirjeldus"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        type="textarea"
                        rows="5"
                    />
                </Col>
            </Row> 
            <Row>
                <Col className="text-end">
                    <Button
                        color=" btn-primary"
                        size="sm"
                        type="submit"
                    > <Check size={20} className="me-1"></Check>
                      Salvesta muudatused
                  </Button>
                </Col>
            </Row>
        </Form>
    );
}

const StoragesSelect =({storageId,storageName,handleChange})=>{
 
    const [storages,setStorages] = useState();
    const {setError} =  useErrorStatus();
    if(!storages)
    {
        page({
            resourceName:'storages',
            searchText: '',
            whenReady:(items)=>{
                setStorages(items.map((storage)=>{
                    return {
                       label:storage.name,
                       value:storage.id
                    };
              }));
            },
            setError
          }); 
    }

    return ( <Select
        id="clentVeh"
        className="basic-single"
        classNamePrefix="select"
        noOptionsMessage={()=>{ return "valikud puuduvad.";}}
      
        isClearable={true}
        isSearchable={false} 
        value={{label:storageName,value:storageId}}
        name="clientVeh"  
        options={storages}
        onChange={handleChange}
        />)
}

//edit
const SparePartEditCard = () => {
    const { id } = useParams();
    if (!id) throw new Error('id required');

    const [sparePart] = useGet({ url:`spareparts/${id}`});  
    const [changeSparePart] = usePut({ url:`spareparts/${id}`});
    const [deleteSparePart] = useDelete({ url:`spareparts/${id}`});

    return (
        <Fragment> 
        {!sparePart ? (
        <Loader />
        ) : ( <Card className="mb-3">
            <ReadOnlyCardHeader { ...{isEditing:true, sparePart,deleteSparePart}} />
            <CardBody className="bg-light border-top">
            <EditableCardBody { ...{ sparePart,changeSparePart}} />
            </CardBody>
            <CardFooter className="border-top">
            <ReadOnlyFooter sparePart={sparePart} />
            </CardFooter>
            </Card>)
            }
    </Fragment> 
    );
}



export default SparePartEditCard;
export {
    EditableCardBody
}

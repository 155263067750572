import React from "react";
import { Routes, Route } from "react-router-dom";
import AppRoute from "./Route";

import FrontPage from "../pages/Esileht";

import SignIn from "../pages/Autentimine/LogiSisse"; 
import Register from "../pages/Autentimine/Registreeri"; 
import ConfirmAccount from "../pages/Autentimine/Kinnita"; 

import Clients from "../pages/Klient/Kliendid";
import ClientCard from '../pages/Klient/Klient';
import ClientEditCard from '../pages/Klient/KlientMuuda';
import ClientNewCard from '../pages/Klient/KlientUus';

import Vehicles from "../pages/Soiduk/Soidukid";
import VehicleCard from '../pages/Soiduk/Soiduk';
import VehicleEditCard from '../pages/Soiduk/SoidukMuuda';
import VehicleNewCard from '../pages/Soiduk/SoidukUus';

import Varuosad from "../pages/Varuosa/Varuosad";
import SparePartCard from '../pages/Varuosa/Varuosa';
import SparePartEditCard from '../pages/Varuosa/VaruosaMuuda';
import SparePartNewCard from '../pages/Varuosa/VaruosaUus';

import Tootajad from "../pages/Tootaja/Tootajad";
import EmployeeCard from '../pages/Tootaja/Tootaja';
import EmployeeEditCard from '../pages/Tootaja/TootajaMuuda';
import EmployeeNewCard from '../pages/Tootaja/TootajaUus';

import Asukohad from "../pages/Asukoht/Asukohad";
import StorageCard from '../pages/Asukoht/Asukoht';
import StorageEditCard from '../pages/Asukoht/AsukohtMuuda';
import StorageNewCard from '../pages/Asukoht/AsukohtUus';


import Tood from "../pages/Tood/Tood";
import WorkCard from '../pages/Tood/Too';
import WorkNewCard from '../pages/Tood/TooUus';
import WorkEditCard from '../pages/Tood/TooMuuda';
import PageNotFound404 from "../PageNotFound404";

import OptionsCard from '../pages/Seaded/Seaded';
import OptionsEditCard from '../pages/Seaded/SeadedMuuda';
import { sessionContext } from '../services/sessionContext';
import { CardChecklist, Nut, People, Signpost, Sliders, Truck } from "react-bootstrap-icons";


export default function AppRoutes() {
  
 
  return (
    <sessionContext.Consumer>
      {({ user }) => {
        return ( 
            <Routes>
              <Route path="/" element={<FrontPage />} />
              <Route path="/autentimine/logisisse"  element={<SignIn />} /> 
              <Route path="/autentimine/logisisse/:validationtoken"  element={<SignIn />} /> 
              <Route path="/autentimine/registreeri" exact element={<Register />} /> 
              <Route path="/autentimine/kinnita/:email" exact element={<ConfirmAccount />} /> 
              <Route path="/toolaud" element={<AppRoute resource={"toolaud"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate> <WorkNewCard /></AppRoute>} />
              <Route path="/tood/:offset?" element={<AppRoute resource={"too"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate><Tood /></AppRoute>} />
              <Route path="/kliendid/:offset?" element={<AppRoute resource={"klient"} user={user} resourceDisplayName={"Klient"} icon={<People size={24} />} isPrivate>  <Clients /> </AppRoute>} />
              <Route path="/soidukid/:offset?" element={<AppRoute resource={"soiduk"} user={user} resourceDisplayName={"Sõiduk"} icon={<Truck size={24} />} isPrivate> <Vehicles /></AppRoute>} />
              <Route path="/varuosad/:offset?" element={<AppRoute resource={"varuosa"} user={user} resourceDisplayName={"Varuosa"} icon={<Nut size={24} />} isPrivate> <Varuosad /></AppRoute>} />
              <Route path="/tootajad/:offset?" element={<AppRoute resource={"tootaja"} user={user} resourceDisplayName={"Töötaja"} icon={<People size={24} />} isPrivate> <Tootajad /></AppRoute>} />
              <Route path="/asukohad/:offset?" element={<AppRoute resource={"asukoht"} user={user} resourceDisplayName={"Asukoht"} icon={<Signpost size={24} />} isPrivate> <Asukohad /></AppRoute>} />

              <Route path="/seaded" element={<AppRoute user={user} resourceDisplayName={"Seaded"} icon={<Sliders size={24} />} isPrivate> <OptionsCard /></AppRoute>} />
              <Route path="/seaded-muuda" element={<AppRoute user={user} resourceDisplayName={"Seaded"} icon={<Sliders size={24} />} isPrivate> <OptionsEditCard /></AppRoute>} />

              <Route path="/soiduk/:id" element={<AppRoute resource={"soiduk"} user={user} resourceDisplayName={"Sõiduk"} icon={<Truck size={24} />} isPrivate> <VehicleCard /></AppRoute>} />
              <Route path="/soiduk-uus" element={<AppRoute resource={"soiduk"} user={user} resourceDisplayName={"Sõiduk"} icon={<Truck size={24} />} isPrivate> <VehicleNewCard /></AppRoute>} />
              <Route path="/soiduk-muuda/:id" element={<AppRoute resource={"soiduk"} user={user} resourceDisplayName={"Sõiduk"} icon={<Truck size={24} />} isPrivate>  <VehicleEditCard /></AppRoute>} />

              <Route path="/varuosa/:id" element={<AppRoute resource={"varuosa"} user={user} resourceDisplayName={"Varuosa"} icon={<Nut size={24} />} isPrivate>  <SparePartCard /></AppRoute>} />
              <Route path="/varuosa-uus" element={<AppRoute resource={"varuosa"} user={user} resourceDisplayName={"Varuosa"} icon={<Nut size={24} />} isPrivate>  <SparePartNewCard /></AppRoute>} />
              <Route path="/varuosa-muuda/:id" element={<AppRoute resource={"varuosa"} user={user} resourceDisplayName={"Varuosa"} icon={<Nut size={24} />} isPrivate>  <SparePartEditCard /></AppRoute>} />

              <Route path="/tootaja/:id" element={<AppRoute resource={"tootaja"} user={user} resourceDisplayName={"Töötaja"} icon={<People size={24} />} isPrivate>  <EmployeeCard /></AppRoute>} />
              <Route path="/tootaja-muuda/:id" element={<AppRoute resource={"tootaja"} user={user} resourceDisplayName={"Töötaja"} icon={<People size={24} />} isPrivate> <EmployeeEditCard /></AppRoute>} />
              <Route path="/tootaja-uus" element={<AppRoute resource={"tootaja"} user={user} resourceDisplayName={"Töötaja"} icon={<People size={24} />} isPrivate> <EmployeeNewCard /></AppRoute>} />

              <Route path="/asukoht/:id" element={<AppRoute resource={"asukoht"} user={user} resourceDisplayName={"Asukoht"} icon={<Signpost size={24} />} isPrivate> <StorageCard /></AppRoute>} />
              <Route path="/asukoht-uus" element={<AppRoute resource={"asukoht"} user={user} resourceDisplayName={"Asukoht"} icon={<Signpost size={24} />} isPrivate> <StorageNewCard /></AppRoute>} />
              <Route path="/asukoht-muuda/:id" element={<AppRoute resource={"asukoht"} user={user} resourceDisplayName={"Asukoht"} icon={<Signpost size={24} />} isPrivate>  <StorageEditCard /></AppRoute>} />

              <Route path="/klient/:id" element={<AppRoute resource={"klient"} user={user} resourceDisplayName={"Klient"} icon={<People size={24} />} isPrivate>  <ClientCard /></AppRoute>} />
              <Route path="/klient-uus" element={<AppRoute resource={"klient"} user={user} resourceDisplayName={"Klient"} icon={<People size={24} />} isPrivate>  <ClientNewCard /></AppRoute>} />
              <Route path="/klient-muuda/:id" element={<AppRoute resource={"klient"} user={user} resourceDisplayName={"Klient"} icon={<People size={24} />} isPrivate>  <ClientEditCard /></AppRoute>} />

              <Route path="/too/:id" element={<AppRoute resource={"too"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate> <WorkCard /></AppRoute>} />
              <Route path="/too/:id/pakkumine/:offerid" element={<AppRoute resource={"too"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate> <WorkCard /></AppRoute>} />
              <Route path="/too-uus" element={<AppRoute resource={"too"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate>  <WorkNewCard /></AppRoute>} />
              <Route path="/too-muuda/:id" element={<AppRoute resource={"too"} user={user} resourceDisplayName={"Töö"} icon={<CardChecklist size={24} />} isPrivate>  <WorkEditCard /></AppRoute>} />

              {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
              <Route path="*" element={<PageNotFound404 />} />
            </Routes > 
          )
      }}
    </sessionContext.Consumer>
  );
}

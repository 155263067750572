import React, {   useState } from 'react'; 
import {
  CardHeader,
  Col,
  Row, 
  Input ,Card,CardBody,CardFooter 
} from 'reactstrap'; 
import { EditableCardBody} from './KlientMuuda' 
import {   usePost } from '../../services/restClient';

const NewCardHeader = ({clientType,clientTypeChanged}) =>{
    return (
        <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">Uus {(clientType === 'legalclients')?'Äri':'Era'}klient</h5>
          </Col>
          <Col xs="auto" >
          <Input
                type="select"
                bsSize="sm"
                id="clientType"
                value={clientType}
                onChange={({ target }) =>{
                  clientTypeChanged(target.value);
                } }
              >
                <option value="privateclients">Eraklient</option>
                <option value="legalclients">Äriklient</option>
              </Input>
          </Col>
        </Row>
        </CardHeader>
      );
}
 
//create
const ClientNewCard = () => {

    const [clientType,setClientType] = useState('privateclients');  
    const [changeClient] = usePost({ resource:clientType});

    
    return (
      <Card className="mb-3">
        <NewCardHeader { ...{ clientType, clientTypeChanged:setClientType}} />
        <CardBody className="bg-light border-top">
        <EditableCardBody { ...{ changeClient,clientType}} />
        </CardBody>
        <CardFooter className="border-top">
      
        </CardFooter>
      </Card> 
    ); 
}



export default ClientNewCard;
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compare } from 'compare-versions';

const log = (isVerboseMode,message, isError) => {
    isVerboseMode && (isError ? console.error(message) : console.log(message));
  };
  const checkCacheStatus = async (metaFileDirectory,currentVersion,isVerboseMode,setCacheStatus) => {
    const getMetaFileDirectory = () => {
        return !metaFileDirectory || metaFileDirectory === '.'
          ? ''
          : metaFileDirectory;
      };
     
      const isThereNewVersion = (metaVersion, currentVersion) => {
        return compare(metaVersion, currentVersion, '>');
      };

    try {
      
      const res = await fetch(`${getMetaFileDirectory()}/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });
      const { version: metaVersion } = await res.json();

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);
      if (shouldForceRefresh) {
        log(isVerboseMode,`There is a new version (v${metaVersion}). Should force refresh.`);
        setCacheStatus({
          loading: false,
          isLatestVersion: false
        });
      } else {
        log(isVerboseMode,'There is no new version. No cache refresh needed.');
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
      }
    } catch (error) {
      log(isVerboseMode,'An error occurred while checking cache status.', true);
      log(isVerboseMode,error, true);

      //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true
        });
    }
  };

export default function CacheBuster({
  children = null,
  resource,
  currentVersion,
  isEnabled = false,
  isVerboseMode = false,
  loadingComponent = null,
  metaFileDirectory = null,
  onCacheClear
}) {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false
  });


 
  useEffect(() => {
    isEnabled ? checkCacheStatus(metaFileDirectory,currentVersion,isVerboseMode,setCacheStatus) : log(isVerboseMode,'React Cache Buster is disabled.');
  },[resource,isEnabled,currentVersion,isVerboseMode,metaFileDirectory]); 

 
  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const { caches } = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

        await Promise.all(cacheDeletionPromises);

        log(isVerboseMode,'The cache has been deleted.');
        window.location.reload(true);
      }
    } catch (error) {
      log(isVerboseMode,'An error occurred while deleting the cache.', true);
      log(isVerboseMode,error, true);
    }
  };
  
  if (!isEnabled) {
    return children;
  } else {
    if (cacheStatus.loading) {
      return loadingComponent;
    }

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      onCacheClear
        ? onCacheClear(refreshCacheAndReload)
        : refreshCacheAndReload();
      return null;
    }
    return children;
  }
}

CacheBuster.propTypes = {
  children: PropTypes.any.isRequired,
  currentVersion: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isVerboseMode: PropTypes.bool,
  loadingComponent: PropTypes.element,
  metaFileDirectory: PropTypes.string,
  onCacheClear: PropTypes.func
};
 
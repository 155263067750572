import React, {useEffect , useState} from "react";
import {TimeoutWarningModal} from "./TimeoutWarningModal"  
import { useNavigate } from 'react-router-dom'; 
import { query } from "../../services/restClient";
import { setJwtToken } from "../../services/session";

const eventTypes = [
  'keypress',
  'mousemove',
  'mousedown',
  'scroll',
  'touchmove',
  'pointermove'
]
export const addEventListeners = (listener) => {
  eventTypes.forEach((type) => {
    window.addEventListener(type, listener, false)
  })
}
export const removeEventListeners = (listener) => {
  if (listener) {
    eventTypes.forEach((type) => {
      window.removeEventListener(type, listener, false)
    })
  }
}

const useLogOff = (user) => {
    const navigate = useNavigate();
    const logOff = ()=>{
       
        user.removeJwtToken();
        navigate('/');
    }
    return logOff;
  };

  
export const Timeout = ({user, sessionOptions}) => { 
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);  
  const logOff = useLogOff(user);  

  const extendSession =()=>{
     setWarningModalOpen(false);
     query({
        url:'users/extendsession' ,
        method: 'POST',
        body : {}, 
        onSuccess:(jwt)=>{  
            setJwtToken(jwt);
         }, 
        onFailure: (error)=>{
          console.error(error); 
        }
      }); 
  }
 
  useEffect(() => {
   
    const createTimeout1 = () => setTimeout(()=>{  
      setWarningModalOpen(true);
    },(sessionOptions.getTimeout() * 1000)-(sessionOptions.dialogTimeout * 1000))

    const createTimeout2 = () => setTimeout(() => {
        logOff(user);
    },(sessionOptions.dialogTimeout * 1000))

    const listener = () => {
      if(!isWarningModalOpen){
        clearTimeout(timeout)
        timeout = createTimeout1();
      }
    } 

    // Initialization
    let timeout = isWarningModalOpen  ? createTimeout2() : createTimeout1()
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[isWarningModalOpen,logOff,user,sessionOptions])
  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarningModal 
         timoutInSeconds={sessionOptions.dialogTimeout}
          isOpen={isWarningModalOpen}
          onLogOffCall={logOff}
          onRequestClose={extendSession}
        />
        )
      }
    </div>
  ) 
}

export{
    useLogOff
}
import React from 'react';
import AsyncSelect from 'react-select/async';
import { page } from '../../services/restClient'; 
import { useErrorStatus } from '../../UnhandledErrorHandler';

const VehicleRemoteSelect =({onKeyDown,value,placeholder,onChange})=>{
 
   
  const { setError } = useErrorStatus();
    return (
        <AsyncSelect
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        placeholder = {placeholder}
        value={value}
        onKeyDown={onKeyDown}
        isClearable={true}
        onChange={(opts) => {
            onChange(opts);
        }}
        loadingMessage={() => {
          return "Laen sõiduki andmeid";
        }}
        loadOptions={(inputValue)=>{
          return vehicletRemoteData(inputValue,setError)
        }}
      />
    )
}


const formatVehicle=(vehicle)=>{
  return vehicle.producer+' '+ vehicle.model+', '+ vehicle.regNr
}

const vehicletRemoteData = (inputValue,setError) =>
  new Promise((resolve) => {

    if (!inputValue) {
      resolve([]);
      return;
    }
 
    page({
      resourceName:'vehicles',
      searchText: inputValue + '*',
      whenReady:(items)=>{
        resolve(items.map((vehicle)=>{
          return {
            label:formatVehicle(vehicle),
            value:vehicle.id
          };
        }));
      },
      setError
    }) 
  });
 export default VehicleRemoteSelect;
 export{
  formatVehicle
 }
import React  from 'react'; 
import { ArrowDownShort, Check, Cup, List } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Button, Col, NavbarToggler, Row } from 'reactstrap';
import './Esileht.css'; 
import HeroImage from '../../assets/img/m2.webp';
import CoffeImage from '../../assets/img/1-coffe-flavour.jpg';
import Colorful1 from '../../assets/img/colorful1.jpg';
import Colorful2 from '../../assets/img/colorful2.jpg';
import Colorful3 from '../../assets/img/colorful3.jpg';
import Bg1 from '../../assets/img/bg1.jpg';
import Logo from '../../assets/img/FullLogo_Transparent.png';

const FrontPage =()=>{
    return ( <>
    
    <header className='header p-3 position-absolute start-0 top-0 end-0' >
      <div className='d-flex justify-content-between align-items-center'> 
      
        <div>
        <NavbarToggler  className='text-white me-2' >
        <List  size={30}></List>
        </NavbarToggler>
      </div>
      </div> 
    </header>
    <section className='hero'>
        <div className='hero__overlay'></div>
         <div className="bg-holder"
              style={{
                backgroundImage:`url(${Bg1})`
              }}>
          </div>
          <div className='hero__content h-100 container-xxl container-custom position-relative'>
            <div className='d-flex h-100 align-items-center justify-content-center'>
              <div>
              <img src={Logo} alt="Car repair app"/> 
              <div className='d-flex text-white align-items-center  justify-content-center'> 
                 <p className='lead'>A way to manage car maintenance and repair process. Keep track of customers, cars and spareparts. Create and issue invoices and offers.</p>
          </div>
          <div className='d-flex text-white align-items-center  justify-content-center'>  
              <Link className='btn btn-lg btn-outline-light me-2'>Try now</Link> 
              <Link className='btn btn-lg btn-outline-light ms-2'>Sign up</Link> 
          </div>
              </div>
           
        
            </div> 
          </div>
          <a href='#scroll-down' className='hero__scroll-btn'>
            More <ArrowDownShort ></ArrowDownShort>
          </a>
    </section>
    <Link id='scroll-down'></Link>
    <section class="steps container-custom">

<div class="row">
  <div class="col-12 col-sm-6 d-md-flex justify-content-md-center">
    <img src="img/1-coffe-flavour.jpg" alt="Coffe Flavour" class="img-fluid pb-4 steps__section-thumbnail" width="553" height="746" loading="lazy"></img>
  </div>

  <div class="col-12 col-sm-6 align-self-center justify-content-md-center">

    <div class="steps__content-width">
      <span>01</span>
      <h1 class="h2 mb-4">Amazing Coffee Flavour</h1>
      <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia totam dolorem quasi! Quis fugiat totam id fuga non distinctio incidunt amet nesciunt itaque, tempore repellat eos natus quo mollitia laborum.</p>
      <a href="#">Read More <i class="bi bi-arrow-right"></i></a>

    </div>

  </div>
</div>

</section>
 
<section class="steps steps--background">

<div class="container-custom">

  <div class="row">
    <div class="col-12 col-sm-6 d-md-flex justify-content-md-center order-sm-1">
      <img src="img/2-health-benefits.jpg" alt="Health Benefits" class="img-fluid pb-4 steps__section-thumbnail" width="553" height="746" loading="lazy"></img>
    </div>

    <div class="col-12 col-sm-6 align-self-center justify-content-md-center">
      <div class="steps__content-width">
        <span>02</span>
        <h1 class="h2 mb-4">Surprising Health Benefits</h1>
        <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia totam dolorem quasi! Quis fugiat totam id fuga non distinctio incidunt amet nesciunt itaque, tempore repellat eos natus quo mollitia laborum.</p>
        <a href="#">Read More <i class="bi bi-arrow-right"></i></a>
      </div>
    </div>
  </div>

</div>
</section>
 
<section class="steps container-custom">

<div class="row">
  <div class="col-12 col-sm-6 d-md-flex justify-content-md-ceneter">
    <img src="img/3-essential-nutrients.jpg" alt="Essential Nutrients" class="img-fluid pb-4 steps__section-thumbnail" width="553" height="746" loading="lazy"></img>
  </div>

  <div class="col-12 col-sm-6 align-self-center justify-content-md-ceneter">

    <div class="steps__content-width">
      <span>03</span>
      <h1 class="h2 mb-4">Essential Nutrients</h1>
      <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia totam dolorem quasi! Quis fugiat totam id fuga non distinctio incidunt amet nesciunt itaque, tempore repellat eos natus quo mollitia laborum.</p>
      <a href="#">Read More <i class="bi bi-arrow-right"></i></a>

    </div>

  </div>
</div>

</section>
 

 <div className='container'>
    <div className="card-body">
        <div className="justify-content-center row">
            <div className="text-center mb-4 col-12">
                <div className="fs-1">Pricing</div>
                <h3 className="fs-2 fs-md-3">Free plan with all the basic features. <br className="d-none d-md-block"></br> Pro plan
                    for advanced users.</h3>
                <div className="d-flex justify-content-center"><label for="custom-switch"
                        className="me-2 form-check-label">Monthly</label>
                    <div className="form-check form-switch"><input type="checkbox" id="yearly" className="form-check-input"
                            checked=""></input><label for="yearly" className="ms-2 align-top form-check-label">Yearly</label></div>
                </div>
            </div>
            <div className="col-xxl-8 col-xl-10 col-lg-9 col-12">
                <div className="row">
                    <div className="col-md">
                        <div className="border rounded-3 overflow-hidden mb-3 mb-md-0">
                            <div className="d-flex justify-content-between align-items-center p-4">
                                <div>
                                    <h3 className="fw-light fs-5 mb-0 text-primary">Free</h3>
                                    <h2 className="fw-light mt-0 text-primary"><sup className="fs-1">$</sup><span
                                            className="fs-3">0</span><span className="fs--2 mt-1">/ m</span></h2>
                                </div>
                                <div className="pe-3"><img src="/static/media/free.b24dc2bc.svg" width="70" alt="Free"></img>
                                </div>
                            </div>
                            <div className="p-4 bg-light">
                                <ul className="list-unstyled">
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add spareparts (10 per month)</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add clients (10 per month)</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add vehicles (10 per month)</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Create offers (10 per month)</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Create invoices (10 per month)</li>
                                    <li className="border-bottom py-2 text-300"><Check size={30} ></Check> Unlimted Tags</li>
                                    <li className="border-bottom py-2 text-300"><Check size={30} ></Check> Custom Fields</li>
                                </ul><button type="button" className="d-block w-100 btn btn-outline-primary">Start free
                                    trial</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="border rounded-3 overflow-hidden mb-3 mb-md-0">
                            <div className="d-flex justify-content-between align-items-center p-4">
                                <div>
                                    <h3 className="fw-light fs-5 mb-0 text-primary">Pro</h3>
                                    <h2 className="fw-light mt-0 text-primary"><sup className="fs-1">$</sup><span
                                            className="fs-3">99</span><span className="fs--2 mt-1">/ m</span></h2>
                                </div>
                                <div className="pe-3"><img src="/static/media/pro.31c71346.svg" width="70" alt="Pro"></img></div>
                            </div>
                            <div className="p-4 bg-light">
                                <ul className="list-unstyled">
                                <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add spareparts</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add clients</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Add vehicles</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Create offers</li>
                                    <li className="border-bottom py-2"><Check size={30} className="text-primary"></Check>Create invoices</li>
                                   <li className="border-bottom py-2"><Check size={30} className="text-primary" ></Check> Unlimted Tags</li>
                                   <li className="border-bottom py-2"><Check size={30} className="text-primary" ></Check> Custom Fields</li>
                                </ul><button type="button" className="d-block w-100 btn btn-primary">Purchase Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center col-12">
                <h5 className="mt-5">Looking for personal or small team task management?</h5>
                <p className="fs-1">Try the <a href="/pricing/pricing-alt#!">basic version</a> of Falcon</p>
            </div>
        </div>
    </div> 
    </div> 
   
   <div className="mb-3 card">
 </div> 
 <section class="bg-dark text-white py-4">
<div class="container-custom my-4">
  <div class="row">

    <div class="col-12 col-sm-4 mb-4">
      <img src="img/1-coffee-flavour-small.jpg" class="mb-4 img-fluid" alt="Coffe Flavour" width="441" height="248" loading="lazy"></img>
      <h3>Amazing Coffee Flavour</h3>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. A veniam ullam illo dolorum dolor. Dolor, velit vitae? Fugiat quidem nostrum nobis sit beatae consequuntur quisquam iusto reprehenderit voluptatibus officia? Natus.</p>
    </div>

    <div class="col-12 col-sm-4 mb-4">
      <img src="img/2-health-benefits-small.jpg" class="mb-4 img-fluid" alt="Health Benefits" width="441" height="248" loading="lazy"></img>
      <h3>Health Benefits</h3>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. A veniam ullam illo dolorum dolor. Dolor, velit vitae? Fugiat quidem nostrum nobis sit beatae consequuntur quisquam iusto reprehenderit voluptatibus officia? Natus.</p>
    </div>

    <div class="col-12 col-sm-4 mb-4">
      <img src="img/3-essential-nutrients-small.jpg" class="mb-4 img-fluid" alt="Essential Nutrients" width="441" height="248" loading="lazy"></img>
      <h3>Essential Nutrients</h3>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. A veniam ullam illo dolorum dolor. Dolor, velit vitae? Fugiat quidem nostrum nobis sit beatae consequuntur quisquam iusto reprehenderit voluptatibus officia? Natus.</p>
    </div>

  </div>
</div>
</section>
 <footer class="footer bg-dark text-white">
<div class="container-custom d-flex justify-content-between align-items-center py-3 border-highlight">
  <div class="col-md-4 d-flex align-items-center">
    <a href="/" class="me-2 text-muted text-decoration-none">
      <i class="bi bi-cup"></i>
      <span>&copy; 2022 GorillaCoffee</span>
    </a> 
  </div>

  <ul class="nav col-md-4 justify-content-end list-unstyled d-flex text-white">
    <li class="ms-3 "><a class="text-muted" href="#"><i class="bi bi-twitter"></i></a></li>
    <li class="ms-3"><a class="text-muted" href="#"><i class="bi bi-instagram"></i></a></li>
    <li class="ms-3"><a class="text-muted" href="#"><i class="bi bi-facebook"></i></a></li>
  </ul>


</div> 
</footer>
    </>);
}

export default FrontPage; 

import React from 'react';
import {Resources} from '../_shared/Ressursid'; 
import { Link } from 'react-router-dom';

const Asukohad = () => {

  const actionColumn = {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle', 
    align: 'right'
  };
  const columns = [
    {
      dataField: 'name',
      text: 'Nimi',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle', 
      sort: true,
      formatter: (dataField, {  name,id }) => {
        return (
          <Link to={'/asukoht/'+id} >
            <h5 className="mb-0 fs--1">{name}</h5>
          </Link>
        );
      }
    },
    {
      dataField: 'address',
      headerClasses: 'border-0',
      text: 'Aadress',
      classes: 'border-0 py-2 align-middle',
      sort: true
    }, 
    {
      dataField: 'description',
      headerClasses: 'border-0',
      text: 'Kirjeldus',
      classes: 'border-0 py-2 align-middle',
      sort: true
    } ,actionColumn 
  ];
  return (
    <Resources
         columns={columns}
         resourceName={'storages'}
         resourceLink="asukoht"
         resourcePath="asukohad"
         resourceDisplayName={'Asukohad'}
         keyField="id"
         actionColumn={actionColumn}
      />
  );
};
export default Asukohad;

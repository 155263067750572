import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; 
import { Button, Col, Form,  FormFeedback,  FormGroup, Input, Row } from 'reactstrap'; 
import LoginImg from '../../../assets/img/m2.webp'
import { query } from '../../../services/restClient';
import { useAlert } from '@blaumaus/react-alert';
import { Link, Navigate, useNavigate, useParams  } from 'react-router-dom'; 
import {sessionContext} from '../../../services/sessionContext';
import { Facebook, Google } from 'react-bootstrap-icons';
import { useForm } from 'react-hook-form';
import {options} from '../../../services/options';
import { setJwtToken } from '../../../services/session';
const ValidatedInput = React.forwardRef((props, ref) => ( 
  <Input innerRef={ref} {...props} /> 
));

const LoginForm = ({user,sessionOptions,validationtoken}) => {
  const hasLabel = true;
  const alert = useAlert();  
  const [message,setMessage] = useState();
  const [isMessageError,setIsMessageError] = useState();
  const navigate = useNavigate();
  // State
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(); 
  
  // Handler
  const postAuthenticate = (data) => {
    
    query({
      url:'users/authenticate' ,
      method: 'POST',
      body : data, 
      onSuccess:(responseBody)=>
      {     
         if(responseBody)
         { 
            sessionOptions.setTimeout("1740"); 
            setJwtToken(responseBody);
            if(user.isAuthorized())
            { 
              navigate('/toolaud') ;
            }
         }
         else
         {
          alert.error("Autentimine ebaõnnestus.")
          console.error(responseBody);
         } 
      }, 
      onFailure: (error)=>{
        console.error(error);
        if(error?.text === "WrongUserOrPass") 
        {
          setMessage("Vale kasutanimi või parool");
          setIsMessageError(true);
        }
      }
    }); 
  };

  useEffect(()=>{
    if(validationtoken)
    {
      query({
        url:'users/validate' ,
        method: 'POST',
        body : validationtoken, 
        onSuccess:(responseBody)=>
        {    
          setMessage("Konto on aktiveeritud, saate sisse logida.");
          setIsMessageError(false);
        }, 
        onFailure: (error)=>{
          console.error(error); 
        }
      });  
    } 
  },[validationtoken])
 
  return (   <div className="p-4 card-body">  
    <div className="d-flex justify-content-end"> 
    <p className="mb-0 fs--1"><span className="fw-semi-bold">Uus kasutaja? </span><a
            href="/autentimine/registreeri">Loo konto</a></p>
   </div> 
    <Form onSubmit={handleSubmit((data)=>postAuthenticate(data))}>
    {message&&
            <div role="alert" className={`mt-2 fade alert alert-${(isMessageError?'danger':'info')} alert-dismissible show`}>
             <button type="button" className="btn-close" onClick={()=> setMessage(null) } aria-label="Close alert"></button>
             <div className="alert-heading h6">{message}</div> 
             </div>}
      <FormGroup className="mb-3">
        {hasLabel && <label>Kasutajanimi</label>}
        <ValidatedInput
          placeholder='Kasutajanimi' 
          {...register('username',  { required: 'Sisesta kasutajanimi' })}
          invalid={!!errors.username}  
        />  
        <FormFeedback invalid="true">{errors.username?.message}</FormFeedback>
      </FormGroup>

      <FormGroup className="mb-3">
        {hasLabel && <label>Parool</label>}
        <ValidatedInput
          placeholder={!hasLabel ? 'Parool' : ''}
          {...register('password',  { required: 'Sisesta parool' })}
          invalid={!!errors.password}  
          type="password"
        />
        <FormFeedback invalid="true">{errors.password?.message}</FormFeedback>
      </FormGroup>
      
      <FormGroup>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100" 
        >
          Sisene
        </Button>
      </FormGroup>
      <Row >
        <Col className="d-flex justify-content-end"><Link className="fs--1 mb-0" to="/autentimine/parool-ununes">Unustasid parooli?</Link></Col>
      </Row> 
      <div className="w-100 position-relative text-center mt-4">
                <hr className="text-300"/>
                <div className="divider-content-center"> või logi sisse muul viisil</div>
            </div>
            <div className="mb-0">
                <Row>
                    <div className="pe-sm-1 col-sm-6"><button type="button"
                            className="btn-outline-google-plus mt-2 w-100 btn btn-sm"><Google size={20}></Google> google</button></div>
                    <div className="ps-sm-1 col-sm-6"><button type="button"
                            className="btn-outline-facebook mt-2 w-100 btn btn-sm"><Facebook size={20}></Facebook>  facebook</button></div>
                </Row>
            </div>
    </Form>
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

 
const LoginPage =()=>{
   
  const {validationtoken} = useParams();
  return (<div className="container-fluid">
  <div className="min-vh-100 bg-100 row">
      <div className="d-none d-lg-block position-relative col-6">
          <div className="bg-holder"
              style={{
                backgroundImage:`url(${LoginImg})`,
                backgroundPosition: "50% 20%"
              }}>
          </div>
      </div>
      <div className="px-sm-0 align-self-center mx-auto py-5 col-sm-10 col-md-6">
          <div className="g-0 justify-content-center row">
              <div className="col-xxl-6 col-lg-9 col-xl-8">
                  <div className="card">
                      <div className="bg-shape bg-circle-shape text-center p-2 card-header"><Link
                              className="text-white light font-sans-serif fs-5 z-index-1 position-relative"
                              style={{
                                fontFamily: '"din-1451", Arial, Tahoma',
                                fontWeight: "normal !important"
                              }} to="/">Carmasters OÜ</Link></div> 
                           <sessionContext.Consumer>
                            {({user,options}) => {
                              return ( 
                                <LoginForm user={user} sessionOptions={options} validationtoken={validationtoken}/>
                              );
                            }}
                          </sessionContext.Consumer> 
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>)
}

export default LoginPage; 

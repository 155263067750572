import React, {  useState, Fragment } from 'react'; 
import { useParams } from 'react-router'; 
import {
    Col,
    Row,  Form,Card,CardBody,CardFooter  
} from 'reactstrap';
import {  Check } from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import {   useNavigate } from 'react-router-dom';
import { useGet,useDelete,usePut } from '../../services/restClient'; 
import { ReadOnlyCardHeader, ReadOnlyFooter } from './Asukoht'
import { Button } from 'reactstrap';
import Loader from '../_shared/Loader';
import { useAlert } from '@blaumaus/react-alert';
 
const EditableCardBody = ({
    storage ,
    changeStorage
}) => {
 
    const [address, setAddress] = useState(storage?.address??'');
    const [name, setName] = useState(storage?.name??''); 
    const [description, setDescription] = useState(storage?.description??'');
    const alert = useAlert();
    let navigate = useNavigate();
    const changeResourceHandler = e => {
        e.preventDefault(); 
        var body ={ address, name, description, introducedAt: new Date() };
        
        changeStorage(body,(response)=>{
            if (!storage) {
                navigate('/asukoht/' + Number(response).toString());
                alert.show('Uus asukoht lisatud.');
            }
            else {
                navigate('/asukoht/'+storage.id);
                alert.show('Asukoht andmed muudetud.');
            }
        }) 
    };
  
    return (
        <Form onSubmit={changeResourceHandler}>
            <Row>
              
                <Col lg={6}>
                    <FormGroupInput
                        id="name"
                        label="Nimi"
                        value={name}
                        onChange={({ target }) => setName(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="address"
                        label="Aadress"
                        value={address} required
                        onChange={({ target }) => setAddress(target.value)}
                    />
                </Col> 
                <Col xs={12}>
                    <FormGroupInput
                        id="description"
                        label="Kirjeldus"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        type="textarea"
                        rows="5"
                    />
                </Col>
            </Row> 
            <Row>
                <Col className="text-end">
                    <Button
                        color=" btn-primary"
                        size="sm"
                        type="submit"
                    > <Check size={20} className="me-1"></Check>
                      Salvesta muudatused
                  </Button>
                </Col>
            </Row>
        </Form>
    );
}

//edit
const StorageEditCard = () => {
    const { id } = useParams(); 
    
    const url = `storages/${id}`;
    const [storage] = useGet({ url});
    const [deleteStorage]= useDelete({url});
    const [changeStorage] = usePut({url});
 
    

    return (
        <Fragment> 
        {!storage ? (
          <Loader />
           ) : ( <Card className="mb-3">
                <ReadOnlyCardHeader   { ...{ isEditing:false, storage, deleteStorage} } />
                <CardBody className="bg-light border-top">
                    <EditableCardBody { ...{ storage, changeStorage} } />
                </CardBody>
                <CardFooter className="border-top">
                    {<ReadOnlyFooter storage={storage} />}
                </CardFooter>
                </Card>)
              }
      </Fragment>  
    );
}



export default StorageEditCard;
export {
    EditableCardBody
}

import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { Nut, PencilFill, ThreeDots} from 'react-bootstrap-icons';
import {
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,Card,CardBody,CardFooter ,Button
} from 'reactstrap';
import Loader from '../_shared/Loader';
import { useGet } from '../../services/restClient';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';
const SparePartRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);


  
const ReadOnlyCardHeader = ({sparePart,isEditing,deleteSparePart}) =>{
 
    if(!sparePart) throw new Error('sparePart required');
    let navigate = useNavigate();
    const [collapseOne, setCollapseOne] = useState(false);
   
     
    return (
      <Fragment>
           <ConfirmDeleteModal 
            isOpen={collapseOne}
            url={ 'spareparts'} 
            onRequestingClose={()=>{ setCollapseOne(false)}} 
            onConfirm={ ()=>{ navigate('/varuosad')}}
            deleteBody={()=>{
              return[sparePart.id]
            }}></ConfirmDeleteModal>   
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
          <span>{sparePart.code}</span>
         </h5>
          </Col>
          <Col xs="auto" >
          <h6 className="text-uppercase text-600">
                 <Nut />
                </h6>
          </Col>
        </Row>
        <Row>
          <Col>
           <UncontrolledDropdown className="d-inline-block ms-2">
              <DropdownToggle color="falcon-default" size="sm">
                <ThreeDots />
              </DropdownToggle>
              <DropdownMenu> 
                <DropdownItem  className="text-danger" onClick={ ()=>  setCollapseOne(true) } >Kustuta varuosa</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                      navigate('/varuosa-muuda/'+ sparePart.id);
                    }
                    else{
                      navigate('/varuosa/'+ sparePart.id);
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Uuenda detaile') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row>
        </CardHeader>
      </Fragment>
      
      );
}

const ReadOnlyFooter= ({ sparePart })=>{
    if(!sparePart) throw new Error('sparePart required');
    return (
         <Row>
        <Col>
        {<Media> 
           <Media body>
              <p className="mb-0">Varuosa loodi</p>
              <p className="fs--1 mb-0 text-600">{sparePart.introducedAt}</p>
            </Media>
          </Media>}
        </Col>
        <Col className="text-end">
        </Col>
      </Row>);
}

const ReadOnlyCardBody = ({ sparePart }) => {
    if(!sparePart) throw new Error('sparePart required');
  return (
    <Row>
      <Col lg className="col-xxl-5">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Varuosa Informatsioon</h6>
        <SparePartRow title="Id">{sparePart.id}</SparePartRow>
        <SparePartRow title="Kood">{sparePart.code}</SparePartRow>
        <SparePartRow title="Nimi">{sparePart.name}</SparePartRow>
        <SparePartRow title="Hind">{sparePart.price.toFixed(2)  } EUR</SparePartRow>
        <SparePartRow title="Kogus">{sparePart.quantity  }</SparePartRow>
        <SparePartRow title="Allahindlus">{sparePart.discount.toFixed(0)  } %</SparePartRow>
        <SparePartRow title="Asukoht">{sparePart.storageName  }</SparePartRow>
      </Col>
      <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Lisa Informatsioon</h6>
        <SparePartRow title="Kirjeldus">
          {sparePart.description ? (
            sparePart.description.split('\n').map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Kirjeldus puudub</p>
            )}
        </SparePartRow>
      </Col>
    </Row>
  );

}



//display
const SparePartCard = (props) => {
    const { id }  = useParams();

    if(!id) throw new Error('id required');

    const [sparePart] = useGet({ url:`spareparts/${id}`}); 
     
    return (
      <Fragment> 
      {!sparePart ? (
        <Loader />
        ) : ( <Card className="mb-3">
        <ReadOnlyCardHeader { ...{ isEditing:false, sparePart}}/>
        <CardBody className="bg-light border-top">
          <ReadOnlyCardBody { ...{ sparePart}}/>
        </CardBody>
        <CardFooter className="border-top">
          {<ReadOnlyFooter  sparePart={sparePart}/>}
        </CardFooter>
      </Card>) }
    </Fragment> 
    ); 
}

export default SparePartCard;
export { 
    ReadOnlyFooter,
    ReadOnlyCardHeader
}
import React, {  useState,Fragment } from 'react';   
import {
    Col,
    Row,  Form, FormGroup, Label, Input 
} from 'reactstrap';
import {  Check } from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import {   useNavigate } from 'react-router-dom';
import { ReadOnlyCardHeader } from './Seaded'
import { Button,Card,CardBody,CardFooter  } from 'reactstrap';
import Loader from '../_shared/Loader';
import {   useGet,usePut  } from '../../services/restClient';
import { useAlert } from '@blaumaus/react-alert'; 
const EditableCardBody = ({
    options = {
         requisites: {
            name: '',
            phone:  '',
            address: '',
            email:  '',
            bankAccount:  '',
            regNr:  '',
            kmkr:  '',
          },
          pricing: {
            invoice: {
              surCharge:  '',
              disclaimer:  '',
              signatureLine: true,
              emailContent:  '',
            },
            estimate: {
              emailContent:  '',
            }
          }
    }, 
    changeOptions,
}) => {
  

    const [name, setName] = useState(options.requisites.name);
    const [phone, setPhone] = useState(options.requisites.phone);
    const [kmkr, setKmkr] = useState(options.requisites.kmkr); 
    const [address, setAddress] = useState(options.requisites.address);
    const [email, setEmail] = useState(options.requisites.email);
    const [regNr, setRegNr] = useState(options.requisites.regNr); 
    const [bankAccount, setBankAccount] = useState(options.requisites.bankAccount);

    const [surCharge, setSurCharge] = useState(options.pricing.invoice.surCharge);
    const [disclaimer, setDisclaimer] = useState(options.pricing.invoice.disclaimer);
    const [signatureLine, setSignatureLine] = useState(options.pricing.invoice.signatureLine); 
    const [invoiceEmailContent, setInvoiceEmailContent] = useState(options.pricing.invoice.emailContent);
    const [estimateEmailContent, setEstimateEmailContent] = useState(options.pricing.estimate.emailContent);

    let navigate = useNavigate();

    
    const alert = useAlert();
    const changeResourceHandler = e => {
        e.preventDefault();
        
        var body = {
            requisites: {
                name,
                phone,
                address,
                email,
                bankAccount,
                regNr,
                kmkr,
              },
              pricing: {
                invoice: {
                  surCharge,
                  disclaimer,
                  signatureLine,
                  emailContent:  invoiceEmailContent,
                },
                estimate: {
                  emailContent:  estimateEmailContent,
                }
              }
        };
      
        changeOptions(body,(response) => {
            navigate('/seaded');
                alert.show('Andmed muudetud.')
        }) ;
    };
 
    return (
        <Form onSubmit={changeResourceHandler}>
              <h6 className="font-weight-bold ls mb-3 text-uppercase">Ettevõte</h6>
            <Row>
                <Col lg={6}>
                    <FormGroupInput
                        id="name"
                        label="Nimi"
                        value={name} required
                        onChange={({ target }) => setName(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="phone"
                        label="Telefon"
                        value={phone} required
                        onChange={({ target }) => setPhone(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="address"
                        label="Aadress"
                        value={address}  required
                        onChange={({ target }) => setAddress(target.value)}
                        
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="email"
                        label="Email"
                        value={email}  required
                        onChange={({ target }) => setEmail(target.value)}
                        type="email"
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="regNr"
                        label="Registrikood"
                        value={regNr}  required
                        onChange={({ target }) => setRegNr(target.value)}
                        
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="bankAccount"
                        label="Pangakonto"
                        value={bankAccount}  required
                        onChange={({ target }) => setBankAccount(target.value)}
                       
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="kmkr"
                        label="KMKR nr."
                        value={kmkr}  required
                        onChange={({ target }) => setKmkr(target.value)}
                    />
                </Col> 
            </Row>  
            <hr></hr> 
            <h6 className="font-weight-bold ls mb-3 text-uppercase">Arve seaded</h6>
            <Row> 
                <Col lg={6}>
                    <FormGroupInput
                        id="surCharge"
                        label="Viivis"
                        value={surCharge}  required
                        onChange={({ target }) => setSurCharge(target.value)}
                         
                    />
                </Col> 
                <Col lg={6}>
                    
                    <FormGroup> 
                    <Label>Allkirja rida</Label>
                        <FormGroup switch>
                            <Input
                                
                                type="switch"
                                cursor="pointer"
                                id="signatureLine"
                                name="signatureLine"
                                checked={signatureLine}
                                onChange={({ target }) => { 
                                        setSignatureLine(target.checked); 
                                }} 
                           ></Input>
                           <label> {signatureLine?' Jah':' Ei'}</label>
                        </FormGroup>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="disclaimer"
                        label="Lahtiütlus"
                        type="textarea"
                        rows="8"
                        value={disclaimer}  required
                        onChange={({ target }) => setDisclaimer(target.value)} 
                    />
                </Col>  
                <Col lg={6}>
                    <FormGroupInput
                        id="invoiceEmailContent"
                        label="Emaili sisu" 
                        type="textarea"
                        rows="8"
                        value={invoiceEmailContent}  required
                        onChange={({ target }) => setInvoiceEmailContent(target.value)} 
                    />
                </Col> 
               
            </Row>  
            <hr></hr> 
            <h6 className="font-weight-bold ls mb-3 text-uppercase">Pakkumise seaded</h6>
            <Row>  
                <Col lg={6}>
                    <FormGroupInput
                        id="estimateEmailContent"
                        label="Emaili sisu"
                        type="textarea"
                        rows="10"
                        value={estimateEmailContent}  required
                        onChange={({ target }) => setEstimateEmailContent(target.value)} 
                    />
                </Col> 
               
            </Row> 
            <hr></hr> 
            <Row>
                <Col className="text-end">
                    <Button
                        color=" btn-primary"
                        size="sm"
                        type="submit"
                    > <Check size={20} className="me-1"></Check>
                      Salvesta muudatused
                  </Button>
                </Col>
            </Row>
        </Form>
    );
}
 
//edit
const OptionsEditCard = () => {
     
    const [options] = useGet({ url:`options`});  
    const [changeOptions] = usePut({ url:`options`}); 

    return (
        <Fragment> 
        {!options ? (
        <Loader />
        ) : ( <Card className="mb-3">
            <ReadOnlyCardHeader { ...{isEditing:true, requisites:options.requisites}} />
            <CardBody className="bg-light border-top">
            <EditableCardBody { ...{ options,changeOptions}} />
            </CardBody>
            <CardFooter className="border-top"> 
            </CardFooter>
            </Card>)
            }
    </Fragment> 
    );
}



export default OptionsEditCard;

import React, { useState, useEffect } from 'react';
import {
  CardHeader,
  Col,
  Row,  CardBody, Card
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { query } from '../../services/restClient';
import Loader from '../_shared/Loader';
import { useErrorStatus } from '../../UnhandledErrorHandler';


const loadClientVehicles = ({ id,   onError, onSuccess }) => {
 
  query({
    url: 'clients/' + id + '/vehicles',
    method: 'GET',
    onSuccess: onSuccess,
    onFailure: onError
  });
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const VehicleRow = ({ clientId, vehicle }) => {

  const { producer, model, regNr, vin, id, ownerId} = vehicle;
  const isCurrentOwner = +clientId === ownerId;
 
  return ( 
    <ConditionalWrapper 
      condition={!isCurrentOwner}
      wrapper={children => <del >{children}</del>  }
    >
      <Row noGutters className=" border-bottom py-2 px-3">
        <Col   >
          <Link to={'/soiduk/' + id} >
            <strong> {regNr}</strong>
          </Link>
        </Col>
        <Col >
          {producer} {model}
        </Col>
        <Col>
            {vin}
        </Col> 
      </Row>
    </ConditionalWrapper> 
  );
};

//display
const ClientVehiclesCard = ({ id }) => {

  if (!id) throw new Error('id required');

  const [clientVehicles, setClientVehicles] = useState();
  const setError = useErrorStatus();

  useEffect(() => { 
    loadClientVehicles({
      id,
      onSuccess: setClientVehicles,
      onError:setError
    });
  }, [id,setError]);
  
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h5 >
              Sõidukid
</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="border-top p-0">
        {!clientVehicles ? (
          <Loader />
        ) : (
            !!clientVehicles && clientVehicles.map((vehicle, index) => <VehicleRow clientId={id} vehicle={vehicle} key={index} />)
          )}
      </CardBody>
    </Card>
  );
}

export default ClientVehiclesCard;
export {
  loadClientVehicles,VehicleRow
}



import { Fragment, useState } from 'react'; 
import Loader from '../_shared/Loader';
import { isMobile } from "react-device-detect"; 

export const PricingPdfView =({url})=>{
  
    const [loading,setIsLoading]= useState(true);

    const canRenderPdf =()=>{
        if(isMobile) return false;
        var isSmallScreen = window.matchMedia('(max-width: 768px)');
        if(isSmallScreen.matches)
        {
            return false;
        }
        return true;
    }
    
    if(!canRenderPdf()) return <Fragment></Fragment>
 
   return (
    <Fragment>
        {loading&&<Loader>Laen pdf-i..</Loader>}
        <Fragment>   
             <object className='w-100' id='pdfViewer' height="1200px"  data={url+'/pdf'}  type="application/pdf"  onLoad={ ()=>{
                 setIsLoading(false); 
             }} >
             </object>
         </Fragment>
    </Fragment>
    )
}

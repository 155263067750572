import { Fragment, useState, useRef } from 'react';
import { Check, Pencil, Download,Files,Send,FileText, SendCheck,InfoCircle, Trash,CheckCircle} from 'react-bootstrap-icons';
import { 
    Row, Button, ButtonGroup, Input, FormGroup, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { useAlert } from '@blaumaus/react-alert'; 
import { unstable_batchedUpdates } from 'react-dom';
import {OfferDelete} from './PricingActions';
import { usePut,useGet, downloadPdf } from '../../services/restClient';
import { useErrorStatus } from '../../UnhandledErrorHandler';
import { SendPricing,IssuePricing,CancelPricing } from './PricingActions';

const EstimateEditView =({workId,offer,setOffer, setIsEditing})=>{
    const [notes,setNotes] = useState(offer.notes);
    const [isVehicleLinesOnEstimate,setIsVehicleLinesOnEstimate]=useState(offer.isVehicleLinesOnEstimate);
   
    const [putOffer] = usePut({ url:`work/${workId}/offers/${offer.number}`});  
    const put =()=>{
        putOffer({
            notes,
            isVehicleLinesOnEstimate
        },(response)=> unstable_batchedUpdates(()=>{
           setIsEditing(false);
           setOffer(response);
        }))
    }

    const cancel =()=>{
        setIsEditing(false);
    }
    return (<Fragment>
        <FormGroup  style={{ whiteSpace: "pre-wrap" }}>
            <label>Kirjeldus</label>
            <Input id="description"
                    label="Kirjeldus"
                    type="textarea"
                    rows="6" value={notes}
                    onChange={({ target }) => { setNotes(target.value) }} />
        </FormGroup> 
        <FormGroup>
            <FormGroup switch>
              <Input
                    type="switch"
                    id="customSwitch"
                    inline="true"
                    name="customSwitch"
                    checked={isVehicleLinesOnEstimate} 
                    onChange={({ target }) => {
                        setIsVehicleLinesOnEstimate(target.checked);
                    }}
                ></Input>
                <label>Kuva sõiduki andmeid pakkumisel</label>
            </FormGroup> 
          </FormGroup>
          <div className="text-end">
          <ButtonGroup > 
                                    <Button color="primary" size="sm" outline onClick={()=>cancel() }   role="button" >
                                        <Pencil></Pencil> Katkesta
                                    </Button> 
                                    <Button color="primary" onClick={()=>put()}  size="sm" >
                                        <Check className="me-1"></Check>
                                        Salvesta
                                    </Button> 
             </ButtonGroup>
          </div>
        
    </Fragment>)
}
const NoData = ({ children }) => {
    return (<p className="ms-2 mb-1 fs--1 font-italic text-400">{children}</p>);
}
export const OfferOptionsComponent =({offerId,workId,pdfPath,setWorkStatus,isReadOnly})=>{

    const [acceptOffer] = usePut({url:`work/${workId}/estimate/accept/${offerId}`});
    const [offer,setOffer] = useGet({ url:`work/${workId}/offers/${offerId}`}); 
    const [isEditing,setIsEditing] = useState();
    const [dropdownOpen, setOpen] = useState(false);  
    const toggle = () => setOpen(!dropdownOpen);
    const offerDeleteRef = useRef(); 
    
    const issueRef = useRef();
    const cancelRef = useRef();
    const sendRef = useRef();
    const { setError} = useErrorStatus();
    const navigate = useNavigate();
    const alert = useAlert() ;

    const wasSent =()=>{
        return offer?.estimate?.sentOn;
    }
    const accept=()=>{
        unstable_batchedUpdates(()=>{
            acceptOffer('',(response)=>{
                navigate(`/too/${workId}`);
                alert.show('Pakkumise tooted ja teenuse kantud tööse.')
            })
        })
    }

    if(!offer) return (<Fragment></Fragment>);
    
    return ( 
        <Fragment>
             <OfferDelete ref={offerDeleteRef} offerId={offerId} workId={workId}  ></OfferDelete>
              <SendPricing ref={sendRef} id={workId} offerId={offerId} setPricing={setOffer} clientName={offer?.estimate?.clientName}  ></SendPricing>
              <IssuePricing ref={issueRef} id={workId} offerId={offerId} setWorkStatus={setWorkStatus}  ></IssuePricing>
              <CancelPricing ref={cancelRef} id={workId} offerId={offerId} setWorkStatus={setWorkStatus}  sentOn={offer?.estimate?.sentOn}  ></CancelPricing>
              <Row> 
            {isEditing?
               <EstimateEditView   workId={workId} setOffer={setOffer} setIsEditing={setIsEditing} offer={offer}></EstimateEditView>:
                    <Fragment> 
                         {offer.estimate&& (<Fragment> 
                            <h6 className="mb-2">Väljastatud </h6>
                            <p className="ms-2 mb-1 fs--1">
                                <CheckCircle size={20} color="Green" ></CheckCircle> {Moment(offer.estimate.issuedOn).locale('et').format('D MMM yyyy')}, {offer.estimate.issuerName}
                            </p>
                            {offer.estimate.sentOn ?
                                <Fragment>
                                    <p className="ms-2 mb-1 fs--1">
                                        <SendCheck size={20} color='Green'></SendCheck> {Moment(offer.estimate.sentOn).locale('et').format('D MMM yyyy HH:mm')}, {offer.estimate.email}
                                    </p>
                                </Fragment> :
                                <Fragment>
                                   <NoData><InfoCircle size={20}></InfoCircle> saatmata</NoData>
                                </Fragment>} 
                        </Fragment>)}
                        <h6 className="fs--1">{'Sõiduki andmed kuvatakse pakkumisel.'}</h6>
                        <p className="ms-2 mb-2 fs--1">{offer.isVehicleLinesOnEstimate ? 'Jah' : 'Ei'}</p>
                        <h6>Kirjeldus</h6><div className="ms-2 fs--1" style={{ whiteSpace: "pre-wrap" }}>{offer.notes}</div>
                       
                    </Fragment> 
             }  
              </Row> 
            {!isEditing &&   <div className="text-end">
                    <ButtonDropdown size="sm" color="primary" isOpen={dropdownOpen} toggle={toggle}>
                        {offer.estimate && <Button size="sm" outline color="primary" onClick={() => downloadPdf({ pdfPath, fileName: `pakkumine_nr_${offerId}.pdf`, setError })}>
                            <Download></Download> Pdf
                        </Button>}
                        {offer.estimate ? 
                            <Button size="sm" disabled={isReadOnly} outline color="primary" role="button" onClick={() => accept()}    >
                                <Files></Files> Võta töösse
                            </Button> :
                            <Button color="primary" disabled={isReadOnly} onClick={() => setIsEditing(true)} outline size="sm" ><Pencil className="me-1"></Pencil>Muuda pakkumist</Button>
                        }
                        <DropdownToggle split color="primary">
                        </DropdownToggle>
                        <DropdownMenu>
                            {offer.estimate && <DropdownItem disabled={isReadOnly} onClick={() => sendRef.current.send()}><Send></Send> Saada {wasSent() ? ' (uuesti)' : ''}</DropdownItem>}
                            {offer.estimate && <DropdownItem disabled={isReadOnly} onClick={() => cancelRef.current.cancel()}>  <Trash></Trash> Tühista dokument</DropdownItem>}
                            {!offer.estimate && <DropdownItem disabled={isReadOnly} onClick={() => issueRef.current.issue()}><FileText></FileText> Väljasta</DropdownItem>}
                            {!offer.estimate && <DropdownItem disabled={isReadOnly} onClick={() => offerDeleteRef.current.delete()}>  <Trash></Trash> Kustuta</DropdownItem>}
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>}
        </Fragment>)
}

import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { PencilFill, ThreeDots } from 'react-bootstrap-icons';
import {
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,Button,Card,CardBody,CardFooter 
} from 'reactstrap';
import { useNavigate } from 'react-router-dom'; 
import Loader from '../_shared/Loader';
import { useGet } from '../../services/restClient';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';

const EmployeeRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col xs={5} sm={4}>
      <p
        className={classNames('font-weight-semi-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);
 
const getEmployeeName =(employee)=>{
    return employee.firstName +' '+employee.lastName;
}

const ReadOnlyCardHeader = ({employee,isEditing}) =>{
 
    if(!employee) throw new Error('employee required');
    let navigate = useNavigate();
    const [collapseOne, setCollapseOne] = useState(false);
    
  
    return (
      <Fragment>
          <ConfirmDeleteModal 
            isOpen={collapseOne}
            url={ 'employees'} 
            onRequestingClose={()=>{ setCollapseOne(false)}} 
            onConfirm={ ()=>{ navigate('/tootajad')}}
            deleteBody={()=>{
              return[employee.id]
            }}></ConfirmDeleteModal>  
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
              {
          getEmployeeName(employee)}
              </h5>
          </Col>
          <Col xs="auto" >
          <h6 className="text-uppercase text-600">
                 
                </h6>
          </Col>
        </Row>
        <Row>
          <Col>
           <UncontrolledDropdown className="d-inline-block ms-2">
              <DropdownToggle color="falcon-default" size="sm">
                <ThreeDots />
              </DropdownToggle>
              <DropdownMenu> 
                <DropdownItem  className="text-danger" onClick={ ()=>  setCollapseOne(true) } >Kustuta töötaja</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                      navigate('/tootaja-muuda/'+ employee.id);
                    }
                    else{
                      navigate('/tootaja/'+ employee.id);
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Uuenda detaile') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row>
        </CardHeader>
      </Fragment>
      
      );
}

const ReadOnlyFooter= ({ employee })=>{
    if(!employee) throw new Error('employee required');
    return (
         <Row>
        <Col>
        {<Media> 
           <Media body>
              <p className="mb-0">Töötaja loodi</p>
              <p className="fs--1 mb-0 text-600">{employee.introducedAt}</p>
            </Media>
          </Media>}
        </Col>
        <Col className="text-end">
        </Col>
      </Row>);
}

const ReadOnlyCardBody = ({ employee }) => {
    if(!employee) throw new Error('employee required');
  return (
    <Row>
      <Col lg className="col-xxl-5">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Töötaja Informatsioon</h6>
        <EmployeeRow title="Id">{employee.id}</EmployeeRow>
        <EmployeeRow title="Eesnimi">{employee.firstName}</EmployeeRow>
        <EmployeeRow title="Perenimi">{employee.lastName  }</EmployeeRow>
        
        <EmployeeRow title="Email">{employee.email}</EmployeeRow>
        <EmployeeRow title="Telefon">{employee.phone  }</EmployeeRow>
        <EmployeeRow title="Elukoht">{employee.address  }</EmployeeRow>
        <EmployeeRow title="Ametikoht">{employee.proffession  }</EmployeeRow>
        <EmployeeRow title="Kasutajanimi">
            {employee.userName ? employee.userName : <p className="font-italic text-400 mb-0">Pole määratud kasutajaks</p>}
          </EmployeeRow>
      </Col>
      <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
        <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">Lisa Informatsioon</h6>
        <EmployeeRow title="Kirjeldus">
          {employee.description ? (
            employee.description.split('\n').map((item, key) => {
              return <Fragment key={key}>{item}<br/></Fragment>
            })
          ) : (
              <p className="font-italic text-400 mb-1">Kirjeldus puudub</p>
            )}
        </EmployeeRow>
      </Col>
    </Row>
  );

}



//display
const EmployeeCard = (props) => {
    const { id }  = useParams();

    if(!id) throw new Error('id required');

    const [employee] = useGet({ url:`employees/${id}`}); 
     
    return (
      <Fragment> 
      {!employee ? (
        <Loader />
        ) : ( <Card className="mb-3">
        <ReadOnlyCardHeader { ...{ isEditing:false, employee}}/>
        <CardBody className="bg-light border-top">
          <ReadOnlyCardBody { ...{ employee}}/>
        </CardBody>
        <CardFooter className="border-top">
          {<ReadOnlyFooter  employee={employee}/>}
        </CardFooter>
      </Card>) }
    </Fragment> 
    ); 
}

export default EmployeeCard;
export { 
    ReadOnlyFooter,
    ReadOnlyCardHeader
}
import React,{Fragment} from 'react';
import Moment from 'moment';
import {Resources,nameFormatter,emailFormatter,phoneFormatter} from '../_shared/Ressursid';




const Kliendid = () => {
  Moment.locale('et');
  const actionColumn = {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle',
    align: 'right'
  };
  const columns = [
    {
      dataField: 'name',
      text: 'Nimi',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: nameFormatter
    },
    {
      dataField: 'email',
      headerClasses: 'border-0',
      text: 'Email',
      classes: 'border-0 py-2 align-middle',
      formatter: emailFormatter
    },
    {
      dataField: 'phone',
      headerClasses: 'border-0',
      text: 'Telefon',
      classes: 'border-0 py-2 align-middle',
      formatter: phoneFormatter
    },
    {
      dataField: 'address',
      headerClasses: 'border-0',
      text: 'Elukoht',
      classes: 'border-0 py-2 align-middle'
    },
    {
      dataField: 'introducedAt',
      headerClasses: 'border-0',
      text: 'Klient alates',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      align: 'right',
      formatter: (dataField, { introducedAt })=> {  
        return Moment(introducedAt).format('D MMM yyyy'); 
       },
      headerAlign: 'right'
    },
    actionColumn
  ];

  return (
    <Resources
         columns={columns}
         keyField="id"
         resourceName={'clients'}
         resourceLink={'klient'}
         resourcePath="kliendid"
         resourceDisplayName={'Kliendid'}
         documentation={<Fragment> <strong>Otsida saab kliendi nime, telefoni, emaili või aadressi järgi</strong>
          <ul className="ms-2 list-unstyled">
            <li>Näiteks kõik kliendid asukohas Veerenni 56
              <ul>
                <li><em>Veerenni 56</em></li>
              </ul>
            </li> 
            <li>klient kelle telefoni number algab numbriga 534588
              <ul>
                <li><em>534588*</em></li>
              </ul>
            </li>
            <li>Kõik ettevõtted Tallinnas
              <ul>
                <li><em>oü tallinn</em></li>
              </ul>
            </li>
            <li>Kõik kliendid kelle nimi on Arvo
              <ul>
                <li><em>arvo</em></li>
              </ul>
            </li>
          </ul> </Fragment>}
         actionColumn={actionColumn}
      />
  );
};
export default Kliendid;

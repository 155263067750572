import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';



// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ({ isOpen, onLogOffCall, onRequestClose,timoutInSeconds }) => {

    const [time, setTime] = useState({ timeSpan: {}, seconds: timoutInSeconds });
    let timer = 0;
    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    };
   
    useEffect(() => {
        // exit early when we reach 0
        if (!time || time.seconds<=0) return;
     
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
          // Remove one second, set state so a re-render happens.
            let seconds = time.seconds - 1;
            setTime({
                timeSpan: secondsToTime(seconds),
                seconds: seconds,
            });
        }, 1000);
    
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
      }, [time]);

    
    if (!isOpen && timer) {
        setTime(null);
    }

    if (!time.timeSpan.s) return <Fragment></Fragment>
    
    return (
        <div>
            <Modal centered isOpen={isOpen}>
                <ModalHeader>Sessioon aegumas</ModalHeader>
                <ModalBody>
                    <p>Kas jätkame sessiooni või logime süsteemist välja?</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={onLogOffCall}>
                        Jah
                    </Button>
                    <Button
                        color="primary"
                        onClick={()=>{
                            setTime(null);
                            onRequestClose();
                            timer = 0;
                        }}>
                       Ei ({time.timeSpan.m.toString().padStart(2, "0")}:{time.timeSpan.s.toString().padStart(2, "0")})
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
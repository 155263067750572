import { forwardRef, Fragment, useState, useImperativeHandle, useRef } from 'react';
import { X, Check, Pencil, Percent, PlusCircle, XCircle } from 'react-bootstrap-icons';
import { 
    Row, Button, ButtonGroup, Input, Modal, ModalBody, ModalHeader, Form, FormGroup, ModalFooter, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu,Col
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { DragDropContext,  Draggable,Droppable } from 'react-beautiful-dnd';
 
import { useAlert } from '@blaumaus/react-alert';
import { useGet, usePut } from '../../services/restClient';
import SparePartRemoteSelect from '../Varuosa/VaruosaRemoveSelect';
import { unstable_batchedUpdates } from 'react-dom';
import Loader from '../_shared/Loader';
import FormGroupInput from '../_shared/FormGroupInput';

const ApplyDiscount = forwardRef((props,ref) => {

    const { rowRef } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [discount, setDiscount] = useState(-10);
    useImperativeHandle(ref, () => ({ 
        apply(){
            setIsOpen(true);
        }
    }));
    const applyDiscount =()=>{
        unstable_batchedUpdates(() =>{
            rowRef.current.filter(row => row !== null).forEach(element => {
               element.applyDiscount(discount);
           }); 
           setIsOpen(false);
       })
    }
    return (<Fragment>
        <Modal centered  isOpen={isOpen}>
            <ModalHeader>Määra soodustus</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <FormGroupInput
                            id="name"
                            label="Soodustus"
                            type="number"
                            step="5"
                            value={discount}
                            onChange={({ target }) => setDiscount(target.value)}
                        ></FormGroupInput>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button size="sm"
                    onClick={() => {
                        setIsOpen(false);
                    }}>
                    Katkesta
                </Button>
                <Button size="sm" color="primary" onClick={()=>applyDiscount()} role="button"    >
                    <Percent></Percent> Rakenda
                </Button>
            </ModalFooter>
        </Modal> 
    </Fragment>)
})


const SparePartEditCell = forwardRef((props, ref) => {

    const { defaultValue, isEditing, nameRef, priceRef } = props;
    const [value, setValue] = useState(defaultValue);

    useImperativeHandle(ref, () => ({
        getValue() {
            return value;
        },
    }));

    if (!isEditing) return value;

    return (<SparePartRemoteSelect
        placeholder="Toote kood"
       
        id="test7"
        value={{ label: value, value: value }}
        onChange={(target) => {
            unstable_batchedUpdates(() => {
                setValue(target.value);
                nameRef.current.setValue(target.name);
                priceRef.current.setValue(target.price);
            });
        }}
    ></SparePartRemoteSelect>)
})

const EditableCell = forwardRef((props, ref) => {
    const { defaultValue, placeholder, type, step, id, isEditing, isMoney, isPercentage, required } = props;

    useImperativeHandle(ref, () => ({
        getValue() {
            return value;
        },
        setValue(value) {
            return setValue(value);
        },
    }));


    const [value, setValue] = useState(defaultValue);
    const getFormattedValue = () => {
        if (value === 0) return '';
        if (isMoney) {
            if (!value) return '';
            return (+value).toFixed(2) + ' €';
        }
        if (isPercentage) return value + ' %'
        return value;
    }
    if (!isEditing) return getFormattedValue();
 

    return (<Input
        bsSize="sm"
        required={required}
        id={id}
        type={type}
        step={step}
        className="flex-fill"
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => setValue(target.value)}
    />)
})
//TODO maybe update only row when changing and on submit collect and submit
const DataItemRow = forwardRef((props, ref) => {

    const { isEditing, index, item, removeWorkItem } = props;
    useImperativeHandle(ref, () => ({
        getValue() {
            return {
                code: codeRef.current.getValue(),
                name: nameRef.current.getValue(),
                price: priceRef.current.getValue(),
                unit: unitRef.current.getValue(),
                quantity: quantityRef.current.getValue(),
                discount: discountRef.current.getValue(),
            };
        },
        applyDiscount(value){
            discountRef.current.setValue(value);
        }
    }));
    var codeRef = useRef();
    var nameRef = useRef();
    var priceRef = useRef();
    var unitRef = useRef();
    var quantityRef = useRef();
    var discountRef = useRef(); 
    // Change WorkItem 
    return (<Draggable
        isDragDisabled={!isEditing}
        key={'_prodRow' + index.toString()}
        draggableId={index.toString()}
        index={index}>
        {provided => (
            <tr key={'trRw' + index.toString()}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <td className='col-2  text-nowrap' >
                    <SparePartEditCell
                        placeholder="Toote kood"
                        defaultValue={item.code}
                        isEditing={isEditing}
                        ref={codeRef}
                        nameRef={nameRef}
                        priceRef={priceRef}
                    >
                    </SparePartEditCell>
                </td>
                <td className='col-4  text-nowrap' style={{minWidth:"225px"}} >
                    <div >
                        <EditableCell
                            id="test6"
                            ref={nameRef}
                            type="text"
                            placeholder="Nimi"
                            defaultValue={item.name}
                            isEditing={isEditing}
                        >
                        </EditableCell>
                    </div>
                </td>
                <td className='col-6' style={{minWidth:"400px"}}>
                    <Row >
                        <div className="col col-sm-4 text-end" >
                            <EditableCell
                                type="number"
                                placeholder="Hind"
                                defaultValue={item.price}
                                isMoney={true}
                                required={true}
                                ref={priceRef}
                                isEditing={isEditing}></EditableCell></div>
                        <div className="col col-sm-2 text-end">
                            <EditableCell id="test3"
                                type="text"
                                placeholder="Ühik"
                                ref={unitRef}
                                defaultValue={item.unit} isEditing={isEditing}></EditableCell></div>
                        <div className="col col-sm-3 text-end">
                            <EditableCell id="test4"
                                type="number"
                                placeholder="Kogus"
                                ref={quantityRef}
                                defaultValue={item.quantity} isEditing={isEditing}> </EditableCell> </div>
                        <div className="col col-sm-3 text-end">
                            <EditableCell id="test5"
                                type="number"
                                defaultValue={item.discount}
                                step="5"
                                ref={discountRef}
                                placeholder="Allahindlus" isPercentage={true} isEditing={isEditing}>
                            </EditableCell></div>

                    </Row>
                </td>
                {isEditing&&<td style={{width:"15px",paddingRight:"5px"}} className="text-center align-middle text-nowrap">
                    <Link  color="link" to="#" size="sm" onClick={() => removeWorkItem(index)}>
                        <X size={36}></X>
                    </Link>
                </td>}
            </tr>
        )}
    </Draggable>)
})



export const ProductsComponent = ({workId,offerId,isReadOnly}) => {
     
    const url = !offerId ? `work/${workId}/productsorservices` : `work/offer/${offerId}/productsorservices` ;
    const [data, setData] = useGet({ url: url });
    const [changeData] = usePut({ url: url });
    const [childKey, setChildKey] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const discountRef = useRef(); 
    const alert = useAlert();
    const [dropdownOpen, setOpen] = useState(false); 
    const toggle = () => setOpen(!dropdownOpen);

    const commitChanges = (newData) => {
       
        const applyChanges = (changedData) => {
            unstable_batchedUpdates(() => {
                setData(changedData);
                setIsEditing(false);
                alert.show(changedData === null ? 'Muudatused tühistatud.' : 'Tooted ja teenused salvestatud.');
            });
        }
        if (newData === null) {
            applyChanges(null);
        }
        else changeData(newData, (response) => {
            applyChanges(response);
        }); 
    }

    const currentDataRows = () =>{
        return rowRef.current.filter(row => row !== null).map(row => row.getValue());
    };

    const onDragEnd = e => {
      
        if (!e.destination) {
            return;
        } 
        var currentData = currentDataRows();
        const sorted = reorder(currentData, e.source.index, e.destination.index);
        unstable_batchedUpdates(() => {
            setChildKey(childKey + 1);
            setData(sorted);
        }); 
    };

    const reorder = (list, startIndex, endIndex) => {
        
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // Remove WorkItem
    const removeWorkItem = id => { 
        var newData = [...data];
        newData.splice(id, 1); 
        unstable_batchedUpdates(() => {
            setChildKey(childKey + 1);
            setData(newData);
        }); 
    }

    const handleAddWorkItem = (count) => {
        if(!count) count = 0;
        for (var i = 0; i <= count; i++)
        {
            data.push({ id: 0, code: '', discount: 0, name: '', price: 0, quantity: 1, unit: 'tk' })
        }
        setData([...data]);
    };
   
     
    const rowRef = useRef([]);

    if(!isEditing && data && data.length === 0)
    {
        return (
           <Row className="align-items-center justify-content-between"> 
            <Col lg={12} className="ps-lg-4 my-5 text-center text-lg-left"> 
              <p >Toodete ja teenuste lisamine.</p>
              <Button color="primary" 
                      disabled={isReadOnly}
                      size="sm"
                      onClick={() => { 
                          setIsEditing(true);
                           if (data.length === 0) {
                               handleAddWorkItem();
                           }
                      }}
                    ><Pencil></Pencil> Alusta</Button>
            </Col>
          </Row>);
    }
     
    return (
        <Fragment>
            {!data ? (<Loader></Loader>) : ( 
                <Fragment>
                    <DragDropContext onDragEnd={onDragEnd}  >
                        <Droppable droppableId="Table">
                            {provided => (
                                <div className='table-responsive' {...provided.droppableProps} ref={provided.innerRef}>
                                    <table className="table table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap" >
                                        <thead>
                                            <tr className="fs--1">
                                                <th scope="col" className='col-2'>Kood</th>
                                                <th scope="col" className='col-4' style={{minWidth:"225px"}}>Nimi</th>
                                                <th scope="col" className={isEditing?'col-6':'col-7'} style={{minWidth:"400px"}}>
                                                    <Row>
                                                        <div className="col col-sm-4 text-end" ><label className="mb-0" >Hind</label> </div>
                                                        <div className="col col-sm-2  text-end"> <label className="mb-0">Ühik</label></div>
                                                        <div className="col col-sm-3  text-end"><label className="mb-0">Kogus</label></div>
                                                        <div className="col col-sm-3 text-end"><label className="mb-0">Allahindlus</label></div>
                                                    </Row>
                                                </th>
                                              {isEditing&&<th scope="col" style={{width:"15px"}}></th>} 
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {data.map((item, index) => (
                                                <DataItemRow ref={el => rowRef.current[index] = el} key={'dataItemRow' + index + childKey} isEditing={isEditing} index={index} item={item} removeWorkItem={(id) => {
                                                    removeWorkItem(id);
                                                }}></DataItemRow>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                </div>

                            )}
                        </Droppable>
                    </DragDropContext>
                    <ApplyDiscount ref={discountRef} rowRef={rowRef}></ApplyDiscount> 
                    <div className='d-flex align-items-end flex-column mt-2' style={{ flex: 2 }}>  
                     {!isEditing?
                      <ButtonGroup className="mt-auto ms-auto me-2 mb-2"> 
                        <Button className='mt-2'
                                color="primary"
                                size="sm"
                                disabled={isReadOnly}
                                onClick={() => { 
                                    setIsEditing(true);
                                    if (data.length === 0) {
                                        handleAddWorkItem();
                                    }
                                }}
                            ><Pencil></Pencil> Muuda tooteid ja teenuseid</Button> 
                        </ButtonGroup>:
                        <ButtonDropdown className="mt-auto ms-auto mb-2"  size="sm" color="primary" isOpen={dropdownOpen} toggle={toggle}> 
                          <Button
                                color="primary"
                                outline
                                size="sm"
                                onClick={() => { 
                                    handleAddWorkItem();
                                }}
                            ><PlusCircle></PlusCircle> Lisa uus rida
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => { 
                                    commitChanges(currentDataRows());
                                }}
                            ><Check></Check> Salvesta
                            </Button>
                                <DropdownToggle  split color="primary">
                                </DropdownToggle>
                                <DropdownMenu> 
                                    <DropdownItem onClick={() => handleAddWorkItem(4)} ><PlusCircle></PlusCircle> Lisa mitu rida</DropdownItem>
                                    <DropdownItem onClick={()=> discountRef.current.apply() } >  <Percent></Percent> Määra soodustus</DropdownItem>
                                    <DropdownItem onClick={()=> commitChanges(null)} > <XCircle></XCircle> Katkesta</DropdownItem> 
                                </DropdownMenu>
                            </ButtonDropdown>
                            }  
                    </div>
                </Fragment> 
            )}
        </Fragment>
    )
}

import React, { Fragment, useState } from 'react';
import {Resources} from '../_shared/Ressursid'; 
import { Link } from 'react-router-dom';
import { ArrowClockwise, EnvelopeCheck } from 'react-bootstrap-icons';
import moment from 'moment';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ClientRemoteSelect from '../Klient/KlientRemoteSelect';
import VehicleRemoteSelect from '../Soiduk/SoidukRemoteSelect';
 
const actionColumn = {
  dataField: '',
  headerClasses: 'border-0',
  text: '',
  classes: 'border-0 py-2 align-middle', 
  align: 'right'
};

const columns = [
  {
    dataField: 'workNr',
    text: 'Töö',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle', 
    sort: true,
    formatter: (dataField, {  workNr }) => {
      return (
        <Link to={'/too/'+workNr}>
           <h5 className="mb-0 fs--1">Töö nr. {workNr}</h5> 
        </Link> 
      );
    }
  },
   {
    dataField: 'detailsOfInvoice',
    headerClasses: 'border-0',
    text: 'Arve/Pakkumised',
    classes: 'border-0 py-2 align-middle  text-nowrap',
    formatter:(dataField,{workNr,detailsOfOffers,status})=>{
        
      return ( 
        <div>
          <div> 
            {dataField?<Fragment>   
            {dataField.sentOn&&<EnvelopeCheck title={moment(dataField.sentOn).locale('et').format('D MMM yyyy HH:mm')} className='me-1' color='Green' size={16}></EnvelopeCheck>} 
          
              <Link to={'/too/'+workNr}><span className='fw-semi-bold text-success text-700 mb-0 fs--1 me-1'>Arve nr: {dataField.invoiceNr}</span></Link>
              <span className={dataField.isOverDue?'text-danger fw-bold':''}>({dataField.isPaid?'makstud': ('maksmata'+(dataField.isOverDue?'!':'')) })</span>
            </Fragment>:
            <Fragment>
               {status==='töös'&&<span className='text-primary fw-bold'><ArrowClockwise size={16}></ArrowClockwise> Töös</span>}
               {status==='alustamata'&&<span className='fw-semi-bold  text-400'>Alustamata</span>}
            </Fragment>}
            </div> 
            {
                detailsOfOffers?.map(offer=>{
                 return (
                  <div key={"detailsOfOffers"+offer.offerNr}>
                   {!offer.estimateNumber&&<ArrowClockwise className='me-1' size={16}></ArrowClockwise>}
                   <Link to={'/too/'+workNr+'/pakkumine/'+offer.offerNr} >
                    <span className="fw-semi-bold text-700 mb-0 fs--1 me-1">
                      {offer.sentOn&&<EnvelopeCheck title={moment(offer.sentOn).locale('et').format('D MMM yyyy HH:mm')} className='me-1' color='Green' size={16}></EnvelopeCheck>}Pakkumine nr: {offer?.offerNr}
                      </span>
                  </Link>
                  </div>
                 )
              })
            } 
        </div>
      )
    }
  },  
  {
    dataField: 'startedOn',
    headerClasses: 'border-0',
    text: 'Algus kp.',
    classes: 'border-0 py-2 align-middle  text-nowrap'
  },
 
  {
    dataField: 'clientId',
    headerClasses: 'border-0',
    text: 'Klient',
    classes: 'border-0 py-2 align-middle',
    formatter: (dataField, {  clientName,clientId }) => {
      return (
        <Link to={'/klient/'+clientId} >
          <h5  className="mb-0 fs--1">{clientName}</h5>
        </Link>
      );
    }
  },
  {
    dataField: 'vehicleId',
    headerClasses: 'border-0',
    text: 'Sõiduk',
    classes: 'border-0 py-2 align-middle  ',
    formatter: (dataField, {  regNr,vehicleId }) => {
      return (
        <Link to={'/soiduk/'+vehicleId} >
             <h5 className="mb-0 fs--1">{regNr}</h5>
        </Link>
      );
    }
  }, 
  
  {
    dataField: 'mechanicNames',
    headerClasses: 'border-0',
    text: 'Teostajad',
    classes: 'border-0 py-2 align-middle  text-nowrap'
  },
  {
    dataField: 'notes',
    headerClasses: 'border-0',
    text: 'Kirjeldus',
    classes: 'border-0 py-2 align-middle  text-nowrap',
    formatter: (dataField, {  notes }) => {
      return (
        <span title={notes} className="d-inline-block text-truncate" style={{maxWidth:  '200px',marginBottom:"-5px"}} >
          {notes}
        </span>
      );
    }
  }
  ,
   actionColumn 
];

const childrenOfRow = [
  
]
const Tood = () => {
 
  const [client,setClient] = useState({value:''});
  const [vehicle,setVehicle] = useState({value:''});
  const [status,setStatus] = useState('all');
  const [invoiceFrom,setInvoiceFrom] = useState('');
  const [invoiceTo,setInvoiceTo] = useState('');
  const filter = {
    status,
    invoiceFrom,
    invoiceTo,
    clientId:client?.value??'',
    vehicleId:vehicle?.value??''
  }
   
  return (
    <Row>
     
      <Col   lg={2} className="order-lg-1 mb-2">
        <Card>
          <CardHeader><h5 className="fw-semi-bold">Filtrid</h5></CardHeader>
          <CardBody className="bg-light"> 
            <h6>Staatus </h6>
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='all'}
                name='all'
                value='all'   onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
                Kõik
              </Label>
              <div className="form-text mt-0"> 
              </div>
            </FormGroup>
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='blank'}
                name='blank'
                value='blank'
                onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
              Alustamata
              </Label>
              <div className="form-text mt-0"> 
              Täitmata, ei ole lisatud tooteid/teenuseid ega ka pakkumisi
              </div>
            </FormGroup>
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='inoffer'}
                name='inoffer'
                value='inoffer'  onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
              Pakkumises
              </Label>
              <div className="form-text mt-0"> 
                Sisaldab vaid pakkumisi või pakkumist mis on koostamisel või väljastatud
              </div>
            </FormGroup>
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='inwork'}
                name='inwork'
                value='inwork' onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
              Töös
              </Label>
              <div className="form-text mt-0"> 
              Tööle on lisatud tooteid/teenuseid, arve veel pole väljastatud
              </div>
            </FormGroup>
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='issued'}
                name='issued'
                value='issued'  onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
              Valmis
              </Label>
              <div className="form-text mt-0"> 
              Tööle on arve väljastatud 
              </div>
            </FormGroup>
           
            <FormGroup check>
            <Input
                type='radio'
                id='defaultRadio' 
                checked={filter.status ==='overdue'}
                name='overdue'
                value='overdue'  onChange={({ target }) => {
                  setStatus(target.value);
                }}
              />
              <Label className="fw-semi-bold" check>
              Ületähtajalised
              </Label>
              <div className="form-text mt-0"> 
              Kuva üle tähtaja arvega tööd 
              </div>
            </FormGroup>
            <div className="border-dashed border-bottom my-3"></div>
            <h6>Arve kuupäev </h6>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    alates
                  </Label>
                  <Input bsSize='sm'
                    id="exampleEmail"
                    name="email" 
                    type="date"
                    value={filter.invoiceFrom}  onChange={({ target }) => {
                      setInvoiceFrom(target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    kuni
                  </Label>
                  <Input
                 bsSize='sm'
                    id="examplePassword"
                    name="password"
                    placeholder="password placeholder"
                    type="date"
                    value={filter.invoiceTo}
                    onChange={({ target }) => {
                      setInvoiceTo(target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="border-dashed border-bottom my-3"></div>
            <FormGroup className="mb-3">
              <Label>Klient</Label>
              <FormGroup>
                    <ClientRemoteSelect
                     
                      placeholder="Vali klient .."
                      value={client} 
                      onChange={(target) => { 
                        setClient(target);
                      }}
                    />
                  </FormGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label>Sõiduk</Label>
              <VehicleRemoteSelect
                      placeholder="Vali sõiduk .."
                      value={vehicle} 
                      onChange={(target) => { 
                        setVehicle(target);
                      }}  />
               </FormGroup>
           
            <div className="border-dashed border-bottom my-3"></div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={10}>
      <Resources
         searchFilter={filter}
         columns={columns}
         childrenOfRow={childrenOfRow}
         keyField="workNr"
         resourceName={'jobs'}
         resourceLink={'too'}
         resourcePath="tood"
         resourceDisplayName={'Tööd'} 
         actionColumn={actionColumn}
      />
      </Col>
    </Row>
  );
};
export default Tood;
import React, { Fragment } from 'react'; 
import classNames from 'classnames';
import {   PencilFill} from 'react-bootstrap-icons';
import {
  CardHeader,
  Col, 
  Row,
  Card,CardBody,CardFooter ,Button
} from 'reactstrap';
import Loader from '../_shared/Loader';
import {   useGet } from '../../services/restClient';
import { useNavigate } from 'react-router-dom';
const OptionsRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col   sm={4}>
      <p
        className={classNames('font-weight-bold', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}
      </p>
    </Col>
    <Col>{children}</Col>
  </Row>
);


  
const ReadOnlyCardHeader = ({isEditing}) =>{
  
    let navigate = useNavigate(); 
     
    return (
      <Fragment> 
      <CardHeader>
            <Row>
          <Col>
          <h5 className="mb-2">
          <span>Seaded</span>
         </h5>
          </Col>
          <Col xs="auto" >
          <Button
                onClick={() =>{
                    if(!isEditing){
                      navigate('/seaded-muuda/');
                    }
                    else{
                      navigate('/seaded');
                    }
                } }
                color="falcon-default"
                size="sm">
                {!isEditing ? ('Muuda') : ('Katkesta')}
                <PencilFill className="ms-1 fs--2" ></PencilFill>
              </Button>
          </Col>
        </Row> 
        </CardHeader>
      </Fragment>
      
      );
}
 

const ReadOnlyCardBody = ({ requisites,invoice,estimate }) => {
   
  return (
    <Fragment>
      <Row> 
        <Col lg className="col-md-12">
          <h6 className="font-weight-bold ls mb-3 text-uppercase">Ettevõte</h6>
          <OptionsRow title="Nimi">{requisites.name}</OptionsRow>
          <OptionsRow title="Telefon">{requisites.phone}</OptionsRow>
          <OptionsRow title="Aadress">{requisites.address}</OptionsRow>
          <OptionsRow title="Email">{requisites.email  } </OptionsRow>
          <OptionsRow title="Pangakonto">{requisites.bankAccount  }</OptionsRow>
          <OptionsRow title="Registrikood">{requisites.regNr }</OptionsRow>
          <OptionsRow title="KMKR nr.">{requisites.kmkr  }</OptionsRow>
        </Col>  
        </Row>
        <hr></hr>
        <Row>
        <Col lg className="col-md-12">
          <h6 className="font-weight-bold ls mb-3 text-uppercase">Arve seaded</h6>
          <OptionsRow title="Viivis">{invoice.surCharge}</OptionsRow> 
          <OptionsRow title="Allkirjakoht">{invoice.signatureLine?'Jah':'Ei'}</OptionsRow>
          <OptionsRow title="Lahtiütlus">
              <div   style={{ whiteSpace: "pre-wrap" }}>
               {invoice.disclaimer} 
                </div> 
            </OptionsRow>
          <OptionsRow title="Emaili sisu">
            <div   style={{ whiteSpace: "pre-wrap" }}>
                 {invoice.emailContent} 
            </div> 
          </OptionsRow>
        </Col>  
      </Row>
      <hr></hr>
      <Row>
      <Col lg className="col-md-12">
          <h6 className="font-weight-bold ls mb-3 text-uppercase">Pakkumise seaded</h6> 
          <OptionsRow title="Emaili sisu">
            <div   style={{ whiteSpace: "pre-wrap" }}>
                 {estimate.emailContent} 
            </div> 
         </OptionsRow>
        </Col> 
      </Row>
    </Fragment>
  );

}

 
//display
const OptionsCard = (props) => { 
    const [options] = useGet({ url:`options`});  
  
     
    return (
      <Fragment> 
      {!options ? (
        <Loader />
        ) : ( <Card className="mb-3">
        <ReadOnlyCardHeader { ...{ isEditing:false, requisites:options.requisites}}/>
        <CardBody className="bg-light border-top">
          <ReadOnlyCardBody { ...{ requisites:options.requisites,invoice:options.pricing.invoice,estimate:options.pricing.estimate}}/>
        </CardBody>
        <CardFooter className="border-top"> 
        </CardFooter>
      </Card>) }
    </Fragment> 
    ); 
}

export default OptionsCard;
export {  
    ReadOnlyCardHeader
}
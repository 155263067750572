import React, {Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router';  
import {  useNavigate } from "react-router-dom";
import ClientRemoteSelect from "../Klient/KlientRemoteSelect";
import VehicleRemoteSelect, {
  formatVehicle,
} from "../Soiduk/SoidukRemoteSelect";
import {   ReadOnlyFooter } from './Too';
import {NewCardHeader} from './TooUus';
import EmployeeRemoteSelect from "../Tootaja/TootajaRemoteSelect";
import { loadClientVehicles } from "../Klient/KlientSoidukid";
import { useGet, usePut } from '../../services/restClient';
import { useAlert } from '@blaumaus/react-alert';
import {
  Input, Row, Col,
  Form,
  Button,
  FormGroup,
  Label, 
  CardBody,
  ButtonGroup,Card,CardFooter 
} from "reactstrap";
import { Check,Pencil } from "react-bootstrap-icons";
import Select from "react-select";
import Loader from '../_shared/Loader'; 
import FormGroupInput from "../_shared/FormGroupInput";
import { useErrorStatus } from '../../UnhandledErrorHandler'; 
  

const EditableCardBody = ({job,changeJob, handleSuccessfulSave, handleCancel,singleColumn=false}) =>  {
   
  const [onlyClientVehicles, setOnlyClientVehicles] = useState(job?.onlyClientVehicles??true);
  const [clientVehicles, setClientVehicles] = useState([]);
  const [client, setClient] = useState(job?.client); 
  const [vehicle, setVehicle] = useState(job?.vehicle);  
  const [isForClient, setIsForClient] = useState((!job || job.client)?"jah":"ei");
  const [description, setDescription] = useState(job?.notes); 
  const [assignedTo, setAssignedTo] = useState(job?.assignedTo);
  const { setError } = useErrorStatus();
  const [startWithOffer, setStartWithOffer] = useState(false);
   
  useEffect(() => {
    if(onlyClientVehicles && client)
    {  
        loadClientVehicles({
          id:client.value,
          onSuccess: (vehicles) => { 
            if (vehicles) { 
             
              setClientVehicles(
                vehicles.map((vehicle) => {
                  return {
                    label: formatVehicle(vehicle),
                    value: vehicle.id
                  };
                })
              );
            }
    
          },
          onError:setError,
        });
    }
  }, [client,onlyClientVehicles,setError]);
 
  const clientVehiclesPlaceHolder = clientVehicles && clientVehicles.length > 0 ? "Vali sõiduk ..":"Puuduvad sõidukid."
  
  const handleClientSelected = (opts) => {
    if(!opts)  return;
    setClient(opts);
    if(onlyClientVehicles)
    {
      setVehicle(null); 
    }
  };
  let navigate = useNavigate();
  const alert = useAlert();

  if(!handleSuccessfulSave)
  {
     handleSuccessfulSave =(response)=>{
     
      var url ='/too/' + Number(response.workId).toString(); 
      if(response.offerId) url = url+'/pakkumine/'+response.offerId;
      navigate(url);
    }
  }
  if(!handleCancel)
  {
    handleCancel = ()=>{
      navigate('/too/'+job.id); 
    }
  }
  const changeResourceHandler = e => {
     
      e.preventDefault(); 
      const body={ clientId:client?.value, vehicleId : vehicle?.value, description,assignedTo: assignedTo?.map(x=>x.value)  };
      if(!job)
      {
        body['startWithOffer']= startWithOffer;
      }
      changeJob(body,(response) => {

        handleSuccessfulSave(response);
        if(job?.id >0)
        { 
          alert.show('Töö muudetud.')
        }
        else  
        {
          alert.show('Uus töö lisatud.')
        }
     });
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
       
      const form = event.target.form;
      const index = [...form].indexOf(event.target); 
      for (var i = index+1; i < form.elements.length; i++) {
        if (form.elements[i].nodeName === "INPUT" && form.elements[i].type === "text") {
          
          form.elements[i].focus();  
          break;
        }
      }
    }
  }; 

  

  return ( 
          <Form onSubmit={changeResourceHandler}>
            <Row>
              <Col xs="12" md="12" lg={singleColumn?12:6} > 
                <FormGroup>
                  <Label for="selectOption">Vali kellele tööd tehakse</Label>
                  <div>
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="exampleCustomRadio22"
                        name="customRadio22" 
                        value="ei"
                        checked={isForClient === "ei"}
                        onChange={({ target }) => {
                          setIsForClient(target.value);
                          setClient(null);
                          setClientVehicles([]);
                          setOnlyClientVehicles(false);
                        }}
                        inline="true"
                    ></Input>
                    <strong>eraisikule</strong>
                    </FormGroup> 
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="exampleCustomRadio32"
                        name="customRadio22" 
                        value="jah"
                        checked={isForClient === "jah"}
                        onChange={({ target }) => setIsForClient(target.value)}
                        inline="true"
                      ></Input>
                      <strong>kliendile</strong>
                     </FormGroup> 
                  </div>
                </FormGroup>
              
                {isForClient === "jah" && (
                  <FormGroup>
                    <ClientRemoteSelect
                      onKeyDown={handleEnter}
                      placeholder="Kirjuta ja leia klient .."
                      value={client} 
                      onChange={(opts) => {
                        handleClientSelected(opts);
                      }}
                    />
                  </FormGroup>
                )}
                
                <FormGroup>
                  <Label className="me-2" for="exampleSelect2">
                    Sõiduk
                  </Label>
                  {client && (
                     <FormGroup switch>
                      <Input
                       inline="true"
                       type="switch"
                       id="customSwitch"
                       name="customSwitch"
                       checked={onlyClientVehicles}
                       onChange={({ target }) => { 
                         setOnlyClientVehicles(target.checked); 
                       }} 
                     ></Input>
                      <small className="form-text mt-0">ainult kliendi sõidukid</small>
                     </FormGroup> 
                  )} 
                   {!onlyClientVehicles ? (
                    <VehicleRemoteSelect
                      onKeyDown={handleEnter}
                      placeholder="Kirjuta ja leia sõiduk .."
                      value={vehicle} 
                      onChange={(opts) => {
                        setVehicle(opts);
                      }}
                    />
                  ):<Select
                  id="clentVeh" 
                  styles={ { menu: styles => ({ ...styles, zIndex: 999 })}}
                  noOptionsMessage={()=>{ return "valikud puuduvad.";}}
                  placeholder={ clientVehiclesPlaceHolder }
                  isClearable={true}
                  isSearchable={false} 
                  value={ vehicle}
                  name="clientVeh" onChange={(opts) => { 
                    setVehicle(opts);
                  }}
                  options={clientVehicles}
                />}
                </FormGroup> 
               {!job&&<FormGroup> 
                <FormGroup switch>
                <Input
                     inline="true"
                     type="switch"
                     cursor="pointer"
                     id="startWithSwitch"
                     name="startWithSwitch"
                     checked={startWithOffer}
                     onChange={({ target }) => { 
                       setStartWithOffer(target.checked); 
                     }} 
                  ></Input>
                  <label> Alusta pakkumisega</label>
                </FormGroup>
              
                   </FormGroup>} 
                <FormGroup>
                    <Label for="exampleSelect3">Teostajad</Label>
                    <EmployeeRemoteSelect
                      placeholder="Vali teostaja .."
                      value={assignedTo} 
                      onChange={(opts) => {
                        if(opts)
                        {
                          setAssignedTo(opts);
                        }
                        else{
                          setAssignedTo([]);
                        }
                        
                      }}
                    />
                  </FormGroup>
              </Col>
              <Col xs="12" md="12" lg={singleColumn?12:6}>
                <FormGroupInput
                  id="description"
                  label="Kirjeldus"
                  type="textarea"
                  rows="8" value={description}
                  onChange={({ target }) => setDescription(target.value)}
                />
              </Col>
            </Row>  
              <hr></hr> 
            <Row>
              <Col className="text-end">
                <ButtonGroup>
                  <Button hidden={!job} size="sm" outline color="primary" onClick={handleCancel} >  <Pencil></Pencil> Katkesta
                </Button>
                <Button color=" btn-primary" size="sm" type="submit">
                  <Check size={20} className="me-1"></Check>
                  Salvesta
                </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Form> 
  );
}

//edit
const WorkEditCard = () => {
    const { id } = useParams();
    if (!id) throw new Error('id required');
    
    const [job] = useGet({ url:`jobs/${id}`});  
    const [changeJob] = usePut({ url:`jobs/${id}`});
   
    return (
      <Fragment> 
      {!job ? (
      <Loader />
      ) : ( <Card className="mb-3">
        <NewCardHeader
                job={job} />
          <CardBody className="bg-light border-top"> 
          <EditableCardBody { ...{ job,changeJob}} />
          </CardBody>
          <CardFooter className="border-top">
          <ReadOnlyFooter job={job} />
          </CardFooter>
          </Card>)
          }
        </Fragment>  
    );
}
 
export default WorkEditCard;
export {
    EditableCardBody
}

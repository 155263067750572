import { useAlert } from '@blaumaus/react-alert'; 
import { useDelete } from '../../services/restClient';
import { ConfirmModal } from './ConfirmModal';

export const ConfirmDeleteModal = ( {isOpen,url, onRequestingClose,onConfirm,deleteBody})=>{
 
    const [deleteResource] = useDelete({ url:url}); 
    const alert = useAlert(); 
    const deleteSelected = () => {

        var body = deleteBody? deleteBody():{};
        deleteResource(body,(response)=>{  
          if(onConfirm) onConfirm(response); 
          alert.show('Andmed kustutatud.');
        }) 
      };

    return ( 
    <ConfirmModal 
        isOpen={isOpen} 
        message={"Kindel, et soovid andmed kustutada? Tagasi neid ei saa."} 
        onRequestingClose={onRequestingClose}
        onConfirm={deleteSelected}>
        </ConfirmModal>  )
}
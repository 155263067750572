import {Fragment, useRef, useState} from 'react'; 
import {
    Col,
    Row ,Button
} from 'reactstrap'; 
import { Link } from 'react-router-dom'; 
import {  usePut,useGet } from '../../services/restClient';
import 'moment/locale/et'; 
import { unstable_batchedUpdates } from 'react-dom';
import { EditableCardBody } from './TooMuuda';
import { FileText} from 'react-bootstrap-icons';

const NoData = ({ children }) => {
    return (<p className="ms-2 mb-1 fs--1 font-italic text-400">{children}</p>);
}

  
 export  const WorkSubjectComponent =  ({id,isReadOnly})=> {
 
    const [work,setWork] = useGet({ url: `work/subject/${id}` }); 
    const statusRef = useRef();
    const [isEditing,setIsEditing] = useState(false);
    const [changeJob] = usePut({ url:`jobs/${id}`});
    const [workDetails,setWorkDetails] = useGet({  url: (isEditing?`jobs/${id}`:null) });  
      
    if(isEditing && workDetails) return (
        <EditableCardBody { ...{ 
            job: workDetails,
            changeJob,
            singleColumn:true,
            handleCancel:()=>{
                unstable_batchedUpdates(()=>{ 
                    setIsEditing(false);
                    setWorkDetails(null);
                    setWork(null);
                }); 
            },
            handleSuccessfulSave:(response)=>{
                unstable_batchedUpdates(()=>{ 
                    setIsEditing(false);
                    setWorkDetails(null);
                    setWork(null);
                }); 
            }
        }}></EditableCardBody>
    ) 
    return ( 
      <Fragment>
            {work&&  <Fragment>
                
                <Row>
                    <Col xs={12} sm={6} md={6} lg={12} className="mb-lg-0">
                        
                        <h6 className="mt-2">Teostajad</h6>
                        {work.mechanics ?
                            (<div className="ms-2 fs--1">
                                {work.mechanics}
                            </div>) : (<NoData>puuduvad</NoData>)}
 
                         </Col> 
                         <Col xs={12} sm={6} md={6} lg={12} className="mb-lg-0">
                         {work.clientId ?
                                <Fragment>
                                    <h6 className="mb-2 mt-2"> <Link to={'/klient/' + work.clientId}>{work.clientName}</Link></h6>

                                    <p className="ms-2 mb-0 fs--1">
                                        Aadress: {work.clientAddress}
                                    </p>
                                    <p className="ms-2 mb-0 fs--1">
                                        Email: <a href={work.clientEmail}>{work.clientEmail}</a>
                                    </p>
                                    <p className="ms-2 mb-2 fs--1">
                                        Telefon: <a href={'tel:' + work.clientPhone}>{work.clientPhone}</a>
                                    </p>
                                </Fragment>:
                                <Fragment>
                                <h6 className="mb-1 mt-2"> Klient:  </h6>
                                <p className="ms-2 mb-1 fs--1">
                                    eraisik
                                </p>
                                </Fragment>}
                            {work.vehicleId && <Fragment>
                                <h6 className=" ms-0 mb-1"> <Link to={'/soiduk/' + work.vehicleId}>{work.vehicleProducer} {work.vehicleModel}</Link></h6>
                                <p className="ms-2 mb-0 fs--1">
                                    Vin: {work.vehicleVin}  
                                </p>
                                <p className="ms-2 mb-0 fs--1">
                                   Reg nr: {work.vehicleRegNr}
                                </p>
                            </Fragment>}
                            <h6 className="mb-1 mt-2">Kirjeldus</h6>
                        {work.notes ?
                            (<div className="ms-2 fs--1" style={{ whiteSpace: "pre-wrap",maxHeight:"450px",overflowY:"auto" }}>
                                {work.notes}
                            </div>) : (<NoData>puudub</NoData>)}
                    </Col>  
                </Row> 
                {statusRef.current?.getStatus() !== 'Valmis' &&  <Row>
                    <Col  className="text-end mb-2 mt-2"> 
                       <Button size="sm" disabled={isReadOnly} outline color="primary" onClick={()=> setIsEditing(true)}>
                                    <FileText></FileText> Muuda
                        </Button>
                    </Col>
                </Row>} 
            </Fragment>} 
        </Fragment> 
    );
}

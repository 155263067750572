import React from 'react';
import AsyncSelect from 'react-select/async';
import { page } from '../../services/restClient'; 
import { useErrorStatus } from '../../UnhandledErrorHandler';

const EmployeeRemoteSelect =({value,placeholder,onChange})=>{
  //todo, option to add new with dialog when empty
  const { setError } = useErrorStatus();
    return (
        <AsyncSelect
        name="tootajadSelect"
        isMulti
        styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          className="basic-multi-select"
          classNamePrefix="select"
        placeholder = {placeholder}
        onChange={(opts) => {
            onChange(opts);
        }} 
        defaultOptions
        loadingMessage={() => {
          return "Laen töötaja andmeid";
        }}
        value={value}
        loadOptions={ (inputValue)=>{
          return employeeRemoteData(inputValue,setError)
        }}
      />
    )
}
 
const employeeRemoteData = (inputValue,setError) =>

  new Promise((resolve) => {
  
    var searchText = '';
    if(inputValue) searchText = inputValue+'*';
    
    if (!inputValue) {
      resolve([]);
      return;
    }
    page({
      resourceName:'employees',
      searchText,
      whenReady:(items)=>{
        resolve(items.map((employee)=>{
          return {
            label:employee.firstName,
            value:employee.id
          };
        }));
      },
      setError
    })

  });
 export default EmployeeRemoteSelect;
 import {
    Button,Modal,ModalBody,ModalFooter
} from 'reactstrap';

export const ConfirmModal = ( {isOpen, message,onRequestingClose,onConfirm})=>{

     
    return (<Modal  centered isOpen={isOpen}>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => { 
              onRequestingClose();
            }}>
            Sulge
          </Button>
          <Button
            color="primary"
            onClick={ ()=>{
              onRequestingClose();
              onConfirm(); 
            }}>
            Jah
          </Button>
        </ModalFooter>
        </Modal>)
}
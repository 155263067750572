import React from 'react';
import { Card, CardBody } from 'reactstrap';

const PageNotFound404 =  () => {
 
  return (
    
    <Card>
      <CardBody className="p-5">
        <div className="text-center display-1 text-300 fs-error">404</div>
        <p className="text-center lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Lehekülge ei leitud
        </p> 
      </CardBody>
    </Card>
  );
};

export default PageNotFound404;

const prod = {
    url: {
        API_SCHEME: 'https',
        API_HOST: 'carcareco.app',
        API_PORT: '5567'
    }
};
const dev = {
    url: {
        API_SCHEME: 'http',
        API_HOST: window.location.hostname,
        API_PORT: '5567'
    }
};
export const options = (process.env.NODE_ENV === 'development') ? dev : prod;
import { Fragment, useState,forwardRef,useImperativeHandle,useRef } from 'react'; 
import { unstable_batchedUpdates } from 'react-dom';
import { 
    Row, Button,Col,Input,Form,ButtonDropdown, DropdownMenu, DropdownItem,DropdownToggle,Badge,Modal,ModalBody,ModalHeader,ModalFooter, FormGroup
} from 'reactstrap';  
import { useGet, usePost,downloadPdf, usePut } from '../../services/restClient';
import { Trash,Wrench,Check,CheckCircle,InfoCircle,FileText, Send, Download, SendCheck, Exclamation,PlusCircle} from 'react-bootstrap-icons';
import Moment from 'moment'; 
import {IssuePricing,CancelPricing,SendPricing  } from './PricingActions' 
import { useErrorStatus } from '../../UnhandledErrorHandler'; 
import 'moment/locale/et';   
import { useNavigate} from 'react-router-dom'
import { useAlert } from '@blaumaus/react-alert';
import { ConfirmDeleteModal } from '../_shared/ConfirmDeleteModal';

const NoData = ({ children }) => {
    return (<p className="ms-2 mb-1 fs--1 font-italic text-400">{children}</p>);
}
const NewOfferDialog = forwardRef((props,ref)=>{
    const {setWorkStatus,workid} = props;
    const [description,setDescription] = useState();
    const [isOpen,setIsOpen] = useState(false);
    const [postOffer] = usePost({ resource:`work/${workid}/offers`});
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({ 
        show(){
            setIsOpen(true);
        }
    }));
    return (
        <Fragment>
              <Modal   centered isOpen={isOpen}>
           <ModalHeader>Uus pakkumine</ModalHeader>
           <ModalBody>
               <Input
               id="description"
               label="Kirjeldus"
               type="textarea"
               rows="12" value={description}
               onChange={({ target }) => setDescription(target.value)}
               />
           </ModalBody>
           <ModalFooter>
           <Button
               onClick={() => { 
                setIsOpen(false);
               }}>
               Katkesta
           </Button>
           <Button
               color="primary"
               onClick={() => { 
                   postOffer(description,(response)=>{
                       
                       unstable_batchedUpdates(()=>{
                           setIsOpen(false);
                           setWorkStatus(null);
                           navigate(`/too/${workid}/pakkumine/${response.number}`)
                       }); 
                   });
                }}>
               Salvesta
           </Button>
           </ModalFooter>
       </Modal>  
        </Fragment> 
    )
})

 


export const WorkStateComponent=({workid,workStatus,setWorkStatus,pdfPath})=>{
 
    const [invoice,setInvoice] = useGet({ url: `work/${workid}/invoice` });
    const [copyWork,] = usePost({ resource: `work/${workid}/makecopy` })   
    const [paid] = usePut({url:`work/${workid}/invoice/paid`});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dropdownOpen, setOpen] = useState(false);  
    const toggle = () => setOpen(!dropdownOpen);
    const issueRef = useRef();
    const cancelRef = useRef();
    const sendRef = useRef();
    const offerRef = useRef();
    const { setError} = useErrorStatus();
    var navigate = useNavigate();
    const alert = useAlert();
   
    const newWithSameData =()=>{
        copyWork( {
            workId:workid
        }, (response)=>{
            
            navigate('/too/'+response);
            alert.show('Koopia tehtud');
        })
    }

    const setInvoicePaid=(isPaid)=>{ 
        paid( isPaid,
            (response)=>{
                setInvoice(null);
                alert.show('Arve märgiti '+(isPaid?'makstuks':'mitte makstuks'));
            })
    }

    if(!invoice) return (<Fragment></Fragment>);
    return (<Form className="mb-2 ms-0 mt-0 me-0">
        <SendPricing ref={sendRef} id={workid} setPricing={setInvoice} clientName={invoice.partyName}  ></SendPricing>
        <IssuePricing ref={issueRef} id={workid} setWorkStatus={setWorkStatus}></IssuePricing>
        <CancelPricing ref={cancelRef} id={workid} setWorkStatus={setWorkStatus} sentOn={invoice.sentOn}  ></CancelPricing>
        <Row className="no-gutters">
            <Col lg={12} className="mb-4">
                <h5 className="mb-2">Töö nr {workid} </h5>
                <p className="ms-2 mb-1  fs--1">Algus kp. {Moment(workStatus.startedOn).format('D MMM yyyy')}</p>
                <Badge color={(workStatus.invoiceIssued ? 'success' : 'info')} pill className="mt-0 ms-0 mb-2 fs--1" >
                    {workStatus.invoiceIssued ? 'Valmis' : 'Töös'}
                    {!workStatus.invoiceIssued && <Wrench className='ms-1' ></Wrench>}
                    {workStatus.invoiceIssued && <Check className='ms-1'></Check>}
                </Badge>
                {invoice.isIssued ? (<Fragment>
                    <h6 className="mb-2">Arve nr {invoice.number} </h6>
                    <p className="ms-2 mb-1 fs--1">
                        <CheckCircle size={20} color="Green" ></CheckCircle> {Moment(invoice.issuedOn).locale('et').format('D MMM yyyy')}, {invoice.issuerName}
                    </p>
                    {invoice.sentOn ?
                        <Fragment>
                            <p className="ms-2 mb-1 fs--1">
                                <SendCheck size={20} color='Green'></SendCheck> {Moment(invoice.sentOn).locale('et').format('D MMM yyyy HH:mm')}, {invoice.email}
                            </p>
                        </Fragment> :
                        <Fragment>
                            <NoData><InfoCircle size={20}></InfoCircle> saatmata</NoData>
                        </Fragment>}
                        <FormGroup className='ms-2 mb-1' switch>
                            <Input 
                                type="switch"
                                cursor="pointer"
                                id="signatureLine"
                                name="signatureLine"
                                checked={invoice.isPaid}
                                onChange={({ target }) => { 
                                    setInvoicePaid(target.checked);
                                }} 
                           ></Input>
                           <p  className='fs--1'> {invoice.isPaid?'Makstud':'Maksmata'}</p>
                        </FormGroup>
                    {<Badge color={(invoice.isOverDue === true ? 'danger' : 'info')} pill className="mt-2 fs--1" >
                        {invoice.paymentStatus}
                        {!invoice.isOverDue && <Check className='ms-1'></Check>}
                        {invoice.isOverDue && <Exclamation className='ms-1'></Exclamation>}
                    </Badge>}
                    
                </Fragment>) : (
                    <Fragment>
                        <h6 className="mb-2">Arve </h6>
                        <NoData> <InfoCircle size={20}></InfoCircle> väljastamata</NoData> 
                    </Fragment>
                )}

            </Col>
        </Row>
        <Row>
            <Col className="text-end mb-2">
                <NewOfferDialog ref={offerRef} setWorkStatus={setWorkStatus} workid={workid}></NewOfferDialog>
                <ConfirmDeleteModal
                    isOpen={isDeleteOpen}
                    url={'jobs'}
                    onRequestingClose={() => { setIsDeleteOpen(false) }}
                    onConfirm={(response) => { navigate('/tood') }}
                    deleteBody={() => {
                        return [workid]
                    }}></ConfirmDeleteModal>
                <ButtonDropdown size="sm" color="primary" isOpen={dropdownOpen} toggle={toggle}>
                    {invoice.isIssued && <Button size="sm" outline color="primary" onClick={() => downloadPdf({ pdfPath, fileName: `arve_nr_${invoice.number}.pdf`, setError })}>
                        <Download></Download> Pdf
                    </Button>}
                    {invoice.isIssued && <Button size="sm" outline color="primary" onClick={() => sendRef.current.send()}>
                        <Send></Send> Saada arve
                    </Button>}

                    {!invoice.isIssued && <Button size="sm" color="primary" outline onClick={() => issueRef.current.issue()}>
                        <FileText></FileText> Väljasta arve
                    </Button>}

                    <DropdownToggle onClick={(d, b, c) => { }} split color="primary">
                    </DropdownToggle>
                    <DropdownMenu>
                        {invoice.isIssued &&
                            <DropdownItem onClick={() => cancelRef.current.cancel()}> <Trash></Trash> Tühista arve</DropdownItem>
                        }
                        {!invoice.isIssued &&
                            <DropdownItem onClick={() => offerRef.current.show()}  >
                                <PlusCircle></PlusCircle> Uus pakkumine
                            </DropdownItem>}
                        <DropdownItem onClick={() => newWithSameData()}  >
                            <PlusCircle></PlusCircle> Uus samade andmetega töö
                            <DropdownItem divider />
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => { setIsDeleteOpen(true) }}
                            className="text-danger"
                        >
                            <Trash></Trash>  Kustuta
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </Col>
        </Row>
    </Form> )
}

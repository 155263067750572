import React, {Fragment,  useState } from 'react'; 
import { useParams } from 'react-router'; 
import {
    Col,
    Row,  Form 
} from 'reactstrap';
import {  Check } from 'react-bootstrap-icons';
import FormGroupInput from '../_shared/FormGroupInput';
import {   useNavigate } from 'react-router-dom';
import { ReadOnlyCardHeader, ReadOnlyFooter } from './Tootaja'
import { Button,Card,CardBody,CardFooter  } from 'reactstrap';
import Loader from '../_shared/Loader';
import { useGet,usePut,useDelete } from '../../services/restClient';
import { useAlert } from '@blaumaus/react-alert';
 
const EditableCardBody = ({
    employee = {
        id: 0,
        firstName: '', 
        lastName: '',
        email: '',
        phone: '',
        address: '',
        proffession: '',
        userName: '',
        description: ''
    }, 
    changeEmployee
}) => {
  
    const [firstName, setFirstName] = useState(employee.firstName); 
    const [lastName, setLastName] = useState(employee.lastName); 
    const [email, setEmail] = useState(employee.email); 
    const [phone, setPhone] = useState(employee.phone); 
    const [address, setAddress] = useState(employee.address);
    const [proffession, setProffession] = useState(employee.proffession); 
    const [userName, setUserName] = useState(employee.userName); 
    const [password, setPassword] = useState('');  
    
    const [description, setDescription] = useState(!employee.description ? '' : employee.description);
    let navigate = useNavigate();
    const alert = useAlert();
    const changeResourceHandler = e => {
        e.preventDefault();
        
        
        var body ={ firstName, 
            lastName,
            email,
            phone,
            address,
            proffession,
            userName,
            password,
            description, introducedAt: new Date() };
        var posting = employee.id === 0;
        changeEmployee(body,(response) => {
            if (posting) {
                navigate('/tootaja/' + Number(response).toString());
                alert.show('Uus tootaja lisatud.');
            }
            else {
                navigate('/tootaja/' + employee.id);
                alert.show('Tootaja andmed muudetud.')
            }
            
        });
    };
 
    return (
        <Form onSubmit={changeResourceHandler}>
            <Row>
              
                <Col lg={6}>
                    <FormGroupInput
                        id="firstName"
                        label="Eesnimi"
                        value={firstName}
                        onChange={({ target }) => setFirstName(target.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroupInput
                        id="lastName"
                        label="Perenimi"
                        value={lastName} required
                        onChange={({ target }) => setLastName(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="email"
                        label="Email"
                        value={email} 
                        onChange={({ target }) => setEmail(target.value)}
                        type="email"
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="phone"
                        label="Telefon"
                        value={phone} 
                        onChange={({ target }) => setPhone(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="address"
                        label="Elukoht"
                        value={address} 
                        onChange={({ target }) => setAddress(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="proffession"
                        label="Ametikoht"
                        value={proffession} 
                        onChange={({ target }) => setProffession(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="userName"
                        label="Kasutajanimi"
                        value={userName} 
                        onChange={({ target }) => setUserName(target.value)}
                    />
                </Col> 
                <Col lg={6}>
                    <FormGroupInput
                        id="password"
                        label="Parool"
                        value={password} 
                        type="password"
                        onChange={({ target }) => setPassword(target.value)}
                    />
                </Col> 
                <Col xs={12}>
                    <FormGroupInput
                        id="description"
                        label="Kirjeldus"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        type="textarea"
                        rows="5"
                    />
                </Col>
            </Row> 
            <Row>
                <Col className="text-end">
                    <Button
                        color=" btn-primary"
                        size="sm"
                        type="submit"
                    > <Check size={20} className="me-1"></Check>
                      Salvesta muudatused
                  </Button>
                </Col>
            </Row>
        </Form>
    );
}

//edit
const EmployeeEditCard = () => {
    const { id } = useParams();
    if (!id) throw new Error('id required');

    const [employee] = useGet({ url:`employees/${id}`});  
    const [changeEmployee] = usePut({ url:`employees/${id}`});
    const [deleteEmployee] = useDelete({ url:`employees/${id}`});

    return (
        <Fragment> 
        {!employee ? (
        <Loader />
        ) : ( <Card className="mb-3">
            <ReadOnlyCardHeader { ...{isEditing:true, employee,deleteEmployee}} />
            <CardBody className="bg-light border-top">
            <EditableCardBody { ...{ employee,changeEmployee}} />
            </CardBody>
            <CardFooter className="border-top">
            <ReadOnlyFooter employee={employee} />
            </CardFooter>
            </Card>)
            }
    </Fragment> 
    );
}



export default EmployeeEditCard;
export {
    EditableCardBody
}

import React from 'react';
import {Resources} from '../_shared/Ressursid'; 
import { Link } from 'react-router-dom';

const Tootajad = () => {

  const actionColumn = {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle', 
    align: 'right'
  };
  const columns = [
    {
      dataField: 'firstName',
      text: 'Eesnimi',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle', 
      sort: true,
      formatter: (dataField, {  firstName,id }) => {
        return (
          <Link to={'/tootaja/'+id} >
             <h5 className="mb-0 fs--1">{firstName}</h5>
          </Link>
        );
      }
    },
    {
      dataField: 'lastName',
      headerClasses: 'border-0',
      text: 'Perenimi',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField, {  lastName,id }) => {
        return (
          <Link to={'/tootaja/'+id} >
             <h5 className="mb-0 fs--1">{lastName}</h5>
          </Link>
        );
      }
    }, 
    {
      dataField: 'userName',
      headerClasses: 'border-0',
      text: 'Kasutajanimi',
      classes: 'border-0 py-2 align-middle',
      sort: true
    }, 
    {
      dataField: 'proffession',
      headerClasses: 'border-0',
      text: 'Ametikoht',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'email',
      headerClasses: 'border-0',
      text: 'Email',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'phone',
      headerClasses: 'border-0',
      text: 'Telefon',
      classes: 'border-0 py-2 align-middle',
      sort: true
    } ,actionColumn 
  ];
  return (
    <Resources
         columns={columns}
         keyField="id"
         resourceName={'employees'}
         resourceLink={'tootaja'}
         resourceDisplayName={'Töötajad'} 
         resourcePath="tootajad"
         actionColumn={actionColumn}
      />
  );
};
export default Tootajad;
